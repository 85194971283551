import {
  COUPON_ALL,
  COUPON_GET,
  COUPON_DEL,
  COUPON_PUT,
  COUPON_POST,
  COUPON_LOADING,
  COUPON_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const couponCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: COUPON_LOADING });
    const { data } = await api.post(`${process.env.REACT_APP_API_URL}/coupon`, {
      ...payload,
    });
    dispatch({ type: COUPON_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: COUPON_ERROR });
    throw new Error(error);
  }
};

export const couponAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/coupon?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: COUPON_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: COUPON_ERROR });
    throw new Error(error);
  }
};

export const couponGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/coupon/${id}`,
    );
    if (status === 200) {
      dispatch({ type: COUPON_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: COUPON_ERROR });
    throw new Error(error);
  }
};

export const couponPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: COUPON_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/coupon/${id}`,
      payload,
    );
    dispatch({ type: COUPON_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: COUPON_ERROR });
    throw new Error(error);
  }
};
export const couponDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: COUPON_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/coupon/${id}`,
    );
    dispatch({ type: COUPON_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: COUPON_ERROR });
    throw new Error(error);
  }
};
