import {
  DELIVERY_PROVIDER_ALL,
  DELIVERY_PROVIDER_CREATE,
  DELIVERY_PROVIDER_GET,
  DELIVERY_PROVIDER_UPDATE,
  DELIVERY_PROVIDER_DELETE,
} from '../../actions/types';

const initialState = {
  serials: null,
  isLoading: true,
  isCompleted: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case DELIVERY_PROVIDER_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DELIVERY_PROVIDER_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case DELIVERY_PROVIDER_CREATE:
      return { isLoading: true, isCompleted: true };
    case DELIVERY_PROVIDER_UPDATE:
      return { isLoading: true, isCompleted: true };
    case DELIVERY_PROVIDER_DELETE:
      return { isLoading: true, isCompleted: true };
    default:
      return state;
  }
}
