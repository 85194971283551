import {
  PORTFOLIO_ALL,
  PORTFOLIO_GET,
  PORTFOLIO_DEL,
  PORTFOLIO_PUT,
  PORTFOLIO_POST,
  PORTFOLIO_LOADING,
  PORTFOLIO_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const portfolioCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PORTFOLIO_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/portfolio`,
      payload,
    );
    dispatch({ type: PORTFOLIO_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PORTFOLIO_ERROR });
    throw new Error(error);
  }
};

export const portfolioAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/portfolio?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: PORTFOLIO_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PORTFOLIO_ERROR });
    throw new Error(error);
  }
};

export const portfolioGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/portfolio/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PORTFOLIO_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PORTFOLIO_ERROR });
    throw new Error(error);
  }
};

export const portfolioPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PORTFOLIO_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/portfolio/${id}`,
      payload,
    );
    dispatch({ type: PORTFOLIO_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PORTFOLIO_ERROR });
    throw new Error(error);
  }
};
export const portfolioDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PORTFOLIO_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/portfolio/${id}`,
    );
    dispatch({ type: PORTFOLIO_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PORTFOLIO_ERROR });
    throw new Error(error);
  }
};
