import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Button,
  FormControlLabel,
  Switch,
  TextField,
  InputAdornment,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
// import DescriptionIcon from '@mui/icons-material/Description';

import { Trash2 as Delete } from 'react-feather';

export function PromotionBillPrice({
  Controller,
  control,
  handleChangesetCheckBillPrice,
  checkBillPrice,
  handleSubmit,
  onSubmitPromotionCustomerType,
  fields,
  handleDeleteItem,
  handleAddItem,
}) {
  const renderTable = () => (
    <div className="mt-2">
      <form onSubmit={handleSubmit(onSubmitPromotionCustomerType)}>
        <Paper>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size={'small'}>
              <colgroup>
                <col width="19%" />
                <col width="33%" />
                <col width="33%" />
                <col width="15%" />
              </colgroup>
              <TableHead>
                <TableRow className="bg-yellow-100">
                  <TableCell>
                    <div className="font-bold py-1">ลำดับที่</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold py-1">ราคารวม</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold py-1">ส่วนลด</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold py-1">ดำเนินการ</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!_.isEmpty(fields) ? (
                  _.map(fields, (item, index) => (
                    <TableRow
                      key={item.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="py-1">{`${index + 1}`}</div>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <div className="py-1">
                          <Controller
                            name={`billPriceList.${index}.total_price`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                fullWidth
                                required
                                type="number"
                                label="ราคารวม"
                                {...field}
                              />
                            )}
                          />
                        </div>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <div className="py-1">
                          <Controller
                            name={`billPriceList.${index}.percent`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                fullWidth
                                required
                                type="number"
                                label="ส่วนลด"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                {...field}
                              />
                            )}
                          />
                        </div>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Button
                          size="sm"
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            handleDeleteItem(index);
                          }}
                        >
                          <Delete size={16} color="red" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <div className="text-center">ไม่มีข้อมูล</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <div className="flex justify-between gap-1 py-4">
          <Button
            size="small"
            variant="outlined"
            color="success"
            onClick={() => {
              handleAddItem();
            }}
          >
            เพิ่ม
          </Button>
          <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );

  return (
    <div className="m-4">
      <div className="text-red-500">( กำลังพัฒนา )</div>

      <div className="mb-4">
        <FormControlLabel
          control={
            <Switch
              checked={checkBillPrice}
              onChange={handleChangesetCheckBillPrice}
            />
          }
          label="ปิด-เปิด"
        />
      </div>
      <div className="mt-6 text-lg pb-2">เพิ่มส่วนลด</div>
      <div className="w-full lg:flex rounded-lg border-2 ">
        <div className="w-full p-2">{renderTable()}</div>
      </div>
    </div>
  );
}

PromotionBillPrice.propTypes = {
  selectCustomerType: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.func,
  handleChangesetCheckBillPrice: PropTypes.func,
  checkBillPrice: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onSubmitPromotionCustomerType: PropTypes.func,
  fields: PropTypes.func,
  handleDeleteItem: PropTypes.func,
  handleAddItem: PropTypes.func,
};

PromotionBillPrice.defaultProps = {
  customer: null,
};

export default PromotionBillPrice;
