import {
  PRODUCT_STOCK_LOT_ALL,
  PRODUCT_STOCK_LOT_GET,
  PRODUCT_STOCK_LOT_DEL,
  PRODUCT_STOCK_LOT_PUT,
  PRODUCT_STOCK_LOT_POST,
  PRODUCT_STOCK_LOT_LOADING,
  PRODUCT_STOCK_LOT_ERROR,
} from '../../actions/types';

const initialState = {
  productStockLot: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case PRODUCT_STOCK_LOT_LOADING:
      return { isLoading: true, isCompleted: true };
    case PRODUCT_STOCK_LOT_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case PRODUCT_STOCK_LOT_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PRODUCT_STOCK_LOT_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PRODUCT_STOCK_LOT_POST:
      return { isLoading: false, isCompleted: true };
    case PRODUCT_STOCK_LOT_PUT:
      return { isLoading: false, isCompleted: true };
    case PRODUCT_STOCK_LOT_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
