import {
  PAYMENT_TRANSACTION_ALL,
  PAYMENT_TRANSACTION_GET,
  PAYMENT_TRANSACTION_DEL,
  PAYMENT_TRANSACTION_PUT,
  PAYMENT_TRANSACTION_LOADING,
  PAYMENT_TRANSACTION_ERROR,
  // eslint-disable-next-line import/named
} from '../../actions/types';

const initialState = {
  productTransaction: null,
  isLoading: true,
  isCompleted: false,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case PAYMENT_TRANSACTION_LOADING:
      return { isLoading: true, isCompleted: true };
    case PAYMENT_TRANSACTION_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case PAYMENT_TRANSACTION_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PAYMENT_TRANSACTION_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PAYMENT_TRANSACTION_PUT:
      return { isLoading: false, isCompleted: true };
    case PAYMENT_TRANSACTION_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
