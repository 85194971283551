import api from '../../../utils/functions/api';

import {
  OPTION_ALL,
  OPTION_CREATE,
  OPTION_GET,
  OPTION_UPDATE,
  OPTION_DELETE,
  OPTION_ERROR,
  OPTION_LOADING,
} from '../types';

// faker.locale = "th"

export const getAllOption = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/option?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: OPTION_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: OPTION_ERROR });
    throw new Error(error);
  }
};

export const getOptionAllOfProduct = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/option?productId=${id}`,
    );
    if (status === 200) {
      dispatch({ type: OPTION_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: OPTION_ERROR });
    throw new Error(error);
  }
};

export const getOneOption = (id) => async (dispatch) => {
  await api.get(`${process.env.REACT_APP_API_URL}/option/${id}`).then((res) => {
    console.log('Request Server to Get One option');
    if (res.data) {
      dispatch({ type: OPTION_GET, payload: res.data });
    } else {
      dispatch({ type: OPTION_GET, payload: null });
    }
  });
};
export const createOneOption = (payload) => async (dispatch) => {
  await api
    .post(`${process.env.REACT_APP_API_URL}/option/`, payload)
    .then(() => {
      console.log('Request Server to Create New option');
      dispatch({ type: OPTION_CREATE });
    });
};

export const editOneOption = (id, payload) => async (dispatch) => {
  await api
    .put(`${process.env.REACT_APP_API_URL}/option/${id}`, payload)
    .then(() => {
      console.log('Request Server to edit option');
      dispatch({ type: OPTION_UPDATE });
    });
};

export const deleteOneOption = (id) => async (dispatch) => {
  dispatch({ type: OPTION_LOADING });
  await api.delete(`${process.env.REACT_APP_API_URL}/option/${id}`).then(() => {
    console.log('Request Server to Delete One option');
    dispatch({ type: OPTION_DELETE, payload: null });
  });
};
