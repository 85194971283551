import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from '@mui/material';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function DetailCoupon({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const coupon = useSelector((state) => state.coupon);

  useEffect(() => {
    dispatch(actions.couponGet(id));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (coupon.isLoading || coupon.rows) {
    return <Loading />;
  }
  if (!coupon.isLoading && coupon.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">รายละเอียดคูปอง</div>
        </div>
        <div>
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold ">{'ชื่อ'}</div>
              <div className="w-1/2 py-4 ">{`${coupon.name}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 ">{`${coupon.description}`}</div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailCoupon.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailCoupon.defaultProps = {
  title: '',
  subtitle: '',
};
