import api from '../../../utils/functions/api';

import {
  PROMOTION_WITH_PRODUCT_TYPE_ALL,
  PROMOTION_WITH_PRODUCT_TYPE_CREATE,
  PROMOTION_WITH_PRODUCT_TYPE_GET,
  PROMOTION_WITH_PRODUCT_TYPE_UPDATE,
  PROMOTION_WITH_PRODUCT_TYPE_DELETE,
  PROMOTION_WITH_PRODUCT_TYPE_ERROR,
} from '../types';

// faker.locale = "th"

export const getAllPromotionWithProductType = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/promotion-with-product-type?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: PROMOTION_WITH_PRODUCT_TYPE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PROMOTION_WITH_PRODUCT_TYPE_ERROR });
    throw new Error(error);
  }
};

export const getOnePromotionWithProductType = (id) => async (dispatch) => {
  await api
    .get(`${process.env.REACT_APP_API_URL}/promotion-with-customer-type/${id}`)
    .then((res) => {
      console.log('Request Server to Get One option');
      if (res.data) {
        dispatch({ type: PROMOTION_WITH_PRODUCT_TYPE_GET, payload: res.data });
      } else {
        dispatch({ type: PROMOTION_WITH_PRODUCT_TYPE_GET, payload: null });
      }
    });
};
export const createOnePromotionWithProductType = (payload) => async (
  dispatch,
) => {
  await api
    .post(
      `${process.env.REACT_APP_API_URL}/promotion-with-product-type/`,
      payload,
    )
    .then(() => {
      console.log('Request Server to Create New option');
      dispatch({ type: PROMOTION_WITH_PRODUCT_TYPE_CREATE });
    });
};

export const editOnePromotionWithProductType = (id, payload) => async (
  dispatch,
) => {
  await api
    .put(
      `${process.env.REACT_APP_API_URL}/promotion-with-product-type/${id}`,
      payload,
    )
    .then(() => {
      console.log('Request Server to edit option');
      dispatch({ type: PROMOTION_WITH_PRODUCT_TYPE_UPDATE });
    });
};

export const deleteOnePromotionWithProductType = (id) => async (dispatch) => {
  await api
    .delete(
      `${process.env.REACT_APP_API_URL}/promotion-with-product-type/${id}`,
    )
    .then(() => {
      console.log('Request Server to Delete One option');
      dispatch({ type: PROMOTION_WITH_PRODUCT_TYPE_DELETE, payload: null });
    });
};
