/* eslint-disable no-underscore-dangle */
/* eslint-disable no-await-in-loop */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Barcode from 'react-jsbarcode';

import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
  // Select,
  // FormControl,
  // MenuItem,
  // InputLabel,
  Autocomplete,
} from '@mui/material';

import dayjs from 'dayjs';

import SaveIcon from '@mui/icons-material/Save';
import Clear from '@mui/icons-material/Clear';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Paperclip } from 'react-feather';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import QrCodeIcon from '@mui/icons-material/QrCode';

// import Inventory2Icon from '@mui/icons-material/Inventory2';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { CSVUpload } from '../../components/CSVUpload';
import { ProductUtil, ProductAllDataUtil } from '../../utils/modelutils';
import * as actions from '../../redux/actions';
import { ProductForm, ProductEditStockForm } from '../../components/Forms';
import { Loading, LoadingNoBackdrop } from '../../components/Loading';
// import LoadingNoBackdrop from '../../components/Loading';
import { Error, NotAuthorized } from '../../components/Error';
import accessSubModule from '../../utils/functions/accessSubModule';
import { ViewTitle } from '../../components/ViewTitle';
import ImageUpload from '../../components/ImageUpload/ImageUpload';
import FileUpload from '../../components/fileUpload/FileUpload';
import VideoUpload from '../../components/fileUpload/VideoUpload';
import BarcodeSticker from '../../components/PdfFile/BarcodeSticker';

import { ProductWithWarehouseQuantityExcelContainer } from '../../components/ExcelDownload';

import { app as appConfig } from '../../configs';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValue = {
  name: '',
  unit: '',
  packing_size: '',
  packing_unit: '',
  net: '',
  unit_net: '',
  detail: '',
  type_code: '',
  inventory: '',
  images: '',
  option: '',
};

function Product({ title, subtitle }) {
  const module = 'WMS';
  const subModule = 'PRODUCT_SEARCH';
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const stockChecking = useSelector((state) => state.stockChecking);
  const setting = useSelector((state) => state.setting);

  const brand = useSelector((state) => state.brand);

  console.log('product', product?.rows);

  const me = useSelector((state) => state.me);
  const productType = useSelector((state) => state.productType);
  const [selectProductType, setSelectProductType] = useState();
  const [selectBrand, setSelectBrand] = useState();
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);

  const [searchNameBrand, setSearchNameBrand] = useState('');
  const [searchNameProduct, setSearchNameProduct] = useState('');

  const handleCSVOpen = () => setCsvUploadOpen(true);
  const handleCSVClose = () => {
    setCsvUploadOpen(false);
    setCsvData([]);
  };

  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: 'onBlur',
  });

  const {
    handleSubmit: checkStockHandleSubmit,
    control: checkStockControl,
    reset: checkStockReset,
  } = useForm();

  const { fields } = useFieldArray({
    control,
    name: 'optionList',
  });

  const [uploadedImage, setUploadedImage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [openEditStock, setOpenEditStock] = useState(false);
  const [openCheckStock, setOpenCheckStock] = useState(false);

  const [editStock, setEditStock] = useState(null);
  const [dataStock, setDataStock] = useState();

  const [addOption, setAddOption] = useState(false);
  const [addOptionMultiple, setAddOptionMultiple] = useState(false);
  const [seleted, setSeleted] = useState({
    checkProductOutOfStock: false,
  });
  const [selectOption, setSelectOption] = useState([]);
  const [serialType, setSerialType] = useState(false);
  const [privatePrice, setPrivatePrice] = useState(false);
  const [stockInfinite, setStockInfinite] = useState(false);
  const [productDescribe, setProductDescribe] = useState('');
  const [createLoading, setCreateLoading] = useState(false);
  const [showFront, setShowFront] = useState(true);
  const [popularProduct, setPopularProduct] = useState(false);
  const [taxInclude, setTaxInclude] = useState(true);

  const [checkStock, setCheckStock] = useState(false);
  const [openPrintBarcode, setOpenPrintBarcode] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});

  const [file, setFile] = useState([]);
  const [modalFileOpen, setModalFileOpen] = useState(false);

  const [video, setVideo] = useState([]);
  const [modalVideoOpen, setModalVideoOpen] = useState(false);

  const handleChangeStock = (event) => {
    setCheckStock(event.target.checked);
  };
  // const [optionList, setOptionList] = useState(null);
  const handleOpen = () => setOpen(true);
  // eslint-disable-next-line no-sequences
  const handleClose = () => (
    // eslint-disable-next-line no-sequences
    setOpen(false),
    setAddOption(false),
    setSelectOption([]),
    setAddOptionMultiple(false)
  );
  const handleOpenEditStock = () => setOpenEditStock(true);
  const handleCloseEditStock = () => setOpenEditStock(false);

  const handleOpenPrintBarcode = () => setOpenPrintBarcode(true);
  const handleClosePrintBarcode = () => setOpenPrintBarcode(false);

  const handleOpenCheckStock = () => setOpenCheckStock(true);
  const handleCloseCheckStock = () => setOpenCheckStock(false);

  const handlePrint = async () => {
    try {
      await BarcodeSticker(selectedProduct, setting);
    } catch (error) {
      alert('ระบบปริ้นมีปัญหา โปรดแจ้งแอดมิน');
    }
  };
  // console.log('productType,.,.', productType?.rows);

  const handleChangeSerialType = (event) => {
    setSerialType(event.target.checked);
  };

  // console.log('selectProduct', selectProduct);
  // console.log('productDescribe', productDescribe);
  const selectStocks = seleted.checkProductOutOfStock;

  useEffect(() => {
    console.log('selectProductType', selectProductType?._id);
    dispatch(actions.settingAll());
    dispatch(actions.productReset());
    dispatch(
      actions.productAll({
        name,
        page,
        size,
        selectStocks,
        selectProductType: selectProductType?._id || '',
        selectBrand: selectBrand?._id || '',
      }),
    );
    dispatch(actions.productTypeAll(''));
    dispatch(actions.brandAll(''));
    dispatch(actions.meGet());
    return () => {};
  }, [name, page, size, selectStocks, selectProductType, selectBrand]);

  useEffect(() => {
    setTotal(product?.total);
    return () => {};
  }, [product]);

  useEffect(() => {
    dispatch(
      actions.getAllStockChecking({
        name,
        page,
        size: 1000,
        productId: dataStock?.id,
      }),
    );
    return () => {};
  }, [dataStock]);

  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     setName(searchTerm);
  //     setPage(1);
  //   }, 1000);
  //   return () => clearTimeout(delayDebounceFn);
  // }, [searchTerm]);

  const handleSearch = () => {
    console.log('Searching for:', searchTerm);
    // เรียกฟังก์ชั่นค้นหาของคุณที่นี่
    setName(searchTerm);
    setPage(1);
  };

  const handleClear = () => {
    setSearchTerm('');
    setName('');
    setPage(1);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleAddFromFile = async () => {
    if (!_.isEmpty(csvData)) {
      console.log('csvData', csvData);
      const NewCSVData = _.map(csvData, (each) => {
        const payload = {
          ...each,
          brand: brand?.rows[parseInt(each?.brand, 10) - 1]?.id,
          product_type:
            productType?.rows[parseInt(each?.product_type, 10) - 1]?.id,
          cost_price:
            each?.cost_price === ''
              ? 0
              : parseFloat(each?.cost_price.replaceAll(',', '')),
          price:
            each?.price === ''
              ? 0
              : parseFloat(each?.price.replaceAll(',', '')),
          inventory:
            each?.inventory === ''
              ? 0
              : parseFloat(each?.inventory.replaceAll(',', '')),
          weight:
            each?.weight === ''
              ? 0
              : parseFloat(each?.weight.replaceAll(',', '')),
          minimum_inventory_size:
            each?.minimum_inventory_size === ''
              ? 0
              : parseFloat(each?.minimum_inventory_size.replaceAll(',', '')),
          delivery: String(each?.delivery).toLowerCase() === 'true',
          option_type: String(each?.option_type).toLowerCase() === 'true',
          private_price: String(each?.private_price).toLowerCase() === 'true',
          serial_type: String(each?.serial_type).toLowerCase() === 'true',
          stock_infinite: String(each?.stock_infinite).toLowerCase() === 'true',
          // tax_include: String(each?.tax_include).toLowerCase() === 'true',
        };
        return payload;
      });
      console.log('NewCSVData', NewCSVData);
      try {
        await dispatch(actions.productCreate({ arr: NewCSVData }));
        alert('สำเร็จ');
        handleCSVClose();
        await dispatch(actions.productAll({ name, page, size }));
      } catch (error) {
        alert('เพิ่มสินค้าไม่สำเร็จ');
        console.error(error);
      }
    } else {
      alert('ไม่สามารถอ่านไฟล์รายการลูกค้าได้');
    }
  };

  const onSubmit = async (data) => {
    console.log('payload + image', data);
    const barCodeNumber = `${'885'}${setting.owner?.barcodeNumber}${
      data.type_code
    }`;
    console.log('barCodeNumber', barCodeNumber);
    setCreateLoading(true);
    try {
      const payload = {
        ...data,
        type_code: data?.type_code.length > 5 ? data.type_code : barCodeNumber,
        inventory: 0,
        product_type: data?.product_type?.id,
        brand: data?.brand?.id || undefined,
        images: _.map(uploadedImage, (image) => ({
          image: image.data_url,
        })),
        price: 0,
        cost_price: 0,
      };
      console.log('payload + image + file', payload);
      // console.log('Data', data);
      // console.log('select Options ', !_.isEmpty(selectOption));
      await dispatch(actions.productCreate(payload));
      reset(defaultValue);
      setProductDescribe('');
      setUploadedImage('');
      setCreateLoading(false);
      alert('สำเร็จ');
      handleClose();
      await dispatch(actions.productAll({ name, page, size }));
      dispatch(actions.meGet());
    } catch (error) {
      console.error(error);
    }
  };

  console.log('me.userData', me.userData);
  const onSubmitEditStock = async (data) => {
    setCreateLoading(true);
    try {
      // console.log('data ...', data);
      // console.log('data of edit stock...', editStock);
      if (!_.isEmpty(editStock?.options)) {
        const sumInventory = _.sumBy(data?.optionList, (eachOption) =>
          parseInt(eachOption?.inventory, 10),
        );
        // console.log('sumInventory', sumInventory);
        if (editStock.inventory !== sumInventory) {
          if (sumInventory > editStock.inventory) {
            const log = {
              date: new Date(),
              product: editStock.id,
              transaction_type: 'เข้า',
              prev_quantity: editStock.inventory,
              next_quantity: sumInventory,
              quantity: sumInventory - editStock.inventory,
              user: me.userData.firstname,
              employee: me.userData._id,
            };
            await dispatch(actions.createOnelog(log));
          } else if (sumInventory < editStock.inventory) {
            const log = {
              date: new Date(),
              product: editStock.id,
              transaction_type: 'ออก',
              prev_quantity: editStock.inventory,
              next_quantity: sumInventory,
              quantity: editStock.inventory - sumInventory,
              user: me.userData.firstname,
              employee: me.userData._id,
            };
            await dispatch(actions.createOnelog(log));
          }
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const eachOption of data?.optionList) {
          // eslint-disable-next-line no-underscore-dangle
          await dispatch(actions.editOneOption(eachOption._id, eachOption));
        }
        // await dispatch(actions.productPut(editStock.id, data));
        alert('สำเร็จ');
        handleCloseEditStock();
        await dispatch(actions.productAll({ name, page, size }));
        dispatch(actions.meGet());
      } else {
        if (editStock.inventory !== data.inventory) {
          if (data.inventory > editStock.inventory) {
            const log = {
              date: new Date(),
              product: editStock.id,
              transaction_type: 'เข้า',
              prev_quantity: editStock.inventory,
              next_quantity: data.inventory,
              quantity: data.inventory - editStock.inventory,
              user: me.userData.firstname,
              employee: me.userData._id,
            };
            await dispatch(actions.createOnelog(log));
          } else if (data.inventory < editStock.inventory) {
            const log = {
              date: new Date(),
              product: editStock.id,
              transaction_type: 'ออก',
              prev_quantity: editStock.inventory,
              next_quantity: data.inventory,
              quantity: editStock.inventory - data.inventory,
              user: me.userData.firstname,
              employee: me.userData._id,
            };
            await dispatch(actions.createOnelog(log));
          }
        }
        const payload = {
          images: _.map(editStock.images, (eachImage) => {
            const dataImage = {
              // eslint-disable-next-line no-underscore-dangle
              id: eachImage._id,
              image: eachImage.url,
            };
            return dataImage;
          }),
          inventory: data.inventory,
        };
        await dispatch(actions.productPut(editStock.id, payload));
        alert('สำเร็จ');
        handleCloseEditStock();
        await dispatch(actions.productAll({ name, page, size }));
        dispatch(actions.meGet());
      }
      setCreateLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitCheckStock = async (data) => {
    console.log('data in check stock', data);
    const payload = {
      ...data,
      inventory: parseFloat(data.inventory),
      product: dataStock?.id,
      employee: me?.userData?.id || me?.userData?._id,
    };

    console.log('payload in check stock', payload);

    await dispatch(actions.createOneStockChecking(payload));
    checkStockReset(defaultValue);
    dispatch(
      actions.getAllStockChecking({
        name,
        page,
        size: 1000,
        productId: dataStock?.id,
      }),
    );
  };

  const handleDeleteProduct = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.productDelete(id));
        await dispatch(actions.productAll({ name, page, size }));
        dispatch(actions.meGet());
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToDetailProduct = (id) => {
    history.push(`/wms/product2/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  // const handleChangeSelect = (event) => {
  //   setSeleted({ ...seleted, [event.target.name]: event.target.checked });
  // };

  const renderAddButton = () => (
    <div className="pb-4">
      <div className="my-auto flex gap-1">
        {/* <Button
          variant="contained"
          onClick={handleOpen}
          startIcon={<AddBoxIcon />}
        >
          เพิ่ม
        </Button>
        <Button
          variant="contained"
          onClick={handleCSVOpen}
          startIcon={<FileUploadIcon />}
          disabled
        >
          อัพโหลด
        </Button> */}
        <div className="my-auto mr-1">
          <ProductWithWarehouseQuantityExcelContainer
            columnList={ProductUtil?.columns}
            columnDataList={ProductAllDataUtil?.columns}
            currentData={product?.rows}
            dataAPIEndPoint="product"
            dataQuery={{
              fetchStockLot: false,
              fetchProcess: false,
              page: 1,
              size: appConfig?.maxFetchSize,
            }}
            sheetName="Product"
          />
        </div>
      </div>
      <div className="my-auto flex justify-end p-2 gap-2">
        <div className="bg-yellow-400 text-center rounded-xl px-4">{''}</div>
        <div className="px-2">คือ จำนวนการจอง</div>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="px-4 pt-4 md:flex md:flex-row">
        <div className="w-full">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="clear search" onClick={handleClear}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        {/* <div className="w-full md:w-1/3 text-center my-auto">
          <FormControlLabel
            control={
              <Checkbox
                checked={seleted.checkProductOutOfStock}
                onChange={handleChangeSelect}
                name="checkProductOutOfStock"
              />
            }
            label="สินค้าใกล้หมดสต๊อก"
          />
        </div> */}
      </div>
      <div className="px-4 pb-4 pt-2 md:flex md:flex-row gap-2">
        <div className="w-full md:w-1/2">
          <Autocomplete
            defaultValue={selectProductType}
            options={productType?.rows}
            getOptionLabel={(option) => option?.name}
            renderOption={(props, type) => (
              <div className="flex gap-2" {...props}>
                {/* <img src={type?.image?.url || ''} width="40" height="20" /> */}
                <div className="my-auto pl-2">{`${type?.name}`}</div>
              </div>
            )}
            onChange={(e, value) => {
              setSelectProductType(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                size="small"
                label="ประเภทสินค้า"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </div>
        <div className="w-full md:w-1/2">
          <Autocomplete
            defaultValue={selectBrand}
            options={brand?.rows}
            getOptionLabel={(option) => option?.name}
            renderOption={(props, type) => (
              <div className="flex gap-2" {...props}>
                <img src={type?.image?.url || ''} width="40" height="20" />
                <div className="my-auto pl-2">{`${type?.name}`}</div>
              </div>
            )}
            onChange={(e, value) => {
              setSelectBrand(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                size="small"
                label="แบรนด์"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </div>
      </div>
    </Card>
  );

  const displayCheckStock = () => (
    <div className="w-full">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead className="bg-yellow-100">
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> จำนวนที่บันทึก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> เวลาที่บันทึก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> บันทึกโดย</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รายละเอียด</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(stockChecking?.rows) && (
                <>
                  {stockChecking?.rows.map((eachStock, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      className={index % 2 === 0 ? 'bg-gray-100' : ''}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <div className="my-auto">{eachStock?.inventory}</div>
                      </TableCell>
                      <TableCell>
                        <div className="my-auto">
                          {dayjs(eachStock?.date).format('D MMM BBBB')}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="my-auto">
                          {eachStock?.employee?.firstname}{' '}
                          {eachStock?.employee?.lastname}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="my-auto">
                          {eachStock?.detail || '-'}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  const renderModalPrintBarcode = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openPrintBarcode}
      onClose={handleClosePrintBarcode}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openPrintBarcode}>
        <Card sx={style} className="max-w-xl my-4">
          <div className="max-h-screen overflow-y-auto">
            <div className="py-2 text-center text-xl">BARCODE</div>
            <div className="mx-auto">
              <Barcode
                value={selectedProduct?.type_code}
                renderer="svg"
                options={{
                  width: 4,
                  height: 100,
                  fontSize: 20,
                  // format: 'EAN13',
                }}
              />
            </div>
            <div className="flex flex-row justify-end gap-1 py-4">
              <Button variant="contained" onClick={() => handlePrint()}>
                พิมพ์
              </Button>
              <Button variant="outlined" onClick={handleClosePrintBarcode}>
                ยกเลิก
              </Button>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderModalEditStock = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openEditStock}
      onClose={handleCloseEditStock}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openEditStock}>
        <Card sx={style} className="max-w-xl my-4">
          <div className="max-h-screen overflow-y-auto">
            <div className="py-2">แก้ไขสต๊อกสินค้า</div>
            <div className="py-2">
              <form onSubmit={handleSubmit(onSubmitEditStock)}>
                <ProductEditStockForm
                  errors={errors}
                  product={editStock}
                  control={control}
                  Controller={Controller}
                  setValue={setValue}
                  fields={fields}
                />
                <div className="flex flex-row justify-end gap-1 py-4">
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<SaveIcon />}
                  >
                    บันทึก
                  </Button>
                  <Button variant="outlined" onClick={handleCloseEditStock}>
                    ยกเลิก
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderModalCheckStock = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openCheckStock}
      onClose={handleCloseCheckStock}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openCheckStock}>
        <Card sx={style} className="max-w-3xl my-4">
          <div className="max-h-screen overflow-y-auto">
            <div className="py-2 text-lg">ตรวจสอบสต๊อคสินค้า</div>
            <div className="py-2">
              <form onSubmit={checkStockHandleSubmit(onSubmitCheckStock)}>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'inventory'}
                    control={checkStockControl}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="จำนวนที่นับได้"
                        fullWidth
                        required
                        type="number"
                        size={'small'}
                      />
                    )}
                  />
                </div>
                <div className="w-full px-1 pb-1">
                  <div className="py-1">วันที่นับสต๊อค</div>
                  <Controller
                    name={'date'}
                    control={checkStockControl}
                    defaultValue={dayjs(new Date())
                      .locale('th')
                      .format('YYYY-MM-DD')}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="date"
                        fullWidth
                        required
                        size={'small'}
                      />
                    )}
                  />
                </div>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'detail'}
                    control={checkStockControl}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="รายละเอียดเพิ่มเติม"
                        fullWidth
                        size={'small'}
                        rows={3}
                        multiline={true}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-row justify-end gap-1 py-4">
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<SaveIcon />}
                  >
                    บันทึก
                  </Button>
                  <Button variant="outlined" onClick={handleCloseCheckStock}>
                    ยกเลิก
                  </Button>
                </div>
              </form>
              {displayCheckStock()}
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-5xl my-4">
          <div className="max-h-screen overflow-y-auto">
            <div className="py-2 text-xl">เพิ่มข้อมูลสินค้า</div>
            <div className="py-2 ">
              <form onSubmit={handleSubmit(onSubmit)}>
                <ProductForm
                  control={control}
                  Controller={Controller}
                  errors={errors}
                  product={product}
                  productType={productType}
                  brand={brand?.rows}
                />
                <div className="p-2 bg-gray-600 text-white my-2 w-full">
                  รูปภาพของสินค้า
                </div>
                <div className="px-1 pt-4">
                  <ImageUpload
                    images={uploadedImage}
                    setImages={setUploadedImage}
                    preview_size="250"
                    maxNumber={1}
                  />
                </div>
                <div className="flex flex-row justify-end gap-1 mb-12">
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<SaveIcon />}
                  >
                    บันทึก
                  </Button>
                  <Button variant="outlined" onClick={handleClose}>
                    ยกเลิก
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderCSVUploadModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={csvUploadOpen}
      onClose={handleCSVClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={csvUploadOpen}>
        <Card sx={style} className="max-w-6xl">
          <div className="max-h-screen overflow-y-auto my-10">
            <div className="pt-2 text-xl">อัพโหลดข้อมูล</div>
            <div className="py-2 font-system">
              อัพโหลดไฟล์ .csv โดยมีโครงสร้างตามไฟล์เทมเพลต แถวที่ 1-2
              และแถวสุดท้าย จะไม่ถูกนำมาคิดในระบบ <br />
              <Button variant="contained">
                <Link to="/filetemplate/Product.csv" target="_blank">
                  ดาวน์โหลดเทมเพลต
                </Link>
              </Button>
            </div>
            <div className="py-2">
              <div className="flex justify-center">
                <div>
                  <CSVUpload setCsvData={setCsvData} />
                  {!_.isEmpty(csvData) && (
                    <div>
                      <p className="font-system my-1">
                        พบข้อมูล {_.size(csvData)} รายการ
                      </p>
                      <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        onClick={handleAddFromFile}
                      >
                        บันทึก
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="my-2 flex">
              <div className="bg-blue-50 p-4 w-1/2">
                <div className="pb-2">
                  * โปรดแทนที่ข้อมูลใน <strong>ประเภทสินค้า</strong>{' '}
                  ด้วยลำดับดังต่อไปนี้
                </div>
                <div className="w-full mb-2 border-2 border-black">
                  <input
                    type="text"
                    name="search"
                    className="w-full p-1"
                    value={searchNameProduct}
                    placeholder="ค้นหา"
                    onChange={(e) => setSearchNameProduct(e.target.value)}
                  />
                </div>

                <div className="rounded-sm overflow-y-auto">
                  <Table
                    sx={{
                      '&:last-child td, &:last-child th': { border: 1 },
                    }}
                  >
                    <TableHead>
                      <TableCell>ลำดับ</TableCell>
                      <TableCell>ชื่อประเภทของสินค้า</TableCell>
                      <TableCell>รหัส</TableCell>
                    </TableHead>
                    <TableBody>
                      {!_.isEmpty(productType?.rows) &&
                        productType?.rows
                          .filter((nameType) =>
                            nameType?.name.match(
                              new RegExp(searchNameProduct, 'i'),
                            ),
                          )
                          .map((nameType, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1} </TableCell>
                              <TableCell>{nameType?.name} </TableCell>
                              <TableCell>{nameType?.id} </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
              <div className="bg-blue-50 p-4 w-1/2">
                <div className="pb-2">
                  * โปรดแทนที่ข้อมูลใน <strong>แบรนด์</strong>{' '}
                  ด้วยลำดับดังต่อไปนี้
                </div>
                <div className="w-full mb-2 border-2 border-black">
                  <input
                    type="text"
                    name="search"
                    className="w-full p-1"
                    value={searchNameBrand}
                    placeholder="ค้นหา"
                    onChange={(e) => setSearchNameBrand(e.target.value)}
                  />
                </div>
                <div className="rounded-sm overflow-y-auto">
                  <Table
                    sx={{
                      '&:last-child td, &:last-child th': { border: 1 },
                    }}
                  >
                    <TableHead>
                      <TableCell>ลำดับ</TableCell>
                      <TableCell>ชื่อแบรนด์</TableCell>
                      <TableCell>รหัส</TableCell>
                    </TableHead>
                    <TableBody>
                      {!_.isEmpty(brand?.rows) &&
                        brand?.rows
                          .filter((nameBrand) =>
                            nameBrand?.name.match(
                              new RegExp(searchNameBrand, 'i'),
                            ),
                          )
                          .map((nameBrand, index) => (
                            <TableRow key={index}>
                              <TableCell>{index + 1} </TableCell>
                              <TableCell>{nameBrand?.name} </TableCell>
                              <TableCell>{nameBrand?.id} </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderTable = () => (
    <div className="my-2 ">
      {/* <Card className="mb-2 p-5 flex">
        <div className="w-1/2">
          <div>ราคารวม ( ต้นทุน )</div>
          <div className="font-bold text-xl">
            {`${parseInt(product?.totalCostPrice || 0, 10, 10).toLocaleString(
              undefined,
            )}`}{' '}
            บาท
          </div>
        </div>
        <div className="w-1/2">
          <div>ราคารวม ( ราคาขาย )</div>
          <div className="font-bold text-xl">
            {`${parseInt(product?.totalPrice || 0, 10, 10).toLocaleString(
              undefined,
            )}`}{' '}
            บาท
          </div>
        </div>
      </Card> */}
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รูปภาพ</div>
                </TableCell>
                {/* <TableCell>
                  <div className="font-bold"> บาร์โค๊ด</div>
                </TableCell> */}
                <TableCell>
                  <div className="font-bold text-center"> คลัง/คงเหลือ/จอง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(product.rows) ? (
                product.rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      <div className="my-auto flex gap-1">
                        <div className="my-auto">
                          {(page - 1) * size + index + 1}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold text-lg">{`${row?.name}`}</div>
                      <div className="">{`( ${row?.type_code || '-'} )`}</div>
                      <div className="">{`( ${row?.model_name || '-'} )`}</div>
                      <div className="flex">
                        {`( ${row?.brand?.name || '-'} )`}
                      </div>
                      {/* <img src={row?.brand?.image[0]?.url} className="w-10" /> */}
                    </TableCell>
                    {_.isEmpty(row.images) ? (
                      <TableCell>
                        <img
                          key={index}
                          src="https://storage.googleapis.com/mydev_eonlineshop/other/thumbnails/1645339249181_800x800.png"
                          width="100"
                          height="100"
                          className="border-2"
                        ></img>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <img
                          key={index}
                          src={`${row?.images[0]?.url}`}
                          width="100"
                          height="100"
                          className="border-2"
                        ></img>
                      </TableCell>
                    )}

                    <TableCell>
                      {_.map(
                        row?.product_in_warehouse,
                        (eachWarehouse, index2) =>
                          eachWarehouse.inventory.length > 0 && (
                            <div
                              className="flex justify-between mb-1"
                              key={index2}
                            >
                              <div>{eachWarehouse?.warehouse}</div>
                              <div
                                className={`px-2 ${
                                  eachWarehouse?.inventory[0]?.stock <
                                  row?.minimum_inventory
                                    ? 'bg-red-400 text-center rounded-xl'
                                    : 'bg-green-400 text-center rounded-xl'
                                }`}
                              >
                                {eachWarehouse?.inventory[0]?.stock} {row?.unit}
                              </div>
                            </div>
                          ),
                      )}
                      <div className="bg-yellow-400 text-center rounded-xl px-2">
                        {_.sumBy(row?.producttransactionexports_reserve1, (e) =>
                          _.sumBy(e?.order, (r) => r.amount),
                        )}{' '}
                        {row?.unit}
                      </div>
                    </TableCell>

                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'success'}
                          size={'small'}
                          onClick={() => {
                            setSelectedProduct(row);
                            handleOpenPrintBarcode();
                          }}
                          startIcon={<QrCodeIcon />}
                        >
                          บาร์โค๊ด
                        </Button>

                        <Button
                          variant="contained"
                          color={'info'}
                          size={'small'}
                          onClick={() => handlePushToDetailProduct(row.id)}
                          startIcon={<DescriptionIcon />}
                        >
                          รายละเอียด
                        </Button>

                        {/* <Button
                          variant="contained"
                          color={'yellow'}
                          size={'small'}
                          onClick={() => {
                            handleOpenEditStock(row?.id);
                            setEditStock(row);
                          }}
                          startIcon={<EditIcon />}
                          disabled
                        >
                          แก้ไขสต๊อก
                        </Button> */}

                        {/* <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeleteProduct(row?.id);
                          }}
                          startIcon={<DeleteIcon />}
                          disabled={row?.inventory > 0}
                        >
                          ลบ
                        </Button> */}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูลที่ต้องการ</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  console.log('status of loading page', product.isLoading);

  if (
    // product.isLoading ||
    createLoading
    // !product?.rows ||
    // stockChecking.isLoading
  ) {
    return <Loading />;
  }
  if (
    !createLoading
    // !product.isLoading &&
    // product.isCompleted &&
    // !stockChecking.isLoading
  ) {
    return (
      <div>
        {/* <FileUpload
            isOpen={modalFileOpen}
            files={file}
            setFiles={setFile}
            handleClose={() => setModalFileOpen(false)}
          /> */}
        {/* <VideoUpload
            isOpen={modalVideoOpen}
            files={video}
            setFiles={setVideo}
            handleClose={() => setModalVideoOpen(false)}
          /> */}
        {renderModal()}
        {renderCSVUploadModal()}
        {renderModalEditStock()}
        {renderModalCheckStock()}
        {renderModalPrintBarcode()}
        <div className="md:flex md:justify-between">
          <div>{renderTitle()}</div>
          <div className="md:mt-6">{renderAddButton()}</div>
        </div>
        {renderSearch()}
        {product?.isLoading ? <LoadingNoBackdrop /> : <>{renderTable()}</>}
      </div>
    );
  }
  return <Error message={product?.message} />;
}

Product.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Product.defaultProps = {
  title: '',
  subtitle: '',
};

export default Product;
