import {
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import dayjs from 'dayjs';

import thLocale from 'date-fns/locale/th';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const localeMap = {
  th: thLocale,
};
const maskMap = {
  th: '__/__/____',
};

export function CouponForm({
  errors,
  coupon,
  Controller,
  control,
  handleChangeType,
  selectType,
  handleChangeEndDate,
  handleChangeStartDate,
  startDate,
  endDate,
  codeFinal,
  setCodeFinal,
  openTapRandom,
  setOpenTapRandom,
}) {
  const [locale] = useState('th');

  console.log('coupon', coupon);

  const [prefixText, setPrefixText] = useState('');
  const [suffixText, setSuffixText] = useState('');
  const [characterSetText, setCharacterSetText] = useState(
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
  );
  const [lengthNumber, setLengthNumber] = useState(10);

  const handleOpenTapRandom = () => {
    setOpenTapRandom(!openTapRandom);
  };

  const GenerateCode = (prefix, length, suffix, characterSet) => {
    let result = '';
    // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charactersLength = characterSet.length;

    // eslint-disable-next-line no-plusplus
    for (let counter = 0; counter < length; counter++) {
      result += characterSet.charAt(
        Math.floor(Math.random() * charactersLength),
      );
      // console.log('result', result, counter);
    }
    console.log('result code', prefix + result + suffix);
    setCodeFinal(prefix + result + suffix);
    return prefix + result + suffix;
  };

  // console.log('code random generated', GenerateCode('', 5));

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={coupon ? coupon.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อคูปอง"
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
              required
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2 flex gap-1">
        <div className="w-4/5">
          {openTapRandom ? (
            <TextField
              // label="โค๊ดจากการสุ่ม"
              value={codeFinal}
              fullWidth
              size={'small'}
              onChange={(event) => {
                setCodeFinal(event.target.value);
              }}
            />
          ) : (
            <Controller
              name={'code'}
              control={control}
              defaultValue={coupon ? coupon.code : ''}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="โค๊ด"
                  fullWidth
                  value={codeFinal}
                  size={'small'}
                  helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                  required={!openTapRandom}
                />
              )}
            />
          )}
        </div>
        <div className="w-1/5 my-auto">
          <Button
            variant="outlined"
            fullWidth
            onClick={() => handleOpenTapRandom()}
          >
            {openTapRandom ? 'ปิดสุ่มโค๊ด' : 'เปิดสุ่มโค๊ด'}
          </Button>
        </div>
      </div>
      {openTapRandom && (
        <div className="w-full p-1 border-4 rounded-md">
          <div className="flex gap-1 my-2">
            <div className="w-1/2">
              <TextField
                label="รหัสนำหน้า"
                value={prefixText}
                fullWidth
                size={'small'}
                onChange={(event) => {
                  setPrefixText(event.target.value);
                }}
              />
            </div>
            <div className="w-1/2">
              <TextField
                label="จำนวนอักษรที่สุ่ม"
                value={lengthNumber}
                fullWidth
                size={'small'}
                type="number"
                onChange={(event) => {
                  setLengthNumber(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="flex gap-1 mb-2">
            <div className="w-1/2">
              <TextField
                label="รหัสตามหลัง"
                value={suffixText}
                fullWidth
                size={'small'}
                onChange={(event) => {
                  setSuffixText(event.target.value);
                }}
              />
            </div>
            <div className="w-1/2">
              <TextField
                label="เซ็ตอักษรที่ใช้สุ่ม"
                value={characterSetText}
                fullWidth
                size={'small'}
                onChange={(event) => {
                  setCharacterSetText(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="mb-2">
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                GenerateCode(
                  prefixText,
                  lengthNumber,
                  suffixText,
                  characterSetText,
                );
              }}
            >
              สุ่มโค๊ด
            </Button>
          </div>
        </div>
      )}

      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={coupon ? coupon.description : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียดคูปอง"
              multiline
              rows={2}
              fullWidth
              size={'small'}
              helperText={errors.description && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'type'}
          control={control}
          defaultValue={coupon ? coupon.type : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ประเภทส่วนลด
              </InputLabel>
              <Select
                {...field}
                label="ประเภทส่วนลด"
                size={'small'}
                fullWidth
                required
              >
                <MenuItem value="delivery">ส่วนลดการจัดส่ง</MenuItem>
                <MenuItem value="bill">ส่วนลดท้ายบิล</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
          <InputLabel id="type" size={'small'}>
            เลือกวิธีการคิดส่วนลด
          </InputLabel>
          <Select
            label="เลือกวิธีการคิดส่วนลด"
            onChange={handleChangeType}
            defaultValue={coupon ? coupon?.type_value : ''}
            size={'small'}
            fullWidth
          >
            <MenuItem value="money">จำนวนเงิน</MenuItem>
            <MenuItem value="percent">เปอร์เซ็นต์</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'value'}
          control={control}
          defaultValue={coupon ? coupon.value : 0}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label={selectType === 'money' ? 'จำนวนเงิน' : 'เปอร์เซ็นต์'}
              fullWidth
              type="number"
              size={'small'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {selectType === 'money' ? 'บาท' : '%'}
                  </InputAdornment>
                ),
              }}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
              required
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'min'}
          control={control}
          defaultValue={coupon ? coupon.min : 0}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนเงินขั้นต่ำการสั่งซื้อ"
              fullWidth
              type="number"
              size={'small'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">บาท</InputAdornment>
                ),
              }}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
              required
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <Controller
          name={'max_value'}
          control={control}
          defaultValue={coupon ? coupon.max_value : 0}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนเงินสูงสุดในการลด"
              fullWidth
              type="number"
              size={'small'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">บาท</InputAdornment>
                ),
              }}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
              required
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-2">
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={localeMap[locale]}
        >
          <DateTimePicker
            sx={{
              width: '100%',
            }}
            ampm={false}
            fullWidth
            mask={maskMap[locale]}
            label="เลือกวันและเวลาเริ่มต้นคูปอง"
            value={startDate}
            // defaultValue={coupon ? coupon.start_date : ''}
            onChange={handleChangeStartDate}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </div>
      <div className="w-1/2 px-1 py-2">
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={localeMap[locale]}
        >
          <DateTimePicker
            sx={{
              width: '100%',
            }}
            ampm={false}
            fullWidth
            mask={maskMap[locale]}
            label="เลือกวันและเวลาสิ้นสุดคูปอง"
            value={endDate}
            // defaultValue={coupon ? coupon.end_date : ''}
            onChange={handleChangeEndDate}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'inventory'}
          control={control}
          defaultValue={coupon ? coupon.inventory : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนโค๊ด"
              fullWidth
              size={'small'}
              type="number"
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
              required
            />
          )}
        />
      </div>
    </div>
  );
}

CouponForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
  }),
  coupon: PropTypes.object,
  Controller: PropTypes.func,
  handleChangeType: PropTypes.func,
  handleChangeEndDate: PropTypes.func,
  handleChangeStartDate: PropTypes.func,
  control: PropTypes.object,
  selectType: PropTypes.string,
  endDate: PropTypes.datetime,
  startDate: PropTypes.datetime,
  codeFinal: PropTypes.string,
  setCodeFinal: PropTypes.string,
  openTapRandom: PropTypes.bool,
  setOpenTapRandom: PropTypes.bool,
};

CouponForm.defaultProps = {
  brand: null,
};

export default CouponForm;
