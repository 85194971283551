import React from 'react';
import { Card, CardContent } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';

import FileSync from '../../assets/images/filesync.png';
import SeasonChnage from '../../assets/images/season-change.png';
import Synchronize from '../../assets/images/synchronize.png';
import Upgrade from '../../assets/images/upgrade.png';

import LoadingLinear from './LoadingLinear';

const useStyles = makeStyles({
  backdrop: {
    zIndex: 100,
    color: '#fff',
  },
});

export default function Loading({ isLoading }) {
  const classes = useStyles();
  const imageList = _.shuffle([SeasonChnage, FileSync, Synchronize, Upgrade]);
  return (
    <div className="min-h-screen overflow-hidden">
      <Backdrop className={classes.backdrop} open={isLoading}>
        <div className="w-1/2 lg:w-1/3">
          <Card>
            <CardContent>
              <div className="flex justify-center">
                <img className="w-3/4" src={imageList[0]} />
              </div>
              <div className="text-center text-lg">
                กำลังโหลด กรุณารอสักครู่
              </div>
              <div className="p-2 px-4">
                <LoadingLinear isLoading={isLoading} />
              </div>
            </CardContent>
          </Card>
        </div>
      </Backdrop>
    </div>
  );
}

Loading.propTypes = {
  isLoading: PropTypes.bool,
};

Loading.defaultProps = {
  isLoading: true,
};
