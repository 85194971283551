/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory, Link } from 'react-router-dom';
// eslint-disable-next-line object-curly-newline
import { useForm, Controller } from 'react-hook-form';
import { Button, Card, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

const ProductSite = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  // const history = useHistory();

  useEffect(() => {
    dispatch(actions.getInformation());
    return () => {};
  }, []);

  console.log('Information', information);
  // Setting React hook form
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      product_header: information?.composite?.product_header,
      product_description: information?.composite?.product_description,
    },
  });

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      const preprocessedData = {
        composite: {
          service_header: information?.composite?.service_header,
          service_description: information?.composite?.service_description,
          portfolio_header: information?.composite?.portfolio_header,
          portfolio_description: information?.composite?.portfolio_description,
          product_header: data.product_header,
          product_description: data.product_description,
        },
      };
      console.log('preprocessedData', preprocessedData);
      event.preventDefault();
      await dispatch(
        actions.editOneInformation(information?._id, preprocessedData),
      );

      alert('สำเร็จ');
      await dispatch(actions.getInformation());
    } catch (error) {
      console.error(error);
    }
  };

  if (information.isLoading) {
    return <Loading />;
  }
  if (!information.isLoading && information.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          {/* <div className="mt-6">{renderAddButton()}</div> */}
        </div>
        <Card className="p-6">
          <div className="py-2">ข้อมูลหน้าแสดงสินค้า</div>

          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <ProductSiteForm /> */}
            <div className="flex flex-row flex-wrap">
              <div className="w-full  px-1 py-2">
                <Controller
                  name={'product_header'}
                  control={control}
                  defaultValue={information?.composite?.product_header || ''}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="ชื่อหัวข้อ"
                      fullWidth
                      size={'small'}
                      helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                    />
                  )}
                />
              </div>
              <div className="w-full  px-1 py-2">
                <Controller
                  name={'product_description'}
                  control={control}
                  defaultValue={
                    information?.composite?.product_description || ''
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="คำอธิบายหน้าแสดงสินค้า"
                      rows={3}
                      multiline={true}
                      fullWidth
                      size={'small'}
                      helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex flex-row justify-end gap-1 py-4">
              <Button
                variant="contained"
                type="submit"
                startIcon={<SaveIcon />}
              >
                บันทึก
              </Button>
            </div>
          </form>
        </Card>
      </div>
    );
  }
  return <Error message={information?.message} />;
};

ProductSite.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProductSite.defaultProps = {
  title: '',
  subtitle: '',
};

export default ProductSite;
