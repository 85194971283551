import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@mui/material';
import Proptypes from 'prop-types';
import _ from 'lodash';

import { MMS } from '../../../utils/Proptypes';

export default function ProcessTemplateTable({
  processTemplate,
  page,
  size,
  total,
  handlePushToEditPage,
  handleDelete,
  handleChangeRowsPerPage,
  handleToInfoPage,
  handleChangePage,
}) {
  return (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ชื่อ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รายละเอียด</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(processTemplate.rows) ? (
                processTemplate.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>{`${row.name}`}</TableCell>
                    <TableCell>{`${row?.detail}`}</TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'secondary'}
                          size={'small'}
                          onClick={() => handleToInfoPage(row.id)}
                        >
                          รายละเอียด
                        </Button>
                        <Button
                          variant="contained"
                          color={'warning'}
                          size={'small'}
                          onClick={() => handlePushToEditPage(row.id)}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          // disabled
                          onClick={() => {
                            handleDelete(row?.id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );
}

ProcessTemplateTable.propTypes = {
  processTemplate: MMS.processTemplate,
  page: Proptypes.number,
  size: Proptypes.number,
  total: Proptypes.number,
  handlePushToEditPage: Proptypes.func.isRequired,
  handleDelete: Proptypes.func.isRequired,
  handleChangeRowsPerPage: Proptypes.func.isRequired,
  handleChangePage: Proptypes.func.isRequired,
  handleToInfoPage: Proptypes.func.isRequired,
};
