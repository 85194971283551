import {
  OPTION_ALL,
  OPTION_CREATE,
  OPTION_GET,
  OPTION_UPDATE,
  OPTION_DELETE,
  OPTION_LOADING,
} from '../../actions/types';

const initialState = {
  option: null,
  isLoading: false,
  isCompleted: true,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case OPTION_LOADING:
      return { isLoading: true, isCompleted: true };
    case OPTION_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case OPTION_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case OPTION_CREATE:
      return { isLoading: false, isCompleted: true };
    case OPTION_UPDATE:
      return { isLoading: false, isCompleted: true };
    case OPTION_DELETE:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
