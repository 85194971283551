/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Backdrop, Button, Card, Fade, Modal } from '@mui/material';
import CourseForm from '../../components/Forms/BMS/CourseForm';
import * as actions from '../../redux/actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { ViewTitle } from '../../components/ViewTitle';
import CourseTable from '../../components/Tables/BMS/CourseTable';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};
const defaultValues = {
  name: '',
  price: '',
  detail: '',
};

export default function Course({ title, subtitle }) {
  const dispatch = useDispatch();
  const course = useSelector((state) => state.course);
  const history = useHistory();
  const { handleSubmit, control, reset } = useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [selectDetail, setSelectDetail] = useState(false);

  useEffect(() => {
    dispatch(actions.courseAll({}));

    return () => {};
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDetailOpen = (data) => {
    setSelectDetail(data);
    setOpenDetail(true);
  };
  const handleDetailClose = () => {
    setOpenDetail(false);
  };

  const handleEditButton = (id) => {
    history.push('course/edit/' + id);
  };
  const handleDeleteButton = async (id) => {
    const confirm = window.confirm('ยืนยันลบข้อมูล');
    if (confirm) {
      await dispatch(actions.courseDelete(id));
      await dispatch(actions.courseAll({}));
      handleClose();
    }
  };

  const onSubmit = async (data) => {
    const confirm = window.confirm('ยืนยันบันทึกข้อมูล');
    if (confirm) {
      await dispatch(actions.courseCreate(data));
      await dispatch(actions.courseAll({}));
      reset(defaultValues);
      handleClose();
    }
  };

  const renderDetailModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openDetail}
      onClose={handleDetailClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openDetail}>
        <Card sx={style} className="max-w-4xl">
          <div className="w-full overflow-y-auto max-h-screen">
            <div className="py-2">รายละเอียด</div>
            <div>
              <div className="text-xl">
                ชื่อคอร์ส : {selectDetail?.name || '-'}
              </div>
              <div className="text-l">ราคา : {selectDetail?.price || '-'}</div>
              <div className="text-l">
                รายเละเอียด : {selectDetail?.detail || '-'}
              </div>
            </div>
            <div className="py-2"></div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );
  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="w-full overflow-y-auto max-h-screen">
            <div className="py-2">เพิ่มข้อมูล</div>
            <div className="py-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="">
                  <CourseForm Controller={Controller} control={control} />
                  <Button variant="contained" type="submit">
                    บันทึก
                  </Button>
                  <Button variant="outlined" onClick={handleClose}>
                    ยกเลิก
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderAddButton = () => (
    <Button startIcon={<AddBoxIcon />} variant="contained" onClick={handleOpen}>
      เพิ่ม
    </Button>
  );
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  return (
    <div>
      {renderModal()}
      {renderDetailModal()}
      {renderTitle()}
      <div className="flex flex-row justify-end pb-4">{renderAddButton()}</div>
      <div className="text-lg font-semibold py-4">
        <CourseTable
          course={course?.rows}
          page={page}
          size={size}
          total={total}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          handleDetailOpen={handleDetailOpen}
          handleEditButton={handleEditButton}
          handleDeleteButton={handleDeleteButton}
        />
      </div>
    </div>
  );
}
Course.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Course.defaultProps = {
  title: '',
  subtitle: '',
};
