import api from '../../../utils/functions/api';

import {
  DELIVERY_PRICE_ALL,
  DELIVERY_PRICE_CREATE,
  DELIVERY_PRICE_GET,
  DELIVERY_PRICE_UPDATE,
  DELIVERY_PRICE_DELETE,
  DELIVERY_PRICE_ERROR,
} from '../types';

// faker.locale = "th"

export const getAllDeliveryPrice = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/delivery-price?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: DELIVERY_PRICE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: DELIVERY_PRICE_ERROR });
    throw new Error(error);
  }
};

export const getOneDeliveryPrice = (id) => async (dispatch) => {
  await api
    .get(`${process.env.REACT_APP_API_URL}/delivery-price/${id}`)
    .then((res) => {
      console.log('Request Server to Get One option');
      if (res.data) {
        dispatch({ type: DELIVERY_PRICE_GET, payload: res.data });
      } else {
        dispatch({ type: DELIVERY_PRICE_GET, payload: null });
      }
    });
};
export const createOneDeliveryPrice = (payload) => async (dispatch) => {
  await api
    .post(`${process.env.REACT_APP_API_URL}/delivery-price/`, payload)
    .then(() => {
      console.log('Request Server to Create New option');
      dispatch({ type: DELIVERY_PRICE_CREATE });
    });
};

export const editOneDeliveryPrice = (id, payload) => async (dispatch) => {
  await api
    .put(`${process.env.REACT_APP_API_URL}/delivery-price/${id}`, payload)
    .then(() => {
      console.log('Request Server to edit option');
      dispatch({ type: DELIVERY_PRICE_UPDATE });
    });
};

export const deleteOneDeliveryPrice = (id) => async (dispatch) => {
  await api
    .delete(`${process.env.REACT_APP_API_URL}/delivery-price/${id}`)
    .then(() => {
      console.log('Request Server to Delete One option');
      dispatch({ type: DELIVERY_PRICE_DELETE, payload: null });
    });
};
