import api from '../../../utils/functions/api';

import {
  STOCK_CHECKING_ALL,
  STOCK_CHECKING_CREATE,
  STOCK_CHECKING_GET,
  STOCK_CHECKING_UPDATE,
  STOCK_CHECKING_DELETE,
  STOCK_CHECKING_ERROR,
} from '../types';

// faker.locale = "th"

export const getAllStockChecking = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1, productId = '' } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/stock-checking?name=${name}&size=${size}&page=${page}&productId=${productId}`,
    );
    if (status === 200) {
      dispatch({ type: STOCK_CHECKING_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: STOCK_CHECKING_ERROR });
    throw new Error(error);
  }
};

export const getOneStockChecking = (id) => async (dispatch) => {
  await api
    .get(`${process.env.REACT_APP_API_URL}/stock-checking/${id}`)
    .then((res) => {
      console.log('Request Server to Get One option');
      if (res.data) {
        dispatch({ type: STOCK_CHECKING_GET, payload: res.data });
      } else {
        dispatch({ type: STOCK_CHECKING_GET, payload: null });
      }
    });
};
export const createOneStockChecking = (payload) => async (dispatch) => {
  await api
    .post(`${process.env.REACT_APP_API_URL}/stock-checking/`, payload)
    .then(() => {
      console.log('Request Server to Create New option');
      dispatch({ type: STOCK_CHECKING_CREATE });
    });
};

export const editOneStockChecking = (id, payload) => async (dispatch) => {
  await api
    .put(`${process.env.REACT_APP_API_URL}/stock-checking/${id}`, payload)
    .then(() => {
      console.log('Request Server to edit option');
      dispatch({ type: STOCK_CHECKING_UPDATE });
    });
};

export const deleteOneStockChecking = (id) => async (dispatch) => {
  await api
    .delete(`${process.env.REACT_APP_API_URL}/stock-checking/${id}`)
    .then(() => {
      console.log('Request Server to Delete One option');
      dispatch({ type: STOCK_CHECKING_DELETE, payload: null });
    });
};
