import Proptypes from 'prop-types';

export const processTemplate = Proptypes.shape({
  id: Proptypes.string,
  name: Proptypes.string,
  detail: Proptypes.string,
  product_id: Proptypes.object,
  step: Proptypes.arrayOf(
    Proptypes.shape({
      index: Proptypes.number,
      name: Proptypes.string,
      detail: Proptypes.string,
      status: Proptypes.string,
      department: Proptypes.string,
    }),
  ),
});

export const processTemplateArray = Proptypes.shape({
  rows: Proptypes.arrayOf(processTemplate),
  currPage: Proptypes.number,
  isComplete: Proptypes.bool,
  isLoading: Proptypes.bool,
  lastPage: Proptypes.number,
});

export default {
  processTemplate,
  processTemplateArray,
};
