import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Button,
  FormControlLabel,
  Switch,
  Autocomplete,
  TextField,
  InputAdornment,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import DescriptionIcon from '@mui/icons-material/Description';

import { Trash2 as Delete } from 'react-feather';

export function PromotionCustomer({
  selectCustomerType,
  Controller,
  control,
  setSelectCustomerType,
  customerType,
  handleChangesetCheckPromotionCustomer,
  checkPromotionCustomer,
  handleSubmit,
  onSubmitPromotionCustomerType,
  fields,
  NewProductType,
  productType,
  handleDeleteItem,
  handleAddItem,
  promotionCustomerType,
  handleOpenModalPromotion,
  setSelectDetailPromotion,
  handleDeletePromotion,
}) {
  console.log('fields customer', fields);
  const renderTable = () => (
    <div className="mt-2">
      <form onSubmit={handleSubmit(onSubmitPromotionCustomerType)}>
        <Paper>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size={'small'}>
              <colgroup>
                <col width="12%" />
                <col width="38%" />
                <col width="20%" />
                <col width="20%" />
                <col width="10%" />
              </colgroup>
              <TableHead>
                <TableRow className="bg-yellow-100">
                  <TableCell>
                    <div className="font-bold py-1">ลำดับที่</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold py-1">ประเภทสินค้า</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold py-1">ส่วนลด</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold py-1">ลดตาม</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold py-1">ดำเนินการ</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!_.isEmpty(fields) ? (
                  _.map(fields, (item, index) => (
                    <TableRow
                      key={item.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="py-1">{`${index + 1}`}</div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <div className="py-1">
                          <Controller
                            name={`promotionCustomerTypeList.${index}.productType`}
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                size={'small'}
                                {...field}
                                onChange={(e, newValue) => {
                                  field.onChange(newValue);
                                  // setSelectCustomerType(newValue);
                                }}
                                defaultValue={
                                  NewProductType ? NewProductType[0] : ' '
                                }
                                options={productType?.rows}
                                getOptionLabel={(type) => `${type?.name}`}
                                required
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="เลือกประเภทสินค้า"
                                    required
                                  />
                                )}
                              />
                            )}
                          />
                        </div>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <div className="py-1">
                          <Controller
                            name={`promotionCustomerTypeList.${index}.percent`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                fullWidth
                                required
                                type="number"
                                label="ส่วนลด"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                {...field}
                              />
                            )}
                          />
                        </div>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <div className="py-1">
                          <Controller
                            name={`promotionCustomerTypeList.${index}.follow_percent`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                fullWidth
                                required
                                type="number"
                                label="ลดตาม"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                {...field}
                              />
                            )}
                          />
                        </div>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <Button
                          size="sm"
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            handleDeleteItem(index);
                          }}
                        >
                          <Delete size={16} color="red" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <div className="text-center">ไม่มีข้อมูล</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <div className="flex justify-between gap-1 py-4">
          <Button
            size="small"
            variant="outlined"
            color="success"
            onClick={() => {
              handleAddItem();
            }}
          >
            เพิ่ม
          </Button>
          <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );

  const renderTablePromotionWithCustomerType = () => (
    <div className="mt-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={'small'}>
            <TableHead>
              <TableRow className="bg-yellow-100">
                <TableCell>
                  <div className="font-bold py-1">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">
                    หมวดหมู่ลูกค้า/กลุ่มลูกค้า
                  </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold py-1">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(promotionCustomerType.rows) ? (
                _.map(promotionCustomerType.rows, (eachPromotion, index) => (
                  <TableRow
                    key={eachPromotion.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="py-1">{`${index + 1}`}</div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div className="py-4">
                        {eachPromotion?.customer_type?.name}
                      </div>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'secondary'}
                          startIcon={<DescriptionIcon />}
                          size={'small'}
                          onClick={() => {
                            handleOpenModalPromotion();
                            setSelectDetailPromotion(eachPromotion);
                          }}
                        >
                          รายละเอียด
                        </Button>
                        <Button
                          size="sm"
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            handleDeletePromotion(eachPromotion);
                          }}
                        >
                          <Delete size={16} color="red" />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ยังไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  return (
    <div className="m-4">
      <div className="mb-4">
        <FormControlLabel
          control={
            <Switch
              checked={checkPromotionCustomer}
              onChange={handleChangesetCheckPromotionCustomer}
            />
          }
          label="ปิด-เปิด"
        />
      </div>
      <div className="mt-6 text-lg pb-2">เพิ่มส่วนลด</div>
      <div className="w-full lg:flex rounded-lg border-2 ">
        <div className="w-full lg:w-1/4 p-2">
          <div className="w-full px-1 py-2">
            <Controller
              name={'customerType'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  size={'small'}
                  {...field}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                    setSelectCustomerType(newValue);
                  }}
                  options={customerType?.rows}
                  getOptionLabel={(type) => `${type?.name}`}
                  required
                  renderInput={(params) => (
                    <TextField {...params} label="เลือกกลุ่มลูกค้า" required />
                  )}
                />
              )}
            />
          </div>
        </div>
        <div className="w-full lg:w-3/4 p-2">
          {selectCustomerType && <>{renderTable()}</>}
        </div>
      </div>

      <div className="mt-6 text-lg pb-2">
        รายการหมวดหมู่ลูกค้าที่มีส่วนลดเเล้ว
      </div>
      <div>{renderTablePromotionWithCustomerType()}</div>
    </div>
  );
}

PromotionCustomer.propTypes = {
  selectCustomerType: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.func,
  setSelectCustomerType: PropTypes.func,
  customerType: PropTypes.object,
  handleChangesetCheckPromotionCustomer: PropTypes.func,
  checkPromotionCustomer: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onSubmitPromotionCustomerType: PropTypes.func,
  fields: PropTypes.func,
  NewProductType: PropTypes.object,
  productType: PropTypes.object,
  handleDeleteItem: PropTypes.func,
  handleAddItem: PropTypes.func,
  promotionCustomerType: PropTypes.object,
  handleOpenModalPromotion: PropTypes.func,
  setSelectDetailPromotion: PropTypes.func,
  handleDeletePromotion: PropTypes.func,
};

PromotionCustomer.defaultProps = {
  customer: null,
};

export default PromotionCustomer;
