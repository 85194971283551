import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import _ from 'lodash';

import qrcode from 'qrcode';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const CoverVerticalPrint = async (paymentTransaction, setting, fromPrint) => {
  console.log('paymentTransaction in cover print', paymentTransaction);
  console.log('setting in cover print', setting);

  const opts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    mode: 'Alphanumeric',
    quality: 0.8,
    margin: 1,
    width: 250,
  };

  const genBody = (data) => {
    const product = _.map(data, (each, index) => [
      {
        text: index + 1,
      },
      {
        text: `${each?.product?.name}`,
      },
      {
        text: `${(each?.remark && each?.remark) || '-'}`,
      },
      {
        text: each?.amount || each?.quantity || '-',
        alignment: 'right',
      },

      {
        text:
          (each?.price - each?.discount || 0)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '-',
        alignment: 'right',
      },
      // {
      //   text:
      //     fromPrint === 'export'
      //       ? (each?.price * each?.amount)
      //           .toFixed(2)
      //           .replace(/\d(?=(\d{3})+\.)/g, '$&,')
      //       : ((each?.price - each?.discount || 0) * each?.quantity)
      //           .toFixed(2)
      //           .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      //   alignment: 'right',
      // },
    ]);
    return product;
  };

  const genItemBody = genBody(
    paymentTransaction?.order || paymentTransaction?.order_id?.orders,
  );

  const genQR = (data) => {
    let resQR;
    qrcode.toDataURL(data, opts, (err, res) => {
      if (err) throw err;
      resQR = res;
    });
    return resQR;
  };

  const documentRef = {
    pageSize: 'A5',
    pageOrientation: 'landscape',
    pageMargins: [20, 30, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },

    header: [
      {
        margin: [0, 10, 0, 0],
        text: 'ห้ามโยน ระวังสินค้าเสียหาย',
        alignment: 'center',
        fontSize: '14',
        color: 'red',
      },
    ],

    content: [
      {
        margin: [0, 5],
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths: ['45%', '*', '45%'],
          body: [
            [
              {
                text: 'ที่อยู่ผู้ฝาก',
                // style: 'header',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text: `${setting?.owner?.name}`,
                style: 'name',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text: `โทร ${setting?.owner?.telephone}`,
                // style: 'header',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              { text: '', border: [false, false, false, false] },
            ],
            [
              {
                text: '',
                // style: 'header',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              {
                text: 'ชื่อที่อยู่ผู้รับ',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                // style: 'header',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              {
                text: `คุณ ${paymentTransaction?.customer?.name}`,
                style: 'name',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                // style: 'header',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              {
                text:
                  fromPrint === 'export'
                    ? paymentTransaction?.customer?.address
                    : `บ้านเลขที่ ${paymentTransaction?.order_id?.delivery?.number} หมู่ที่ ${paymentTransaction?.order_id?.delivery?.village_number} ซอย ${paymentTransaction?.order_id?.delivery?.alley} ถนน ${paymentTransaction?.order_id?.delivery?.road} ตำบล ${paymentTransaction?.order_id?.delivery?.sub_district} อำเภอ ${paymentTransaction?.order_id?.delivery?.district} ตำบล ${paymentTransaction?.order_id?.delivery?.sub_district} จังหวัด ${paymentTransaction?.order_id?.delivery?.province}`,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                // style: 'header',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              {
                text: `โทร ${
                  paymentTransaction?.order_id?.delivery?.tel ||
                  paymentTransaction?.customer?.phone_number
                }`,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: '',
                // style: 'header',
                border: [false, false, false, false],
              },
              { text: '', border: [false, false, false, false] },
              {
                text:
                  fromPrint !== 'export'
                    ? `${paymentTransaction?.order_id?.delivery?.postcode}`
                    : '',
                border: [false, false, false, false],
                style: 'name',
              },
            ],
          ],
        },
      },
      {
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths: ['5%', '40%', '25%', '15%', '15%'],

          body: [
            [
              {
                fillColor: '#BFC1C0',
                text: 'ลำดับ',
                style: 'tableHeader',
                border: [true, true, true, true],
              },
              {
                fillColor: '#BFC1C0',
                text: 'ชื่อสินค้า',
                style: 'tableHeader',
                border: [true, true, true, true],
              },
              {
                fillColor: '#BFC1C0',
                text: 'รายละเอียด',
                style: 'tableHeader',
                border: [true, true, true, true],
              },
              {
                fillColor: '#BFC1C0',
                text: 'จำนวน',
                border: [true, true, true, true],
                style: 'tableHeader',
                alignment: 'right',
              },
              {
                fillColor: '#BFC1C0',
                text: 'ราคาต่อหน่วย',
                style: 'tableHeader',
                border: [true, true, true, true],
                alignment: 'right',
              },
              // {
              //   fillColor: '#BFC1C0',
              //   text: 'ราคารวม',
              //   border: [true, true, true, true],
              //   style: 'tableHeader',
              //   alignment: 'right',
              // },
            ],
            ...genItemBody,
            [
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: ' ',
                border: [false, false, false, false],
              },
              {
                text: ' ',
                border: [false, false, false, false],
              },
              {
                text: '',
                alignment: 'right',
                border: [false, false, false, false],
              },
              {
                text: '',
                alignment: 'right',
                border: [false, false, false, false],
              },
              // {
              //   text: '',
              //   border: [false, false, false, false],
              //   alignment: 'right',
              // },
            ],
          ],
        },
      },
    ],

    footer: [
      {
        margin: [20, -70, 20, 0],
        table: {
          alignment: '',
          heights: 70,
          widths: ['*', '*', '*'],
          body: [
            [
              [
                {
                  text: `หมายเลขออเดอร์ ( ${
                    paymentTransaction?.order_id?.prefix ||
                    paymentTransaction?.bill_no
                  } )`,
                  alignment: 'center',
                  border: [false, true, false, false],
                },
                {
                  text: `วันที่สั่งซื้อสินค้า ${
                    dayjs(new Date(paymentTransaction?.createdAt))
                      .locale('th')
                      .format('DD MMM BBBB HH:mm') || '-'
                  }`,
                  alignment: 'center',
                  border: [false, false, false, false],
                },
              ],

              [
                {
                  text: 'สั่งซื้อสินค้าอีกครั้งทาง',
                  border: [true, true, false, false],
                  alignment: 'center',
                },
                {
                  alignment: 'center',
                  image: 'QR',
                  width: 50,
                  margin: [0, 0, 0, 0],
                  border: [true, false, false, true],
                },
              ],

              [
                {
                  text: `จำหน่ายโดย ${setting?.owner?.name}`,
                  border: [true, true, false, false],
                  alignment: 'center',
                },
                // {
                //   image: 'logo',
                //   border: [true, true, false, false],
                //   rowSpand: 3,
                //   width: 70,
                //   margin: [0, 0, 0, 0],
                //   alignment: 'center',
                // },
                {
                  text: `${setting?.owner?.website}`,
                  alignment: 'center',
                  border: [true, false, false, false],
                },
              ],
            ],
          ],
        },
      },
    ],

    styles: {
      header: {
        fontSize: 16,
        bold: true,
        alignment: 'center',
      },

      name: {
        fontSize: 16,
        bold: true,
      },
      tab: {
        color: 'white',
      },
    },
    images: {
      QR: genQR(setting?.owner?.website || '1234'),
      // logo: setting?.logo?.url || '-',
    },
  };
  pdfMake.createPdf(documentRef).open();
};
export default CoverVerticalPrint;
