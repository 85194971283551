import {
  PLACE_ALL,
  PLACE_GET,
  PLACE_DEL,
  PLACE_PUT,
  PLACE_POST,
  PLACE_LOADING,
  PLACE_ERROR,
} from '../../actions/types';

const initialState = {
  place: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case PLACE_LOADING:
      return { isLoading: true, isCompleted: true };
    case PLACE_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case PLACE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PLACE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PLACE_POST:
      return { isLoading: false, isCompleted: true };
    case PLACE_PUT:
      return { isLoading: false, isCompleted: true };
    case PLACE_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
