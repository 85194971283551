/* eslint-disable react/prop-types */
import {
  TextField,
  Card,
  // FormControlLabel,
  // InputAdornment,
  // Switch,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
// import PlaceholderLogo from '../../../assets/images/diprom.png';
// import ImageUpload from '../../ImageUpload/ImageUpload';

export function SEOManagementForm({
  errors,
  SEOManagement,
  Controller,
  control,
  // logo,
  // setLogo,
  // shortcut,
  // setShortcut,
}) {
  // console.log('information in from', information);
  console.log('SEOManagement', SEOManagement);
  const renderCompanyInfo = () => (
    <div className="flex flex-row flex-wrap">
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'title'}
          control={control}
          defaultValue={SEOManagement ? SEOManagement?.title : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="title (ชื่อหลัก)"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'og_title'}
          control={control}
          defaultValue={SEOManagement ? SEOManagement?.og_title : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="og title"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.website && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'short_title'}
          control={control}
          defaultValue={SEOManagement ? SEOManagement?.short_title : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="short title"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.telephone && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'subtitle'}
          control={control}
          defaultValue={SEOManagement ? SEOManagement?.subtitle : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="subtitle ( ชื่อแบบย่อ )"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.email && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'keywords'}
          control={control}
          defaultValue={SEOManagement ? SEOManagement?.keywords : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="keywords ( คำค้นหาของร้าน คั่นเเต่ละคำด้วย , )"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.email && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={SEOManagement ? SEOManagement?.description : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="description ( คำอธิบายร้าน )"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.email && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'publisher'}
          control={control}
          defaultValue={SEOManagement ? SEOManagement?.publisher : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="publisher"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.email && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'googleSite'}
          control={control}
          defaultValue={SEOManagement ? SEOManagement?.googleSite : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="googleSite"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.email && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      {/* <div className="w-full lg:w-1/2 px-1 py-2 flex justify-center my-2 border-2">
        <div>
          <h1 className="px-2 py-2 font-bold">shortcut_icon</h1>
          <img
            src={SEOManagement?.shortcut_icon?.url || PlaceholderLogo}
            className="h-48 rounded-md"
          />{' '}
          <div className="w-full px-1 py-3 justify-center">
            <ImageUpload
              title="เปลี่ยนรูป"
              images={shortcut}
              maxNumber={1}
              previewSize={400}
              setImages={setShortcut}
            />
          </div>
        </div>
      </div> */}
      {/* <div className="w-full lg:w-1/2 px-1 py-2 flex justify-center my-2 border-2">
        <div>
          <h1 className="px-2 py-2 font-bold">Logo</h1>
          <img
            src={SEOManagement?.logo_url?.url || PlaceholderLogo}
            className="h-48 rounded-md"
          />
          <div className="w-full px-1 py-3 justify-center">
            <ImageUpload
              title="เปลี่ยนรูป"
              images={logo}
              maxNumber={1}
              previewSize={400}
              setImages={setLogo}
            />
          </div>
        </div>
      </div> */}
    </div>
  );

  return (
    <div>
      <div className="flex flex-wrap">
        <div className="w-full  px-2 mb-2">
          <Card className="p-4">{renderCompanyInfo()}</Card>
        </div>
      </div>
    </div>
  );
}

SEOManagementForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
    owner: PropTypes.object,
    sponsor: PropTypes.object,
    url: PropTypes.object,
    payment: PropTypes.object,
    banking: PropTypes.object,
    promptpay: PropTypes.object,
  }),
  SEOManagement: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

SEOManagementForm.defaultProps = {
  information: null,
};

export default SEOManagementForm;
