/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import _ from 'lodash';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  IconButton,
  Link,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import BuddhistEra from 'dayjs/plugin/buddhistEra';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import PropTypes from 'prop-types';

import {
  Trash as DeleteIcon,
  Paperclip,
  Image as ImageIcon,
} from 'react-feather';

import ModalUploadFile from '../../Modal/ModalUploadFile';

import * as actions from '../../../redux/actions';

// import { ImageUpload } from '../../ImageUpload';

dayjs.extend(BuddhistEra);

export default function MessageCard({
  complainMessage,
  size,
  setSize,
  showTitle = true,
  handleSubmitMessage,
}) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);

  const { control, handleSubmit, reset } = useForm();
  // const [image, setImage] = useState([]);
  // const [modalImageOpen, setModalImageOpen] = useState(false);
  const [modalFileOpen, setModalFileOpen] = useState(false);
  // const [image, setImage] = useState([]);
  const [file, setFile] = useState([]);
  const boxRef = useRef();

  // const scrollToBottom = () => {
  //   boxRef.current?.scrollIntoView({ behavior: 'smooth' });
  // };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [complainMessage]);

  useEffect(() => {
    dispatch(actions.meGet());
  }, []);

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      file: _.isEmpty(file) ? undefined : file[0],
      // image: _.isEmpty(image)
      //   ? undefined
      //   : { image: image?.[0]?.data_url, imageType: 'comment', alt: '' },
    };
    // setImage([]);
    reset({ message: '' });
    handleSubmitMessage(payload);
  };

  const handleIncrementSize = () => {
    setSize(size + 5);
  };

  const handleDeleteComment = async (id) => {
    const confirm = window.confirm('ยืนยันการลบความคิดเห็น');
    if (confirm) {
      try {
        await dispatch(actions.orderMessageDelete(id));
      } catch (error) {
        window.alert('ลบความคิดเห็นไม่สำเร็จ');
        console.error('Cannot Delete Comment', error);
      }
    }
  };

  const renderCommentLists = () => (
    <div className="w-full mt-2 h-80 min-h-80 overflow-y-auto">
      {size < (_.size(complainMessage) || 0) && (
        <div>
          <Button onClick={handleIncrementSize}> โหลดเพิ่ม</Button>
        </div>
      )}
      {_.map(complainMessage, (_row, index) => (
        <div key={index} className="my-2">
          {_row?.customer !== undefined ? (
            <div className="flex gap-2 ">
              <Avatar src={_row?.customer?.image?.url} className="uppercase">
                {_.first(_row?.customer?.name)}
              </Avatar>
              <Card>
                <CardContent>
                  <div className="text-xs text-gray-500 flex">
                    <div className="self-center">
                      <span className="font-bold text-black mb-1">
                        {_row?.customer?.name}
                      </span>{' '}
                      <br />
                      เมื่อวันที่{' '}
                      {dayjs(_row?.updatedAt).format(
                        'D MMM BBBB เวลา HH.mm น.',
                      )}
                    </div>
                  </div>
                  <div className="mt-1 break-all font-system">
                    {_row?.message}
                  </div>
                  {_row?.image && !_.isEmpty(_row?.image) && (
                    <div className="mt-1">
                      <img src={_row?.image?.url} className="h-48" />
                    </div>
                  )}
                  {_row?.file && (
                    <div className="mt-1">
                      <Link
                        href={_row?.file?.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="flex gap-2">
                          <Paperclip size={16} className="self-center" />
                          <Typography className="self-center">
                            {_row?.file?.file_name}
                          </Typography>
                        </div>
                      </Link>
                    </div>
                  )}
                </CardContent>
              </Card>
            </div>
          ) : (
            <>
              <div className="flex gap-2 justify-end">
                <Card sx={{ bgcolor: '#0079ff' }}>
                  <CardContent>
                    <div className="text-xs text-white flex">
                      <div className="self-center">
                        {' '}
                        <span className="font-bold text-white mb-1">
                          {`${_row?.employee?.firstname} ${_row?.employee?.lastname}`}
                        </span>{' '}
                        <br />
                        เมื่อวันที่{' '}
                        {dayjs(_row?.updatedAt).format(
                          'D MMM BBBB เวลา HH.mm น.',
                        )}
                      </div>
                      <div className="self-center">
                        {_row?.employee?._id === me?.userData?.id && (
                          <IconButton
                            color="error"
                            onClick={() => {
                              // eslint-disable-next-line no-underscore-dangle
                              handleDeleteComment(_row?._id);
                            }}
                          >
                            <DeleteIcon size={14} />
                          </IconButton>
                        )}
                      </div>
                    </div>
                    <div className="mt-1 font-sanserif text-white break-all">
                      {_row?.message}
                    </div>
                    {_row?.image && !_.isEmpty(_row?.image) && (
                      <div className="mt-1">
                        <img src={_row?.image?.url} className="h-48" />
                      </div>
                    )}
                    {_row?.file && (
                      <div className="mt-1">
                        <Link
                          href={_row?.file?.url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="flex gap-2">
                            <Paperclip
                              size={16}
                              className="self-center text-red-600"
                            />
                            <Typography className="self-center text-red-600">
                              {_row?.file?.file_name}
                            </Typography>
                          </div>
                        </Link>
                      </div>
                    )}
                  </CardContent>
                </Card>
                <Avatar src={_row?.employee?.image?.url} className="uppercase">
                  {_.first(_row?.employee?.firstname)}
                </Avatar>
              </div>
            </>
          )}
        </div>
      ))}
      <div ref={boxRef}></div>
    </div>
  );

  const renderCommentCreate = () => (
    <div className="mt-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="message"
          control={control}
          defaultValue=""
          placeholder="ใส่ข้อความของคุณ"
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              label="ข้อความ"
              fullWidth
              required
            />
          )}
        />
        {/* {_.size(image) > 0 && (
          <div className="my-2 ">
            <Link>
              <div className="flex gap-2">
                <ImageIcon size={16} className="self-center" />
                <Typography className="self-center">
                  {image?.[0]?.file?.name}
                </Typography>
                <IconButton color="error" onClick={() => setImage([])}>
                  <DeleteIcon size={16} />
                </IconButton>
              </div>
            </Link>
          </div>
        )} */}

        {/* <div className="flex justify-start gap-2 my-2">
          <ImageUpload images={image} setImages={setImage} maxNumber={1} />
        </div> */}
        {_.size(file) > 0 && (
          <div className="my-2 ">
            <Link>
              <div className="flex gap-2">
                <Paperclip size={16} className="self-center" />
                <Typography className="self-center">
                  {file?.[0]?.path}
                </Typography>
                <IconButton color="error" onClick={() => setFile([])}>
                  <DeleteIcon size={16} />
                </IconButton>
              </div>
            </Link>
          </div>
        )}
        <div className="flex justify-start gap-2 my-2">
          <Button
            type="button"
            size="small"
            onClick={() => setModalFileOpen(true)}
            startIcon={<Paperclip size={16} />}
          >
            ไฟล์
          </Button>
          {/* <Button
            type="button"
            size="small"
            startIcon={<ImageIcon size={16} />}
            onClick={() => setModalImageOpen(true)}
          >
            รูปภาพ
          </Button> */}
        </div>
        <div className="flex justify-end mt-2 gap-2">
          <Button type="submit" color="primary" variant="contained">
            ส่ง
          </Button>
        </div>
      </form>
    </div>
  );

  return (
    <div>
      <ModalUploadFile
        isOpen={modalFileOpen}
        files={file}
        setFiles={setFile}
        handleClose={() => setModalFileOpen(false)}
      />
      <Card>
        <CardContent>
          {showTitle && (
            <div className="font-semibold   font-display text-lg">
              แชทกับลูกค้า
            </div>
          )}
          {renderCommentLists()}
          {renderCommentCreate()}
        </CardContent>
      </Card>
    </div>
  );
}

MessageCard.propTypes = {
  comment: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
    currPage: PropTypes.number,
    isLoading: PropTypes.bool,
    isCompleted: PropTypes.bool,
    lastPage: PropTypes.number,
    total: PropTypes.number,
  }),
  size: PropTypes.number,
  showTitle: PropTypes.bool,
  setSize: PropTypes.func,
  container: PropTypes.bool,
  me: PropTypes.object,
  handleDeleteComment: PropTypes.func,
  handleSubmitMessage: PropTypes.func,
  maintenanceOrder: PropTypes.object,
};
