import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
// import _ from 'lodash';

export function AddProviderForm({
  Controller,
  control,
  selectProviderPrice,
  handleChangeProviderPrice,
  checkMorePrice,
  handleChangeCheckMorePrice,
  handleChangeCashOnDelivery,
  cashOnDelivery,
  deliveryPrice,
}) {
  console.log('deliveryPrice', deliveryPrice);
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full md:w-1/2  px-1 py-2">
        <Controller
          name={'name_th'}
          control={control}
          defaultValue={deliveryPrice ? deliveryPrice?.provider?.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อภาษาไทย"
              fullWidth
              size={'small'}
              required
            />
          )}
        />
      </div>

      <div className="w-full md:w-1/2  px-1 py-2">
        <Controller
          name={'name_en'}
          control={control}
          defaultValue={deliveryPrice ? deliveryPrice?.provider?.name_en : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อภาษาอังกฤษ"
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>

      <div className="w-full lg:w-1/2 px-1 py-2">
        <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
          <InputLabel id="type" size={'small'}>
            เลือกวิธีการคิดค่าจัดส่ง
          </InputLabel>
          <Select
            label="เลือกวิธีการคิดค่าจัดส่ง"
            onChange={handleChangeProviderPrice}
            defaultValue={
              deliveryPrice?.provider?.self_pickup ? 'self_pickup' : 'weight'
            }
            size={'small'}
            fullWidth
          >
            <MenuItem value="weight">คิดราคาตามน้ำหนัก</MenuItem>
            <MenuItem value="self_pickup">รับเองที่ร้าน</MenuItem>
          </Select>
        </FormControl>
      </div>

      {selectProviderPrice === 'self_pickup' && (
        <div className="w-full lg:w-1/2 px-1 py-2 my-auto text-red-600">
          ไม่มีค่าจัดส่งกรณีรับเองที่ร้าน
        </div>
      )}

      <div className="w-full px-1 pt-2">
        <Controller
          name={'check_more_price'}
          control={control}
          defaultValue={
            deliveryPrice ? deliveryPrice?.provider?.more_price?.available : ''
          }
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  defaultChecked={field.value}
                  onChange={handleChangeCheckMorePrice}
                />
              }
              label="ค่าใช้จ่ายอื่นๆ (เช่น ค่ากล่อง)"
            />
          )}
        />
      </div>

      {checkMorePrice && (
        <>
          <div className="w-full md:w-1/2  px-1 py-2">
            <Controller
              name={'name_more'}
              control={control}
              defaultValue={
                deliveryPrice ? deliveryPrice?.provider?.more_price?.name : ''
              }
              rules={{ required: checkMorePrice }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อค่าใช้จ่าย"
                  fullWidth
                  size={'small'}
                  required={checkMorePrice}
                />
              )}
            />
          </div>
          <div className="w-full md:w-1/2  px-1 py-2">
            <Controller
              name={'price_more'}
              control={control}
              defaultValue={
                deliveryPrice ? deliveryPrice?.provider?.more_price?.price : ''
              }
              rules={{ required: checkMorePrice }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="จำนวนเงิน"
                  fullWidth
                  type="number"
                  size={'small'}
                  required={checkMorePrice}
                />
              )}
            />
          </div>
        </>
      )}

      <div className="w-full px-1 pb-2">
        <Controller
          name={'cash_on_delivery'}
          control={control}
          defaultValue={
            deliveryPrice
              ? deliveryPrice?.provider?.more_price_on_delivery?.available
              : ''
          }
          rules={{ required: false }}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  defaultChecked={field.value}
                  onChange={handleChangeCashOnDelivery}
                />
              }
              label="เก็บเงินปลายทางมีค่าใช้จ่ายเพิ่มเติม"
            />
          )}
        />
      </div>

      {cashOnDelivery && (
        <>
          <div className="w-full md:w-1/2  px-1 pb-2">
            <Controller
              name={'price_more_on_delivery'}
              control={control}
              defaultValue={
                deliveryPrice
                  ? deliveryPrice?.provider?.more_price_on_delivery?.price
                  : ''
              }
              rules={{ required: cashOnDelivery }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="จำนวนเงิน"
                  fullWidth
                  type="number"
                  size={'small'}
                  required={cashOnDelivery}
                />
              )}
            />
          </div>
        </>
      )}
    </div>
  );
}

AddProviderForm.propTypes = {
  customer: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  handleChangeProviderPrice: PropTypes.func,
  selectProviderPrice: PropTypes.string,
  handleChangeCheckMorePrice: PropTypes.func,
  checkMorePrice: PropTypes.bool,
  cashOnDelivery: PropTypes.bool,
  handleChangeCashOnDelivery: PropTypes.func,
  deliveryPrice: PropTypes.object,
};

export default AddProviderForm;
