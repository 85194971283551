import {
  SETTING_LAYOUT_ALL,
  SETTING_LAYOUT_GET,
  SETTING_LAYOUT_DEL,
  SETTING_LAYOUT_PUT,
  SETTING_LAYOUT_POST,
  SETTING_LAYOUT_LOADING,
  SETTING_LAYOUT_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const settingLayoutCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SETTING_LAYOUT_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/setting-layout`,
      {
        ...payload,
      },
    );
    dispatch({ type: SETTING_LAYOUT_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SETTING_LAYOUT_ERROR });
    throw new Error(error);
  }
};

export const settingLayoutAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/setting-layout?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: SETTING_LAYOUT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SETTING_LAYOUT_ERROR });
    throw new Error(error);
  }
};

export const settingLayoutGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/setting-layout/${id}`,
    );
    if (status === 200) {
      dispatch({ type: SETTING_LAYOUT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SETTING_LAYOUT_ERROR });
    throw new Error(error);
  }
};

export const settingLayoutPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: SETTING_LAYOUT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/setting-layout/${id}`,
      payload,
    );
    dispatch({ type: SETTING_LAYOUT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SETTING_LAYOUT_ERROR });
    throw new Error(error);
  }
};
export const settingLayoutDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: SETTING_LAYOUT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/setting-layout/${id}`,
    );
    dispatch({ type: SETTING_LAYOUT_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SETTING_LAYOUT_ERROR });
    throw new Error(error);
  }
};
