import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Card, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';

import { AddProviderForm } from '../../components/Forms';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function EditDeliverySetting({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const deliveryPrice = useSelector((state) => state.deliveryPrice);

  const [selectProviderPrice, setSelectProviderPrice] = useState(
    deliveryPrice?.provider?.self_pickup ? 'self_pickup' : 'weight',
  );
  const [checkMorePrice, setCheckMorePrice] = useState(
    deliveryPrice?.provider?.more_price?.available,
  );
  const [cashOnDelivery, setCashOnDelivery] = useState(
    deliveryPrice?.provider?.more_price_on_delivery?.available,
  );

  const handleChangeProviderPrice = (event) => {
    setSelectProviderPrice(event.target.value);
  };

  const handleChangeCheckMorePrice = () => setCheckMorePrice(!checkMorePrice);

  const handleChangeCashOnDelivery = () => setCashOnDelivery(!cashOnDelivery);

  const { handleSubmit, control } = useForm({
    defaultValues: {
      name: '',
      type_code: '',
      description: '',
    },
  });

  useEffect(() => {
    dispatch(actions.getOneDeliveryPrice(id));
    return () => {};
  }, []);

  useEffect(() => {
    console.log('ทำ');
    if (deliveryPrice?.provider) {
      setSelectProviderPrice(
        deliveryPrice?.provider?.self_pickup ? 'self_pickup' : 'weight',
      );
    }

    setCheckMorePrice(deliveryPrice?.provider?.more_price?.available);
    setCashOnDelivery(
      deliveryPrice?.provider?.more_price_on_delivery?.available,
    );
    return () => {};
  }, [deliveryPrice]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      const payload = {
        name: data.name_th,
        name_en: data.name_en,
        free_delivery: selectProviderPrice === 'self_pickup',
        self_pickup: selectProviderPrice === 'self_pickup',
        more_price: {
          available: checkMorePrice,
          name: data.name_more || undefined,
          price: parseFloat(data.price_more) || undefined,
        },
        more_price_on_delivery: {
          available: cashOnDelivery,
          price: parseFloat(data.price_more_on_delivery) || undefined,
        },
      };
      console.log('DATA add', payload);
      await dispatch(
        // eslint-disable-next-line no-underscore-dangle
        actions.editOneDeliveryProvider(deliveryPrice?.provider?._id, payload),
      );
      await dispatch(actions.getOneDeliveryPrice(id));
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };
  if (deliveryPrice.isLoading || deliveryPrice.rows) {
    return <Loading />;
  }
  if (!deliveryPrice.isLoading && deliveryPrice.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">แก้ไขข้อมูลการจัดส่ง</div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <AddProviderForm
                Controller={Controller}
                control={control}
                handleChangeProviderPrice={handleChangeProviderPrice}
                selectProviderPrice={selectProviderPrice}
                handleChangeCheckMorePrice={handleChangeCheckMorePrice}
                checkMorePrice={checkMorePrice}
                handleChangeCashOnDelivery={handleChangeCashOnDelivery}
                cashOnDelivery={cashOnDelivery}
                deliveryPrice={deliveryPrice}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditDeliverySetting.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditDeliverySetting.defaultProps = {
  title: '',
  subtitle: '',
};
