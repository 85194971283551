import React from 'react';
import PropTypes from 'prop-types';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';

// eslint-disable-next-line arrow-body-style
const Processes = ({ title, subtitle }) => {
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
        <div className="text-lg font-semibold ml-6">ไลน์การผลิต</div>
      </div>
    </div>
  );
};

Processes.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Processes.defaultProps = {
  title: '',
  subtitle: '',
};
export default Processes;
