import {
  BOOKING_ALL,
  BOOKING_GET,
  BOOKING_DEL,
  BOOKING_PUT,
  BOOKING_POST,
  BOOKING_LOADING,
  BOOKING_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const bookingCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: BOOKING_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/booking`,
      {
        ...payload,
      },
    );
    dispatch({ type: BOOKING_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BOOKING_ERROR });
    throw new Error(error);
  }
};

export const bookingAll = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      size = '',
      page = 1,
      date = new Date(),
      selectCourse = '',
    } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/booking?name=${name}&size=${size}&page=${page}&date=${date}&selectCourse=${selectCourse}`,
    );
    if (status === 200) {
      dispatch({ type: BOOKING_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: BOOKING_ERROR });
    throw new Error(error);
  }
};

export const bookingGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/booking/${id}`,
    );
    if (status === 200) {
      dispatch({ type: BOOKING_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: BOOKING_ERROR });
    throw new Error(error);
  }
};

export const bookingPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: BOOKING_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/booking/${id}`,
      payload,
    );
    dispatch({ type: BOOKING_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BOOKING_ERROR });
    throw new Error(error);
  }
};
export const bookingDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: BOOKING_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/booking/${id}`,
    );
    dispatch({ type: BOOKING_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: BOOKING_ERROR });
    throw new Error(error);
  }
};
