import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Fade,
  Card,
  Backdrop,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  TextField,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 4,
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: theme.palette.mode === 'light' ? '#ff7d1a' : '#308fe8',
  },
}));

export function DailySaleGraph({ product, loading, productTransaction }) {
  const [labelStack, setLabelStack] = useState([
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ]);

  console.log('productTransaction', productTransaction);

  const NewProductTransactionDay = _.sortBy(
    _.map(productTransaction, (e) => {
      const data = {
        totalPrice: e.totalPrice,
        date: new Date(`${e.id.year}-${e.id.month}-${e.id.day}`).getTime(),
        day: e.id.day,
        month: e.id.month,
        year: e.id.year,
      };
      return data;
    }),
    ['date'],
  );

  const NewProductTransaction = _.sortBy(
    _.map(productTransaction, (e) => {
      const data = {
        totalPrice: e.totalPrice,
        date: new Date(`${e.id.year}-${e.id.month}`).getTime(),
        day: e.id.day,
        month: e.id.month,
        year: e.id.year,
      };
      return data;
    }),
    ['date'],
  );

  const NewProductTransactionMonth = _.groupBy(
    NewProductTransaction,
    (e) => e.date,
  );

  const NewProductTransactionYear = _.groupBy(
    NewProductTransaction,
    (e) => e.year,
  );

  const NewProductTransactionMonth1 = _.map(
    NewProductTransactionMonth,
    (e, index) => {
      // console.log('eeeee', e, index);
      const payload1 = parseInt(index, 10) + 86400000;
      const payload2 = _.sumBy(e, (ee) => ee.totalPrice);
      return [payload1, payload2?.toFixed(2)];
    },
  );

  const NewProductTransactionYear1 = _.map(
    NewProductTransactionYear,
    (e, index) => {
      // console.log('eeeee', e, index);
      const payload1 = new Date(index).getTime();
      const payload2 = _.sumBy(e, (ee) => ee.totalPrice);
      return [payload1, payload2?.toFixed(2)];
    },
  );

  console.log('NewProductTransactionYear', NewProductTransactionYear);
  console.log('NewProductTransactionYear1', NewProductTransactionYear1);

  // const yyyyy = new Date('2022-1').getTime();
  // const yyyyy1 = new Date('2022-1-21').getTime();
  // console.log('yyyyy', yyyyy);
  // console.log('yyyyy1', yyyyy1);

  const generateDayWiseTimeSeries = (count) => {
    let i = 0;
    const series = [];

    while (i < count) {
      series.push([
        NewProductTransactionDay[i].date,
        NewProductTransactionDay[i].totalPrice?.toFixed(2),
      ]);
      i += 1;
    }
    console.log('series', series);
    return series;
  };

  const chartSeries = [
    {
      name: 'ยอดขาย',
      data: NewProductTransactionMonth1,
    },
  ];

  const chartSeriesYear = [
    {
      name: 'ยอดขาย',
      data: NewProductTransactionYear1,
    },
  ];

  const chartSeriesLine = [
    {
      name: 'ยอดขาย',
      data: generateDayWiseTimeSeries(_.size(NewProductTransaction)),
    },
  ];

  const chartOptionsBar = {
    chart: {
      type: 'bar',
      width: '100%',
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
      locales: [
        {
          name: 'th',
          options: {
            months: [
              'มกราคม',
              'กุมภาพันธ์',
              'มีนาคม',
              'เมษายน',
              'พฤษภาคม',
              'มิถุนายน',
              'กรกฎาคม',
              'สิงหาคม',
              'กันยายน',
              'ตุลาคม',
              'พฤศจิกายน',
              'ธันวาคม',
            ],
            shortMonths: [
              'ม.ค.',
              'ก.พ.',
              'มี.ค.',
              'เม.ย.',
              'พ.ค.',
              'มิ.ย.',
              'ก.ค.',
              'ส.ค.',
              'ก.ย.',
              'ต.ค.',
              'พ.ย.',
              'ธ.ค.',
            ],
            days: [
              'อาทิตย์',
              'จันทร์',
              'อังคาร',
              'พุธ',
              'พฤหัสบดี',
              'ศุกร์',
              'เสาร์',
            ],
            shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            toolbar: {
              exportToSVG: 'Download SVG',
              exportToPNG: 'Download PNG',
              menu: 'Menu',
              selection: 'Selection',
              selectionZoom: 'Selection Zoom',
              zoomIn: 'Zoom In',
              zoomOut: 'Zoom Out',
              pan: 'Panning',
              reset: 'Reset Zoom',
            },
          },
        },
      ],
      defaultLocale: 'th',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      type: 'datetime',
      labels: {
        rotate: -45,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      rotateAlways: true,
      hideOverlappingLabels: true,
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
    },
    yaxis: {
      title: {
        text: 'จำนวนเงิน (บาท)',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${parseFloat(val, 10 || 0, 10).toLocaleString(
            undefined,
          )} บาท`;
        },
      },
      x: {
        format: 'MMM yyyy',
      },
      enabled: true,
    },
    legend: {
      position: 'bottom',
    },
    grid: {
      show: true,
    },
  };

  const chartOptionsBarYear = {
    chart: {
      type: 'bar',
      width: '100%',
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
      locales: [
        {
          name: 'th',
          options: {
            months: [
              'มกราคม',
              'กุมภาพันธ์',
              'มีนาคม',
              'เมษายน',
              'พฤษภาคม',
              'มิถุนายน',
              'กรกฎาคม',
              'สิงหาคม',
              'กันยายน',
              'ตุลาคม',
              'พฤศจิกายน',
              'ธันวาคม',
            ],
            shortMonths: [
              'ม.ค.',
              'ก.พ.',
              'มี.ค.',
              'เม.ย.',
              'พ.ค.',
              'มิ.ย.',
              'ก.ค.',
              'ส.ค.',
              'ก.ย.',
              'ต.ค.',
              'พ.ย.',
              'ธ.ค.',
            ],
            days: [
              'อาทิตย์',
              'จันทร์',
              'อังคาร',
              'พุธ',
              'พฤหัสบดี',
              'ศุกร์',
              'เสาร์',
            ],
            shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            toolbar: {
              exportToSVG: 'Download SVG',
              exportToPNG: 'Download PNG',
              menu: 'Menu',
              selection: 'Selection',
              selectionZoom: 'Selection Zoom',
              zoomIn: 'Zoom In',
              zoomOut: 'Zoom Out',
              pan: 'Panning',
              reset: 'Reset Zoom',
            },
          },
        },
      ],
      defaultLocale: 'th',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      type: 'datetime',
      labels: {
        rotate: -45,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      rotateAlways: true,
      hideOverlappingLabels: true,
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
    },
    yaxis: {
      title: {
        text: 'จำนวนเงิน (บาท)',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${parseFloat(val, 10 || 0, 10).toLocaleString(
            undefined,
          )} บาท`;
        },
      },
      x: {
        format: 'yyyy',
      },
      enabled: true,
    },
    legend: {
      position: 'bottom',
    },
    grid: {
      show: true,
    },
  };

  const chartOptionsLine = {
    chart: {
      type: 'line',
      height: 300,
      foreColor: '#999',
      stacked: true,
      dropShadow: {
        enabled: true,
        enabledSeries: [0],
        top: -2,
        left: 2,
        blur: 5,
        opacity: 0.06,
      },
      locales: [
        {
          name: 'th',
          options: {
            months: [
              'มกราคม',
              'กุมภาพันธ์',
              'มีนาคม',
              'เมษายน',
              'พฤษภาคม',
              'มิถุนายน',
              'กรกฎาคม',
              'สิงหาคม',
              'กันยายน',
              'ตุลาคม',
              'พฤศจิกายน',
              'ธันวาคม',
            ],
            shortMonths: [
              'ม.ค.',
              'ก.พ.',
              'มี.ค.',
              'เม.ย.',
              'พ.ค.',
              'มิ.ย.',
              'ก.ค.',
              'ส.ค.',
              'ก.ย.',
              'ต.ค.',
              'พ.ย.',
              'ธ.ค.',
            ],
            days: [
              'อาทิตย์',
              'จันทร์',
              'อังคาร',
              'พุธ',
              'พฤหัสบดี',
              'ศุกร์',
              'เสาร์',
            ],
            shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            toolbar: {
              exportToSVG: 'Download SVG',
              exportToPNG: 'Download PNG',
              menu: 'Menu',
              selection: 'Selection',
              selectionZoom: 'Selection Zoom',
              zoomIn: 'Zoom In',
              zoomOut: 'Zoom Out',
              pan: 'Panning',
              reset: 'Reset Zoom',
            },
          },
        },
      ],
      defaultLocale: 'th',
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      strokeColor: '#fff',
      strokeWidth: 3,
      strokeOpacity: 1,
      fillOpacity: 1,
      hover: {
        size: 6,
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        rotate: -45,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      rotateAlways: true,
      hideOverlappingLabels: true,
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
    },
    yaxis: {
      labels: {
        offsetX: 14,
        offsetY: -5,
      },
      tooltip: {
        enabled: true,
      },
    },
    grid: {
      padding: {
        left: -5,
        right: 5,
      },
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${parseFloat(val, 10 || 0, 10).toLocaleString(
            undefined,
          )} บาท`;
        },
      },
      x: {
        format: 'dd MMM yyyy',
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
    },
    fill: {
      type: 'solid',
      fillOpacity: 0.7,
    },
  };

  if (!loading) {
    return (
      <>
        <div className="xl:grid xl:justify-items-stretch">
          <h1 className="font-bold text-xl text-center pb-2">
            สรุปยอดขายรายวัน
          </h1>
          <div className="w-full xl:w-1/2 xl:justify-self-center">
            <Chart
              options={chartOptionsLine}
              series={chartSeriesLine}
              type="line"
            />
          </div>
          <h1 className="font-bold text-xl text-center pb-2">
            สรุปยอดขายรายเดือน
          </h1>
          <div className="w-full xl:w-1/2 xl:justify-self-center">
            <Chart options={chartOptionsBar} series={chartSeries} type="bar" />
          </div>
          <h1 className="font-bold text-xl text-center pb-2">สรุปยอดขายปี</h1>
          <div className="w-full xl:w-1/2 xl:justify-self-center">
            <Chart
              options={chartOptionsBarYear}
              series={chartSeriesYear}
              type="bar"
            />
          </div>
        </div>
      </>
    );
  }
  return <BorderLinearProgress />;
}

DailySaleGraph.propTypes = {
  product: PropTypes.array,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  periodText: PropTypes.string,
  productTransaction: PropTypes.array,
};

DailySaleGraph.defaultProps = {
  product: null,
  productTransaction: null,
};

export default DailySaleGraph;
