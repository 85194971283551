import {
  Card,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export function TableStatusOnline({
  product,
  paymentTransaction,
  customer,
  loading,
}) {
  const [dataStack, setDataStack] = useState([]);
  const [customerStack, setCustomerStack] = useState([]);
  const [orderStack, setOderStack] = useState([]);

  // console.log('paymentTransaction', paymentTransaction?.rows);
  // console.log('orderStack', orderStack);
  console.log(
    'dataStack',
    _.sortBy(customerStack, ['customer', 'price']).reverse(),
  );

  const filterPaymentCompleted = _.filter(
    paymentTransaction?.rows,
    (eachPayment) => eachPayment.status_order === 'PRODUCT_COMPLETE',
  );

  useEffect(() => {
    setOderStack([]);
    // console.log('orderStack', orderStack);
    // find order stack
    const stackData = [];
    _.map(filterPaymentCompleted, (log) => {
      _.map(log.order_id.orders, (order) => {
        const Data = {
          order,
          // eslint-disable-next-line no-underscore-dangle
          customer: log?.customer?._id,
          date: log?.updatedAt,
        };
        stackData.push(Data);
      });
    });
    // console.log('stackData', stackData);
    setOderStack(stackData);

    // find group of product
    const orderByProduct = _.groupBy(
      stackData,
      // eslint-disable-next-line no-underscore-dangle
      (order) => order.order.product,
    );
    // console.log('orderByProduct', orderByProduct);

    // find group of customer
    const orderByCustomer = _.groupBy(
      stackData,
      // eslint-disable-next-line no-underscore-dangle
      (order) => order?.customer,
    );

    // find sum price each customer
    const priceOfCustomer = _.map(customer, (eachCustomer) => {
      // eslint-disable-next-line no-underscore-dangle
      const orderOfCustomer = orderByCustomer[eachCustomer._id];

      const sumAmount = {
        customer: eachCustomer,
        price: _.sum(
          _.map(
            orderOfCustomer,
            (order) => order?.order?.quantity * order?.order?.price,
          ),
        ),
      };
      return sumAmount;
    });

    // find sum of amount
    const amountOfProduct = _.map(product, (eachProduct) => {
      // eslint-disable-next-line no-underscore-dangle
      const orderOfProduct = orderByProduct[eachProduct._id];

      const sumAmount = {
        product: eachProduct,
        amount: _.sum(
          _.map(orderOfProduct, (order) => order?.order?.quantity) || 0,
        ),
      };

      return sumAmount;
    });

    setCustomerStack(priceOfCustomer);
    setDataStack(amountOfProduct);

    return () => {};
  }, [paymentTransaction.rows.length]);

  const renderTableCustomer = () => (
    <div className="max-h-96 overflow-y-auto">
      <Paper>
        <TableContainer component={Paper}>
          <Table size="small" className="border-lg">
            <TableHead className="bg-yellow-300">
              <TableRow>
                <TableCell>
                  <div className="font-bold py-2">อันดับ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อลูกค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ยอดเงิน</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(
                _.sortBy(customerStack, ['customer', 'price']).reverse(),
                (EachCustomer, index) => (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    // className={index % 2 === 0 ? 'bg-gray-100' : ''}
                    className="hover:bg-yellow-100 py-2"
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {!loading ? (
                        <div className="font-bold my-auto py-2">
                          {EachCustomer?.customer?.name}
                        </div>
                      ) : (
                        'กำลังคำนวณ...'
                      )}
                    </TableCell>
                    <TableCell>
                      {!loading &&
                        parseInt(EachCustomer?.price, 10).toLocaleString()}{' '}
                      บาท
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  const renderTopCustomers = () => (
    <Card className="p-6">
      <div className="">
        <div className="pb-2 text-xl">รายการลูกค้าดีเด่น (ออนไลน์)</div>
        {renderTableCustomer()}
      </div>
    </Card>
  );

  const renderTableProduct = () => (
    <div className="max-h-96 overflow-y-auto">
      <Paper>
        <TableContainer component={Paper}>
          <Table size="small" className="border-lg">
            <TableHead className="bg-yellow-300">
              <TableRow>
                <TableCell>
                  <div className="font-bold py-2">อันดับ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ยอดขาย</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(
                _.sortBy(dataStack, ['product', 'amount']).reverse(),
                (EachProduct, index) => (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    // className={index % 2 === 0 ? 'bg-gray-100' : ''}
                    className="hover:bg-yellow-100 py-2"
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {!loading ? (
                        <div className="font-bold my-auto py-2">
                          {EachProduct?.product?.name}
                        </div>
                      ) : (
                        'กำลังคำนวณ...'
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-wrap">
                        <p className="text-center pr-1">
                          {!loading && EachProduct?.amount}
                        </p>
                        <p>{!loading && EachProduct?.product?.unit}</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  const renderTopProduct = () => (
    <Card className="p-6">
      <div className="">
        <div className="pb-2 text-xl">รายการสินค้าขายดี (ออนไลน์)</div>
        {renderTableProduct()}
      </div>
    </Card>
  );

  if (!loading) {
    return (
      <>
        {renderTopCustomers()}
        {renderTopProduct()}
      </>
    );
  }
  return (
    <Card className="p-6">
      <div></div>
    </Card>
  );
}

TableStatusOnline.propTypes = {
  product: PropTypes.array,
  productTransaction: PropTypes.object,
  paymentTransaction: PropTypes.object,
  customer: PropTypes.object,
  startDate: PropTypes.text,
  endDate: PropTypes.text,
  loading: PropTypes.bool,
};

TableStatusOnline.defaultProps = {
  product: null,
  productTransaction: null,
  paymentTransaction: null,
  customer: null,
  startDate: null,
  endDate: null,
  loading: null,
};

export default TableStatusOnline;
