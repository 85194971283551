import AddProductDialog from './AddProductDialog';
import AddSerialNumberDialog from './AddSerialNumberDialog';
import IncreaseSerialNumberDialog from './IncreaseSerialNumberDialog';
import AddOptionDialog from './AddOptionDialog';
import IncreaseOptionDialog from './IncreaseOptionDialog';

export {
  AddProductDialog,
  AddSerialNumberDialog,
  IncreaseSerialNumberDialog,
  AddOptionDialog,
  IncreaseOptionDialog,
};
export default {
  AddProductDialog,
  AddSerialNumberDialog,
  IncreaseSerialNumberDialog,
  AddOptionDialog,
  IncreaseOptionDialog,
};
