import {
  PROMOTION_WITH_WHOLESALE_ALL,
  PROMOTION_WITH_WHOLESALE_CREATE,
  PROMOTION_WITH_WHOLESALE_GET,
  PROMOTION_WITH_WHOLESALE_UPDATE,
  PROMOTION_WITH_WHOLESALE_DELETE,
} from '../../actions/types';

const initialState = {
  serials: null,
  isLoading: true,
  isCompleted: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case PROMOTION_WITH_WHOLESALE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PROMOTION_WITH_WHOLESALE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PROMOTION_WITH_WHOLESALE_CREATE:
      return { isLoading: true, isCompleted: true };
    case PROMOTION_WITH_WHOLESALE_UPDATE:
      return { isLoading: true, isCompleted: true };
    case PROMOTION_WITH_WHOLESALE_DELETE:
      return { isLoading: true, isCompleted: true };
    default:
      return state;
  }
}
