import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  MainDashboard,
  MainGraph,
  ProductDashboard,
  DashboardOverview,
} from '../views/Dashboard';
import { HomeNavbar } from '../components/Navbars';
import { MainFooter } from '../components/Footers';
import { MainSidebar } from '../components/Sidebar';
import { Loading } from '../components/Loading';
import { NotFound, NotAuthorized } from '../components/Error';
import accessRight from '../utils/functions/accessRight';
import * as actions from '../redux/actions';

export function Dashboard() {
  const moduleName = 'DASHBOARD';
  const prefix = '/dashboard';
  const name = 'แดชบอร์ด';

  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: DashBoard');
    dispatch(actions.meGet());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  console.log('accessRight', accessRight(me, moduleName, 0, availableModule));

  if (accessRight(me, moduleName, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />

        <div className="min-h-screen  pt-16 px-4 flex w-full">
          <MainSidebar
            onMobileClose={handleOnMobileNavClose}
            openMobile={isMobileNavOpen}
            me={me}
          />
          <div className="py-4 w-full">
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/main`} />
              <Route exact path={`${prefix}/main`}>
                <MainDashboard title="แดชบอร์ด" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/overview`}>
                <DashboardOverview title="ภาพรวม" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/product/:id`}>
                <ProductDashboard title="แดชบอร์ดสินค้า" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/graph`}>
                <MainGraph title="แดชบอร์ด" subtitle={name} />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
