import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { MATERIAL_STOCK_STATUS } from '../../utils/constants';

function DetailMaterialStock({ title, subtitle }) {
  const dispatch = useDispatch();
  const place = useSelector((state) => state.place);
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(undefined);
  const [materialData, setMaterialData] = useState();
  const history = useHistory();

  const filterMaterial = _.filter(
    materialStockLot.rows,
    (row) =>
      row?.place?.id === id &&
      row?.status !== MATERIAL_STOCK_STATUS.OUT_OF_STOCK.status_code,
  );

  const genData = () => {
    const data = [];
    _.map(filterMaterial, (_material) => {
      const findMaterial = _.find(
        data,
        (_data) => _data?.id === _material?.material?.id,
      );
      if (findMaterial === undefined) {
        const material = {
          amount: _material?.amount,
          ..._material?.material,
        };
        data.push(material);
      } else {
        data[data.indexOf(findMaterial)].amount =
          data[data.indexOf(findMaterial)].amount + _material?.amount;
      }
    });
    return data;
  };

  useEffect(() => {
    dispatch(actions.materialStockLotAll({ materialName: name, page, size }));
    dispatch(actions.placeGet(id));
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setMaterialData(genData());
    setTotal(materialData?.length);
    return () => {};
  }, [materialStockLot]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handlePushToDetailMaterialStock = async (materialId) => {
    history.push(`/ims/material/${materialId}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4">
      <div>
        <Button
          variant="contained"
          onClick={() => history.push(`/ims/material-stock/create/${id}`)}
        >
          เพิ่ม/ลด วัตถุดิบ
        </Button>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อวัตถุดิบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> คงเหลือ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(materialData) ? (
                materialData.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>{`${row.name}`}</TableCell>
                    <TableCell>{`${row.amount} ${row.unit}`}</TableCell>
                    <TableCell>
                      {' '}
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'info'}
                          size={'small'}
                          onClick={() =>
                            handlePushToDetailMaterialStock(row.id)
                          }
                        >
                          รายละเอียด
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (materialStockLot.isLoading) {
    return <Loading />;
  }
  if (!materialStockLot.isLoading && materialStockLot.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex justify-between">
          <div className="flex flex-row justify-start pb-4">
            <div>
              <BackButton />
            </div>
            <div className="text-2xl font-semibold ml-6">{place.name}</div>
          </div>
          <div>{renderAddButtom()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={materialStockLot?.message} />;
}

DetailMaterialStock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMaterialStock.defaultProps = {
  title: '',
  subtitle: '',
};

export default DetailMaterialStock;
