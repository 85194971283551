import {
  LOG_ALL,
  LOG_CREATE,
  LOG_GET,
  LOG_UPDATE,
  LOG_DELETE,
  LOG_LOADING,
} from '../../actions/types';

const initialState = {
  logs: null,
  isLoading: false,
  isCompleted: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case LOG_LOADING:
      return { isLoading: true, isCompleted: true };
    case LOG_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case LOG_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case LOG_CREATE:
      return { isLoading: false, isCompleted: true };
    case LOG_UPDATE:
      return { isLoading: false, isCompleted: true };
    case LOG_DELETE:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
