import {
  PROMOTION_BILL_PRICE_ALL,
  PROMOTION_BILL_PRICE_CREATE,
  PROMOTION_BILL_PRICE_GET,
  PROMOTION_BILL_PRICE_UPDATE,
  PROMOTION_BILL_PRICE_DELETE,
} from '../../actions/types';

const initialState = {
  serials: null,
  isLoading: true,
  isCompleted: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case PROMOTION_BILL_PRICE_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PROMOTION_BILL_PRICE_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case PROMOTION_BILL_PRICE_CREATE:
      return { isLoading: true, isCompleted: true };
    case PROMOTION_BILL_PRICE_UPDATE:
      return { isLoading: true, isCompleted: true };
    case PROMOTION_BILL_PRICE_DELETE:
      return { isLoading: true, isCompleted: true };
    default:
      return state;
  }
}
