import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
  useHistory,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Collapse, List, ListItemButton, Accordion } from '@mui/material';
import { ChevronRight, ChevronDown } from 'react-feather';
import _ from 'lodash';
import accessRight from '../../utils/functions/accessRight';
import NavSubItem from './NavSubItem';

const NavItem = ({ href, icon: Icon, title, sub, moduleName, me }) => {
  const availableModule = useSelector((state) => state.availableModule);
  const history = useHistory();
  const location = useLocation();
  const active = href
    ? !!matchPath(location.pathname, {
        path: href,
      })
    : false;
  const [open, setOpen] = useState(active);

  const handleClick = () => {
    setOpen(!open);
    history.push(href);
  };

  return (
    <div className="py-2">
      <Accordion
        elevation={0}
        expanded={open}
        onClick={() => {
          handleClick();
        }}
        sx={{
          ...(active && {
            color: 'primary.main',
          }),
        }}
        TransitionProps={{ unmountOnExit: true }}
        className="py-2 px-2 cursor-pointer"
      >
        <div className="flex gap-2">
          {Icon && <Icon size="20" />}
          <span>{title}</span>
          {_.size(sub) ? (
            <div>
              {open ? <ChevronDown size="20" /> : <ChevronRight size="20" />}
            </div>
          ) : (
            <></>
          )}
        </div>
      </Accordion>
      {_.size(sub) ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {_.map(sub, (item, index) => {
            if (accessRight(me, moduleName, item.level, availableModule)) {
              return (
                <div key={index}>
                  <List component="div" disablePadding>
                    {_.size(item.sub) > 0 ? (
                      <NavSubItem
                        href={item.href}
                        title={item.title}
                        sub={item.sub}
                        me={me}
                        moduleName={item.name}
                      />
                    ) : (
                      <ListItemButton
                        component={RouterLink}
                        to={item.href}
                        sx={{
                          pl: 6,
                          ...(item.href === location.pathname && {
                            color: 'primary.main',
                            backgroundColor: 'grey.100',
                          }),
                        }}
                      >
                        <div className="font-light text-sm">{item.title}</div>
                      </ListItemButton>
                    )}
                  </List>
                </div>
              );
            }
            return <></>;
          })}
        </Collapse>
      ) : (
        <></>
      )}
    </div>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  sub: PropTypes.array,
  me: PropTypes.object,
  moduleName: PropTypes.string,
};

export default NavItem;
