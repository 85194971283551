import { TextField } from '@mui/material';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function ProductForm({
  errors,
  product,
  fields,
  setValue,
  Controller,
  control,
}) {
  useEffect(() => {
    if (!_.isEmpty(product?.options)) {
      const optionArray = _.map(product?.options, (eachOption) => eachOption);
      setValue('optionList', optionArray);
    }
    return () => {};
  }, []);

  if (_.isEmpty(product?.options)) {
    return (
      <div className="flex flex-row flex-wrap">
        <div className="p-2 bg-gray-600 text-white m-1 w-full">
          {product.name}
        </div>
        <div className="w-full  px-1 py-2">
          <Controller
            name={'inventory'}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="จำนวน"
                fullWidth
                type="number"
                size={'small'}
                defaultValue={product?.inventory}
                // eslint-disable-next-line react/prop-types
                helperText={errors.inventory && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="">
      <div className="p-2 bg-gray-600 text-white my-1 w-full">
        {product.name}
      </div>
      {!_.isEmpty(fields) ? (
        _.map(fields, (item, index) => (
          <div className="flex" key={index}>
            <div className="w-3/5 my-auto">
              {index + 1} : {item?.name}
            </div>
            <div className="w-2/5  px-1 py-2">
              <Controller
                name={`optionList.${index}.inventory`}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="จำนวน"
                    fullWidth
                    type="number"
                    size={'small'}
                    defaultValue={item?.inventory}
                    // eslint-disable-next-line react/prop-types
                    helperText={errors.inventory && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
}

ProductForm.propTypes = {
  errors: PropTypes.shape({
    inventory: PropTypes.number,
  }),
  product: PropTypes.object,
  productType: PropTypes.object,
  fields: PropTypes.array,
  setValue: PropTypes.func,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

ProductForm.defaultProps = {
  product: null,
  productType: null,
};

export default ProductForm;
