import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Box } from '@mui/material';

export default function LoadingLinear({ isLoading, label }) {
  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);

  const progressRef = useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      <div className="flex w-full justify-center my-2">
        <Box sx={{ width: '100%' }}>
          <LinearProgress
            variant="buffer"
            value={progress}
            valueBuffer={buffer}
            style={{ height: 15 }}
          />
        </Box>
      </div>
      <div>{label}</div>
    </div>
  );
}

LoadingLinear.propTypes = {
  isLoading: PropTypes.bool,
  label: PropTypes.string,
};

LoadingLinear.defaultProps = {
  isLoading: true,
};
