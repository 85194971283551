/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import { AvailableModuleForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import Error from '../../components/Error/Error';

const ModuleSelectors = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const availableModule = useSelector((state) => state.availableModule);

  const { handleSubmit, control } = useForm();

  console.log('availableModule', availableModule);

  useEffect(() => {
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  const onSubmit = async (data, event) => {
    try {
      console.log('DATA', data);
      event.preventDefault();
      await dispatch(actions.availableModulePut(availableModule._id, data));
      alert('สำเร็จ');
      await dispatch(actions.availableModuleAll());
    } catch (error) {
      console.error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AvailableModuleForm
        Controller={Controller}
        control={control}
        availableModule={availableModule}
      />
      <div className="flex flex-row justify-end gap-1 py-4">
        <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
          บันทึก
        </Button>
      </div>
    </form>
  );

  if (availableModule.isLoading) {
    return <Loading />;
  }
  if (!availableModule.isLoading && availableModule.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderForm()}
      </div>
    );
  }

  return <Error />;
};

ModuleSelectors.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ModuleSelectors.defaultProps = {
  title: '',
  subtitle: '',
};

export default ModuleSelectors;
