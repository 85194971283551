/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  // FormControlLabel,
  // Switch,
  // Autocomplete,
  // TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  // InputAdornment,
  Modal,
  Fade,
  Backdrop,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from '@mui/material';

// import MuiAccordion from '@mui/material/Accordion';
// import MuiAccordionSummary from '@mui/material/AccordionSummary';
// import MuiAccordionDetails from '@mui/material/AccordionDetails';
// import { Trash2 as Delete } from 'react-feather';

// import { styled } from '@mui/material/styles';

import SaveIcon from '@mui/icons-material/Save';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import DescriptionIcon from '@mui/icons-material/Description';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';

import {
  PromotionProduct,
  PromotionCustomer,
  PromotionWholesale,
  PromotionCash,
  PromotionBillPrice,
} from '../../components/Promotions';

import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import Error from '../../components/Error/Error';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValue = {
  productType3: [],
};

const PromotionSetting = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const customerType = useSelector((state) => state.customerType);
  const productType = useSelector((state) => state.productType);
  const product = useSelector((state) => state.product);

  const promotionCustomerType = useSelector(
    (state) => state.promotionCustomerType,
  );
  const promotionProductType = useSelector(
    (state) => state.promotionProductType,
  );
  const promotionWholesale = useSelector((state) => state.promotionWholesale);
  const promotionBillPrice = useSelector((state) => state.promotionBillPrice);

  const setting = useSelector((state) => state.setting);
  const discountPayment = useSelector((state) => state.discountPayment);

  const NewProductType = _.map(productType?.rows, (eachProductType, index) => ({
    ...eachProductType,
    index: index + 1,
  }));

  const NewProductTypeWholesale = _.map(
    productType?.rows,
    (eachProductType, index) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      _.merge(
        { ...eachProductType, index: index + 1 },
        _.find(
          promotionWholesale?.rows,
          (each) => eachProductType?.id === each?.product_type?._id,
        ),
      ),
    // eslint-disable-next-line function-paren-newline
  );

  const newArrayProductType = _.map(
    NewProductType,
    (item) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      _.merge(
        item,
        _.find(
          promotionProductType?.rows,
          (each) => item?.id === each?.product_type?._id,
        ),
      ),
    // eslint-disable-next-line function-paren-newline
  );

  const [selectCustomerType, setSelectCustomerType] = useState();
  const [selectProductType, setSelectProductType] = useState();
  const [
    selectProductTypeWholesale,
    setSelectProductTypeWholesale,
  ] = useState();

  const [
    selectProductTypeWholesaleArray,
    setSelectProductTypeWholesaleArray,
  ] = useState([]);

  console.log(
    'selectProductTypeWholesaleArray',
    selectProductTypeWholesaleArray,
  );

  const [selectPaymentType, setSelectPaymentType] = useState();
  const [discountPaymentValue, setDiscountPaymentValue] = useState();
  const [selectPromotionType, setSelectPromotionType] = useState();
  const [selectPaymentDiscount, setSelectPaymentDiscount] = useState();
  const [selectProductDiscount, setSelectProductDiscount] = useState(
    selectProductType?.percent || 0,
  );
  const [
    selectProductDiscountFollow,
    setSelectProductDiscountFollow,
  ] = useState(selectProductType?.follow_percent || 0);
  const [checkPromotionCustomer, setCheckPromotionCustomer] = useState(
    setting?.promotion_with_customer_type,
  );
  const [checkPromotionProduct, setCheckPromotionProduct] = useState(
    setting?.promotion_with_product,
  );
  const [checkDiscountCash, setCheckDiscountCash] = useState(
    setting?.discount_cash,
  );
  const [checkWholesaleDiscount, setCheckWholesaleDiscount] = useState(
    setting?.promotion_wholesale,
  );
  const [checkBillPrice, setCheckBillPrice] = useState(
    setting?.promotion_bill_price,
  );

  const [openModalPromotion, setOpenModalPromotion] = useState(false);
  const [
    openModalAddCustomerByProductType,
    setOpenModalAddCustomerByProductType,
  ] = useState(false);

  const [
    openModalPromotionWholesale,
    setOpenModalPromotionWholesale,
  ] = useState(false);

  const [
    openModalAddCustomerByWholesale,
    setOpenModalAddCustomerByWholesale,
  ] = useState(false);

  const [selectDetailPromotion, setSelectDetailPromotion] = useState();
  const [
    selectDetailPromotionWholesale,
    setSelectDetailPromotionWholesale,
  ] = useState();

  const [
    checkAddCustomerByProductTypeArray,
    setCheckAddCustomerByProductTypeArray,
  ] = useState([]);

  const [
    checkAddCustomerByWholesaleArray,
    setCheckAddCustomerByWholesaleArray,
  ] = useState([]);

  const [
    selectEditPromotionWholesale,
    setSelectEditPromotionWholesale,
  ] = useState();

  console.log('selectEditPromotionWholesale', selectEditPromotionWholesale);

  useEffect(() => {
    console.log('set customer array');
    if (selectProductType?.customer_array) {
      setCheckAddCustomerByProductTypeArray(selectProductType?.customer_array);
    } else {
      setCheckAddCustomerByProductTypeArray([]);
    }

    return () => {};
  }, [selectProductType]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('set customer array');
    if (selectProductTypeWholesale?.customer_array) {
      setCheckAddCustomerByWholesaleArray(
        selectProductTypeWholesale?.customer_array,
      );
    } else {
      setCheckAddCustomerByWholesaleArray([]);
    }
    return () => {};
  }, [selectProductTypeWholesale]);

  const handleCheckAddCustomerByProductType = (event) => {
    const updatedList = [...checkAddCustomerByProductTypeArray];
    if (event.target.checked) {
      updatedList.push({ customer: event.target.value });
    } else {
      updatedList.splice(
        checkAddCustomerByProductTypeArray.indexOf({
          customer: event.target.value,
        }),
        1,
      );
    }
    setCheckAddCustomerByProductTypeArray(updatedList);
  };

  const handleCheckAddCustomerByWholesale = (event) => {
    const updatedList = [...checkAddCustomerByWholesaleArray];
    if (event.target.checked) {
      updatedList.push({ customer: event.target.value });
    } else {
      updatedList.splice(
        checkAddCustomerByWholesaleArray.indexOf({
          customer: event.target.value,
        }),
        1,
      );
    }
    setCheckAddCustomerByWholesaleArray(updatedList);
  };

  const handleChangeSelectPromotionType = (event) => {
    setSelectPromotionType(event.target.value);
  };

  const handleOpenModalPromotion = () => setOpenModalPromotion(true);
  const handleCloseModalPromotion = () => setOpenModalPromotion(false);

  const handleOpenModalAddCustomerByProductType = () => {
    setOpenModalAddCustomerByProductType(true);
  };
  const handleCloseModalAddCustomerByProductType = () => {
    setOpenModalAddCustomerByProductType(false);
  };

  const handleOpenModalAddCustomerByWholesale = () => {
    setOpenModalAddCustomerByWholesale(true);
  };
  const handleCloseModalAddCustomerByWholesale = () => {
    setOpenModalAddCustomerByWholesale(false);
  };

  const handleOpenModalPromotionWholesale = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    setOpenModalPromotionWholesale(true);
  const handleCloseModalPromotionWholesale = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    setOpenModalPromotionWholesale(false);

  const { handleSubmit, control, setValue } = useForm();

  const defaultValuesWholesale = {
    wholesaleArray: [
      {
        productType: '',
        discount_array: [
          {
            quantity: 0,
            percent: 0,
            follow_percent1: 0,
            follow_percent2: 0,
            whole_sale: false,
          },
        ],
      },
    ],
  };

  const {
    handleSubmit: wholesaleHandleSubmit,
    control: wholesaleControl,
    setValue: wholesaleSetValue,
    reset: wholesaleReset,
  } = useForm({ defaultValuesWholesale });

  const {
    handleSubmit: billPriceHandleSubmit,
    control: billPriceControl,
    setValue: billPriceSetValue,
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'promotionCustomerTypeList',
  });

  const {
    fields: wholesaleFields,
    append: wholesaleAppend,
    remove: wholesaleRemove,
  } = useFieldArray({
    control: wholesaleControl,
    name: 'wholesaleList',
  });

  const {
    fields: billPriceFields,
    append: billPriceAppend,
    remove: billPriceRemove,
  } = useFieldArray({
    control: billPriceControl,
    name: 'billPriceList',
  });

  useEffect(() => {
    setSelectProductDiscountFollow(selectProductType?.follow_percent || 0);
    setSelectProductDiscount(selectProductType?.percent || 0);
    return () => {};
  }, [selectProductType]);

  useEffect(() => {
    const discountPaymentFilter = _.filter(
      discountPayment?.rows,
      (row) => row.payment_type === selectPaymentType?.value,
    );
    setDiscountPaymentValue(discountPaymentFilter[0]?.percent);
    return () => {};
  }, [selectPaymentType]);

  useEffect(() => {
    wholesaleSetValue('wholesaleList', [
      {
        discount_array: [
          {
            quantity: 0,
            percent: 0,
            follow_percent1: 0,
            follow_percent2: 0,
            whole_sale: false,
          },
        ],
      },
    ]);
    return () => {};
  }, [product, selectProductTypeWholesale]);

  useEffect(() => {
    if (!_.isEmpty(promotionBillPrice?.rows)) {
      billPriceSetValue(
        'billPriceList',
        promotionBillPrice?.rows[0]?.discount_array || [
          {
            total_price: 0,
            percent: 0,
          },
        ],
      );
    }
    return () => {};
  }, [promotionBillPrice]);

  useEffect(() => {
    console.log('set product type array', loading);
    if (selectEditPromotionWholesale?.customer_array) {
      setCheckAddCustomerByWholesaleArray(
        selectEditPromotionWholesale?.customer_array,
      );
      wholesaleSetValue(
        'productType3',
        selectEditPromotionWholesale?.product_type_array,
      );
      wholesaleSetValue(
        'wholesaleList',
        selectEditPromotionWholesale?.product_type_array || [
          {
            discount_array: [
              {
                quantity: 0,
                percent: 0,
                follow_percent1: 0,
                follow_percent2: 0,
                whole_sale: false,
              },
            ],
          },
        ],
      );
    } else {
      setCheckAddCustomerByWholesaleArray([]);
    }
    setLoading(true);
    return () => {};
  }, [selectEditPromotionWholesale]);

  const handleChangePaymentDiscount = (event) => {
    setSelectPaymentDiscount(event.target.value);
  };

  const handleChangeProductDiscount = (event) => {
    setSelectProductDiscount(event.target.value);
  };

  const handleChangeProductDiscountFollow = (event) => {
    setSelectProductDiscountFollow(event.target.value);
  };

  useEffect(() => {
    dispatch(actions.customerTypeAll({}));
    dispatch(actions.productTypeAll({}));
    dispatch(actions.productAll({}));
    dispatch(actions.getAllPromotionWithCustomerType({}));
    dispatch(actions.getAllPromotionWithProductType({}));
    dispatch(actions.getAllPromotionWithWholesale({}));
    dispatch(actions.getAllPromotionBillPrice({}));
    dispatch(actions.settingAll());
    dispatch(actions.getAllDiscountPayments({}));
    return () => {};
  }, []);

  useEffect(() => {
    setCheckPromotionCustomer(setting?.promotion_with_customer_type);
    setCheckPromotionProduct(setting?.promotion_with_product);
    setCheckDiscountCash(setting?.discount_cash);
    setCheckWholesaleDiscount(setting?.promotion_wholesale);
    setCheckBillPrice(setting?.promotion_bill_price);
    // setCheckAddCustomerByProductTypeArray(
    //   setting?.customer_type_array_promotion_with_product || [],
    // );
    setCheckAddCustomerByWholesaleArray(
      setting?.customer_type_array_promotion_wholesale || [],
    );
    return () => {};
  }, [setting]);

  useEffect(() => {
    if (!promotionCustomerType.isLoading) {
      const checkPromotion = _.find(
        promotionCustomerType.rows,
        (eachPromotion) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          eachPromotion?.customer_type?._id === selectCustomerType?.id,
      );

      console.log('checkPromotion', checkPromotion);

      if (checkPromotion) {
        setValue(
          'promotionCustomerTypeList',
          _.map(checkPromotion.promotion_array, (eachPromotion) => ({
            productType: eachPromotion.product_type,
            percent: eachPromotion.percent,
            follow_percent: eachPromotion.follow_percent,
          })),
        );
      } else {
        setValue('promotionCustomerTypeList', [
          {
            productType: productType.rows[0],
            percent: 0,
            follow_percent: 0,
          },
        ]);
      }
    }
    return () => {};
  }, [productType, selectCustomerType]);

  const handleDeleteItem = (index) => {
    remove(index);
  };

  const handleAddItem = () => {
    append({
      productType: productType.rows[0],
      percent: 0,
    });
  };

  const handleDeleteItemWholesaleList = (index) => {
    wholesaleRemove(index);
  };

  const handleAddItemWholesaleList = () => {
    wholesaleAppend({
      discount_array: [
        {
          quantity: 0,
          percent: 0,
          follow_percent1: 0,
          follow_percent2: 0,
          whole_sale: false,
        },
      ],
    });
  };

  const handleDeleteItemBillPriceList = (index) => {
    billPriceRemove(index);
  };

  const handleAddItemBillPriceList = () => {
    billPriceAppend({
      total_price: 0,
      percent: 0,
    });
  };

  const onSubmitPromotionCustomerType = async (data, event) => {
    try {
      console.log('DATA', data);

      const newPromotionCustomerTypeList = _.map(
        data.promotionCustomerTypeList,
        (eachList) => {
          const dataList = {
            product_type: eachList.productType._id,
            percent: parseFloat(eachList.percent),
            follow_percent: parseFloat(eachList.follow_percent),
          };
          return dataList;
        },
      );

      const findPromotion = _.find(
        promotionCustomerType?.rows,
        (eachPromotion) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          eachPromotion?.customer_type?._id === data?.customerType?.id,
      );

      console.log('findPromotion', findPromotion);

      const payload = {
        customer_type: data.customerType.id,
        promotion_array: newPromotionCustomerTypeList,
      };

      if (findPromotion) {
        console.log('payload', payload);
        event.preventDefault();

        await dispatch(
          actions.editOnePromotionWithCustomerType(findPromotion?.id, payload),
        );
        alert('สำเร็จ');
        await dispatch(actions.customerTypeAll({}));
        await dispatch(actions.productTypeAll({}));
        await dispatch(actions.getAllPromotionWithCustomerType({}));
        await dispatch(actions.getAllPromotionWithProductType({}));
        await dispatch(actions.getAllPromotionWithWholesale({}));
        await dispatch(actions.settingAll());
        await dispatch(actions.getAllDiscountPayments({}));
      } else {
        console.log('payload', payload);
        event.preventDefault();

        await dispatch(actions.createOnePromotionWithCustomerType(payload));
        alert('สำเร็จ');
        await dispatch(actions.customerTypeAll({}));
        await dispatch(actions.productTypeAll({}));
        await dispatch(actions.getAllPromotionWithCustomerType({}));
        await dispatch(actions.getAllPromotionWithProductType({}));
        await dispatch(actions.getAllPromotionWithWholesale({}));
        await dispatch(actions.settingAll());
        await dispatch(actions.getAllDiscountPayments({}));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitPromotionWholesaleList = async (data, event) => {
    console.log('data in submit', data);
    try {
      const newWholesaleList = _.map(data?.wholesaleList, (eachList) => {
        const dataList = {
          product_type:
            eachList?.productType?._id || eachList?.product_type?._id,
          discount_array: eachList?.discount_array,
        };
        return dataList;
      });

      const payload = {
        product_type_array: newWholesaleList,
        customer_array: checkAddCustomerByWholesaleArray,
      };

      console.log('payload on submit', payload);

      if (selectEditPromotionWholesale?._id) {
        console.log('แก้ไข');
        event.preventDefault();

        await dispatch(
          actions.editOnePromotionWithWholesale(
            selectEditPromotionWholesale?._id,
            payload,
          ),
        );
        alert('สำเร็จ');
        setSelectProductTypeWholesale([]);
        setCheckAddCustomerByWholesaleArray([]);
        wholesaleSetValue('wholesaleList', [
          {
            discount_array: [
              {
                quantity: 0,
                percent: 0,
                follow_percent1: 0,
                follow_percent2: 0,
                whole_sale: false,
              },
            ],
          },
        ]);
        setSelectEditPromotionWholesale();
        await dispatch(actions.customerTypeAll({}));
        await dispatch(actions.productTypeAll({}));
        await dispatch(actions.getAllPromotionWithCustomerType({}));
        await dispatch(actions.getAllPromotionWithProductType({}));
        await dispatch(actions.getAllPromotionWithWholesale({}));
        await dispatch(actions.settingAll());
        await dispatch(actions.getAllDiscountPayments({}));
      } else {
        event.preventDefault();

        await dispatch(actions.createOnePromotionWithWholesale(payload));
        alert('สำเร็จ');
        setSelectProductTypeWholesale([]);
        setCheckAddCustomerByWholesaleArray([]);
        wholesaleSetValue('wholesaleList', [
          {
            discount_array: [
              {
                quantity: 0,
                percent: 0,
                follow_percent1: 0,
                follow_percent2: 0,
                whole_sale: false,
              },
            ],
          },
        ]);
        await dispatch(actions.customerTypeAll({}));
        await dispatch(actions.productTypeAll({}));
        await dispatch(actions.getAllPromotionWithCustomerType({}));
        await dispatch(actions.getAllPromotionWithProductType({}));
        await dispatch(actions.getAllPromotionWithWholesale({}));
        await dispatch(actions.settingAll());
        await dispatch(actions.getAllDiscountPayments({}));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitPromotionBillPrice = async (data, event) => {
    try {
      console.log('DATA', data);
      const newPromotionBillPriceList = _.map(
        data.billPriceList,
        (eachList) => {
          const dataList = {
            total_price: parseFloat(eachList.total_price),
            percent: parseFloat(eachList.percent),
          };
          return dataList;
        },
      );

      const findPromotion = _.find(
        promotionBillPrice?.rows,
        (eachPromotion) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          eachPromotion?.product_type?._id ===
          selectProductTypeWholesale?.product_type?._id,
      );

      console.log('findPromotion', findPromotion);

      if (findPromotion) {
        const payload = {
          discount_array: newPromotionBillPriceList,
        };

        console.log('payload', payload);

        event.preventDefault();

        await dispatch(
          actions.editOnePromotionWithWholesale(findPromotion?.id, payload),
        );
        alert('สำเร็จ');
        await dispatch(actions.customerTypeAll({}));
        await dispatch(actions.productTypeAll({}));
        await dispatch(actions.getAllPromotionWithCustomerType({}));
        await dispatch(actions.getAllPromotionWithProductType({}));
        await dispatch(actions.getAllPromotionWithWholesale({}));
        await dispatch(actions.getAllPromotionBillPrice({}));
        await dispatch(actions.settingAll());
        await dispatch(actions.getAllDiscountPayments({}));
      } else {
        const payload = {
          product_type: selectProductTypeWholesale?.id,
          discount_array: newPromotionBillPriceList,
        };

        console.log('payload', payload);

        event.preventDefault();

        await dispatch(actions.editOnePromotionWithBillPrice(payload));
        alert('สำเร็จ');
        await dispatch(actions.customerTypeAll({}));
        await dispatch(actions.productTypeAll({}));
        await dispatch(actions.getAllPromotionWithCustomerType({}));
        await dispatch(actions.getAllPromotionWithProductType({}));
        await dispatch(actions.getAllPromotionWithWholesale({}));
        await dispatch(actions.getAllPromotionBillPrice({}));
        await dispatch(actions.settingAll());
        await dispatch(actions.getAllDiscountPayments({}));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitDiscountPayment = async () => {
    try {
      const findPromotion = _.find(
        discountPayment?.rows,
        (eachDiscount) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          eachDiscount?.payment_type === selectPaymentType?.value,
      );

      console.log('findPromotion', findPromotion);

      const payload = {
        payment_type: selectPaymentType?.value,
        percent: parseFloat(selectPaymentDiscount),
      };

      console.log('payload', payload);
      if (findPromotion) {
        await dispatch(
          actions.editOneDiscountPayments(findPromotion.id, payload),
        );
        alert('สำเร็จ');
        await dispatch(actions.customerTypeAll({}));
        await dispatch(actions.productTypeAll({}));
        await dispatch(actions.getAllPromotionWithCustomerType({}));
        await dispatch(actions.getAllPromotionWithProductType({}));
        await dispatch(actions.getAllPromotionWithWholesale({}));
        await dispatch(actions.settingAll());
        await dispatch(actions.getAllDiscountPayments({}));
      } else {
        await dispatch(actions.createOneDiscountPayments(payload));
        alert('สำเร็จ');
        await dispatch(actions.customerTypeAll({}));
        await dispatch(actions.productTypeAll({}));
        await dispatch(actions.getAllPromotionWithCustomerType({}));
        await dispatch(actions.getAllPromotionWithProductType({}));
        await dispatch(actions.getAllPromotionWithWholesale({}));
        await dispatch(actions.settingAll());
        await dispatch(actions.getAllDiscountPayments({}));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitDiscountProductType = async () => {
    try {
      if (selectProductType?.product_type?._id) {
        const findProductType = _.find(
          promotionProductType?.rows,
          (eachProductType) =>
            // eslint-disable-next-line implicit-arrow-linebreak
            eachProductType?.product_type?._id ===
            selectProductType?.product_type?._id,
        );
        const payload = {
          product_type: selectProductType?.product_type?._id,
          percent: parseFloat(selectProductDiscount),
          follow_percent: parseFloat(selectProductDiscountFollow),
          customer_array: checkAddCustomerByProductTypeArray,
        };
        console.log('payload', payload);
        await dispatch(
          actions.editOnePromotionWithProductType(findProductType.id, payload),
        );
        alert('สำเร็จ');
        setSelectProductType(null);
        setSelectProductDiscountFollow(0);
        setSelectProductDiscount(0);
        await dispatch(actions.customerTypeAll({}));
        await dispatch(actions.productTypeAll({}));
        await dispatch(actions.getAllPromotionWithCustomerType({}));
        await dispatch(actions.getAllPromotionWithProductType({}));
        await dispatch(actions.getAllPromotionWithWholesale({}));
        await dispatch(actions.settingAll());
        await dispatch(actions.getAllDiscountPayments({}));
      } else {
        const payload = {
          product_type: selectProductType?._id,
          percent: parseFloat(selectProductDiscount),
          follow_percent: parseFloat(selectProductDiscountFollow),
          customer_array: checkAddCustomerByProductTypeArray,
        };
        await dispatch(actions.createOnePromotionWithProductType(payload));
        alert('สำเร็จ');
        setSelectProductType(null);
        setSelectProductDiscountFollow(0);
        setSelectProductDiscount(0);
        await dispatch(actions.customerTypeAll({}));
        await dispatch(actions.productTypeAll({}));
        await dispatch(actions.getAllPromotionWithCustomerType({}));
        await dispatch(actions.getAllPromotionWithProductType({}));
        await dispatch(actions.getAllPromotionWithWholesale({}));
        await dispatch(actions.settingAll());
        await dispatch(actions.getAllDiscountPayments({}));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeletePromotion = async (data) => {
    console.log('data', data);
    const confirmDelete = window.confirm('ยืนยันการลบ');
    if (confirmDelete) {
      await dispatch(actions.deleteOnePromotionWithCustomerType(data?.id));
      alert('สำเร็จ');

      await dispatch(actions.getAllPromotionWithCustomerType({}));
    }
  };

  const handleDeletePromotionWholesale = async (data) => {
    console.log('data', data);
    const confirmDelete = window.confirm('ยืนยันการลบ');
    if (confirmDelete) {
      await dispatch(actions.deleteOnePromotionWithWholesale(data?.id));
      alert('สำเร็จ');
      wholesaleSetValue('wholesaleList', [
        {
          discount_array: [
            {
              quantity: 0,
              percent: 0,
              follow_percent1: 0,
              follow_percent2: 0,
              whole_sale: false,
            },
          ],
        },
      ]);
      await dispatch(actions.getAllPromotionWithWholesale({}));
    }
  };

  const handleDeleteDiscountPayments = async (data) => {
    console.log('data', data);
    const confirmDelete = window.confirm('ยืนยันการลบ');
    if (confirmDelete) {
      await dispatch(actions.deleteOneDiscountPayments(data?.id));
      alert('สำเร็จ');

      await dispatch(actions.getAllDiscountPayments({}));
    }
  };

  const handleDeletePromotionProductType = async (data) => {
    console.log('data', data);
    const confirmDelete = window.confirm('ยืนยันการลบ');
    if (confirmDelete) {
      await dispatch(actions.deleteOnePromotionWithProductType(data?.id));
      alert('สำเร็จ');

      await dispatch(actions.getAllPromotionWithProductType({}));
    }
  };

  const handleChangesetCheckPromotionCustomer = async (event) => {
    setCheckPromotionCustomer(event.target.checked);
    try {
      await dispatch(
        actions.settingPut(setting.id, {
          type: 'promotion',
          promotion_with_customer_type: event.target.checked,
        }),
      );
      await dispatch(actions.settingAll());
      await dispatch(actions.customerTypeAll({}));
      await dispatch(actions.productTypeAll({}));
      await dispatch(actions.getAllPromotionWithCustomerType({}));
      await dispatch(actions.getAllPromotionWithProductType({}));
      await dispatch(actions.getAllPromotionWithWholesale({}));
      await dispatch(actions.getAllPromotionBillPrice({}));
      await dispatch(actions.getAllDiscountPayments({}));
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangesetCheckPromotionProduct = async (event) => {
    setCheckPromotionProduct(event.target.checked);
    try {
      await dispatch(
        actions.settingPut(setting.id, {
          type: 'promotion',
          promotion_with_product: event.target.checked,
        }),
      );
      await dispatch(actions.settingAll());
      await dispatch(actions.customerTypeAll({}));
      await dispatch(actions.productTypeAll({}));
      await dispatch(actions.getAllPromotionWithCustomerType({}));
      await dispatch(actions.getAllPromotionWithProductType({}));
      await dispatch(actions.getAllPromotionWithWholesale({}));
      await dispatch(actions.getAllPromotionBillPrice({}));
      await dispatch(actions.getAllDiscountPayments({}));
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangesetCheckDiscountCash = async (event) => {
    setCheckDiscountCash(event.target.checked);
    try {
      await dispatch(
        actions.settingPut(setting.id, {
          type: 'promotion',
          discount_cash: event.target.checked,
        }),
      );
      await dispatch(actions.settingAll());
      await dispatch(actions.customerTypeAll({}));
      await dispatch(actions.productTypeAll({}));
      await dispatch(actions.getAllPromotionWithCustomerType({}));
      await dispatch(actions.getAllPromotionWithProductType({}));
      await dispatch(actions.getAllPromotionWithWholesale({}));
      await dispatch(actions.getAllPromotionBillPrice({}));
      await dispatch(actions.getAllDiscountPayments({}));
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangesetCheckWholesaleDiscount = async (event) => {
    setCheckWholesaleDiscount(event.target.checked);
    try {
      await dispatch(
        actions.settingPut(setting.id, {
          type: 'promotion',
          promotion_wholesale: event.target.checked,
        }),
      );
      await dispatch(actions.settingAll());
      await dispatch(actions.customerTypeAll({}));
      await dispatch(actions.productTypeAll({}));
      await dispatch(actions.getAllPromotionWithCustomerType({}));
      await dispatch(actions.getAllPromotionWithProductType({}));
      await dispatch(actions.getAllPromotionWithWholesale({}));
      await dispatch(actions.getAllPromotionBillPrice({}));
      await dispatch(actions.getAllDiscountPayments({}));
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangesetCheckBillPrice = async (event) => {
    setCheckBillPrice(event.target.checked);
    try {
      await dispatch(
        actions.settingPut(setting.id, {
          type: 'promotion',
          promotion_bill_price: event.target.checked,
        }),
      );
      await dispatch(actions.settingAll());
      await dispatch(actions.customerTypeAll({}));
      await dispatch(actions.productTypeAll({}));
      await dispatch(actions.getAllPromotionWithCustomerType({}));
      await dispatch(actions.getAllPromotionWithProductType({}));
      await dispatch(actions.getAllPromotionWithWholesale({}));
      await dispatch(actions.getAllPromotionBillPrice({}));
      await dispatch(actions.getAllDiscountPayments({}));
    } catch (error) {
      console.error(error);
    }
  };

  const onsubmitCustomerByProductType = async () => {
    console.log(
      'checkAddCustomerByProductTypeArray ',
      checkAddCustomerByProductTypeArray,
    );

    setOpenModalAddCustomerByProductType(false);
  };

  const onsubmitCustomerByWholesale = async () => {
    setOpenModalAddCustomerByWholesale(false);
  };

  console.log('billPriceFields', billPriceFields);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderModalAddCustomerByProductType = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModalAddCustomerByProductType}
      onClose={handleCloseModalAddCustomerByProductType}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModalAddCustomerByProductType}>
        <Card sx={style} className="max-w-xl">
          <div className="pb-2 font-bold text-center underline text-xl">
            รายการกลุ่มลูกค้าที่ได้รับส่วนลด
          </div>
          <div className="max-h-80 overflow-y-auto">
            <div>
              {customerType?.rows.map((item, index) => (
                <div key={index}>
                  <Checkbox
                    value={item?._id}
                    defaultChecked={
                      _.map(customerType?.rows, (eachCustomerType) => {
                        const findCustomerType = _.find(
                          checkAddCustomerByProductTypeArray,
                          (each) => eachCustomerType?._id === each?.customer,
                        );
                        if (findCustomerType) {
                          return true;
                        }
                        return false;
                      })[index]
                    }
                    onChange={handleCheckAddCustomerByProductType}
                  />
                  <span>{item?.name}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button
              variant="contained"
              type="submit"
              startIcon={<SaveIcon />}
              onClick={() => onsubmitCustomerByProductType()}
            >
              ตกลง
            </Button>
            <Button
              variant="outlined"
              onClick={() => setOpenModalAddCustomerByProductType(false)}
            >
              ยกเลิก
            </Button>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderModalAddCustomerByWholesale = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModalAddCustomerByWholesale}
      onClose={handleCloseModalAddCustomerByWholesale}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModalAddCustomerByWholesale}>
        <Card sx={style} className="max-w-xl">
          <div className="pb-2 font-bold text-center underline text-xl">
            รายการกลุ่มลูกค้าที่ได้รับส่วนลด
          </div>
          <div className="max-h-80 overflow-y-auto">
            <div>
              {customerType?.rows.map((item, index) => (
                <div key={index}>
                  <Checkbox
                    value={item?._id}
                    defaultChecked={
                      _.map(customerType?.rows, (eachCustomerType) => {
                        const findCustomerType = _.find(
                          checkAddCustomerByWholesaleArray,
                          (each) => eachCustomerType?._id === each?.customer,
                        );
                        if (findCustomerType) {
                          return true;
                        }
                        return false;
                      })[index]
                    }
                    onChange={handleCheckAddCustomerByWholesale}
                  />
                  <span>{item?.name}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button
              variant="contained"
              type="submit"
              startIcon={<SaveIcon />}
              onClick={() => onsubmitCustomerByWholesale()}
            >
              บันทึก
            </Button>
            <Button
              variant="outlined"
              onClick={() => setOpenModalAddCustomerByWholesale(false)}
            >
              ยกเลิก
            </Button>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderModalDetailPromotionWithCustomerType = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModalPromotion}
      onClose={handleCloseModalPromotion}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModalPromotion}>
        <Card sx={style} className="max-w-2xl">
          <div className="pb-2 font-bold">
            รายการประเภทสินค้าและส่วนลดของ{' '}
            {selectDetailPromotion?.customer_type?.name}
          </div>
          <div className="max-h-80 overflow-y-auto border-2">
            <Paper>
              <TableContainer component={Paper}>
                <Table size="small" className="border-lg">
                  <TableHead className="bg-yellow-100">
                    <TableRow>
                      <TableCell>
                        <div className="font-bold">ลำดับที่</div>
                      </TableCell>
                      <TableCell>
                        <div className="font-bold">ประเภทสินค้า</div>
                      </TableCell>
                      <TableCell>
                        <div className="font-bold">ส่วนลด</div>
                      </TableCell>
                      <TableCell>
                        <div className="font-bold">ลดตาม</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectDetailPromotion?.promotion_array.map(
                      (eachPromotion, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                          className={index % 2 === 0 ? 'bg-gray-100' : ''}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <div className="py-2">
                              {eachPromotion?.product_type?.name}
                            </div>
                          </TableCell>
                          <TableCell>{eachPromotion?.percent} %</TableCell>
                          <TableCell>
                            {eachPromotion?.follow_percent} %
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  console.log('selectDetailPromotionWholesale', selectDetailPromotionWholesale);

  const renderModalDetailPromotionWithWholesale = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModalPromotionWholesale}
      onClose={handleCloseModalPromotionWholesale}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModalPromotionWholesale}>
        <Card sx={style} className="max-w-2xl">
          <div className="pb-2 font-bold">
            รายการประเภทสินค้าและส่วนลดของ{' '}
            {/* {selectDetailPromotionWholesale?.product_type?.name} */}
          </div>
          {_.map(
            selectDetailPromotionWholesale?.product_type_array,
            (eachProduct, index) => (
              <div className="mb-2" key={index}>
                <div>- {eachProduct?.product_type?.name}</div>
                <div className="max-h-80 overflow-y-auto border-2">
                  <Paper>
                    <TableContainer component={Paper}>
                      <Table size="small" className="border-lg">
                        <TableHead className="bg-yellow-100">
                          <TableRow>
                            <TableCell>
                              <div className="font-bold">ลำดับที่</div>
                            </TableCell>
                            <TableCell>
                              <div className="font-bold">จำนวนการซื้อ</div>
                            </TableCell>
                            <TableCell>
                              <div className="font-bold">ลดเพิ่ม</div>
                            </TableCell>
                            <TableCell>
                              <div className="font-bold">ลดตาม</div>
                            </TableCell>
                            <TableCell>
                              <div className="font-bold">ลดตาม</div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {eachProduct?.discount_array.map(
                            (eachPromotion, index2) => (
                              <TableRow
                                key={index2}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                                className={
                                  index2 % 2 === 0 ? 'bg-gray-100' : ''
                                }
                              >
                                <TableCell>{index2 + 1}</TableCell>
                                <TableCell>
                                  <div className="py-2">
                                    {eachPromotion?.quantity}{' '}
                                    {/* {eachPromotion?.discount_array[0]?.quantity
                                      ? '-'
                                      : 'ขึ้นไป'}{' '}
                                    {eachPromotion?.discount_array[0]
                                      ?.quantity &&
                                      eachPromotion?.discount_array[0]
                                        ?.quantity - 1} */}
                                    {eachPromotion?.whole_sale && <>( ลัง )</>}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  {eachPromotion?.percent} %
                                </TableCell>
                                <TableCell>
                                  {eachPromotion?.follow_percent1} %
                                </TableCell>
                                <TableCell>
                                  {eachPromotion?.follow_percent2} %
                                </TableCell>
                              </TableRow>
                            ),
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </div>
              </div>
            ),
          )}
        </Card>
      </Fade>
    </Modal>
  );

  if (productType.isLoading || setting.isLoading || customerType.isLoading) {
    return <Loading />;
  }
  if (
    !productType.isLoading &&
    productType.isCompleted &&
    !setting.isLoading &&
    setting.isCompleted &&
    !customerType.isLoading &&
    customerType.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        {renderModalDetailPromotionWithCustomerType()}
        {renderModalDetailPromotionWithWholesale()}
        {renderModalAddCustomerByProductType()}
        {renderModalAddCustomerByWholesale()}
        <div className="mb-2">
          <Card>
            <div className="p-4">
              <FormControl fullWidth>
                <InputLabel>เลือกหัวข้อส่วนลด</InputLabel>
                <Select
                  value={selectPromotionType}
                  label="เลือกหัวข้อส่วนลด"
                  onChange={handleChangeSelectPromotionType}
                >
                  <MenuItem value={1}>
                    1.ปรับลดราคาสินค้าตามประเภทสินค้า
                  </MenuItem>
                  <MenuItem value={2}>
                    2.ปรับลดราคาสินค้าตามกลุ่มลูกค้า
                  </MenuItem>
                  <MenuItem value={3}>3.ส่วนลดประเภทสินค้าขายส่ง</MenuItem>
                  <MenuItem value={4}>
                    4.เพิ่มส่วนลดลูกค้าเงินสด-เครดิต
                  </MenuItem>
                  {/* <MenuItem value={5}>เพิ่มส่วนลดท้ายบิล</MenuItem> */}
                </Select>
              </FormControl>
            </div>
          </Card>
        </div>

        {/* <div className="text-center mt-8 w-full text-gray-500">
          <div className="text-2xl pb-1">วิธีการเพิ่มส่วนลด</div>
          <div className="text-xl pb-1">1 . </div>
          <div className="text-xl pb-1">2 . </div>
          <div className="text-xl pb-1">3 . </div>
        </div> */}

        {selectPromotionType === 1 && (
          <div className="mb-2">
            <Card>
              <PromotionProduct
                handleDeletePromotionProductType={
                  handleDeletePromotionProductType
                }
                promotionProductType={promotionProductType}
                handleChangeProductDiscountFollow={
                  handleChangeProductDiscountFollow
                }
                selectProductDiscountFollow={selectProductDiscountFollow}
                handleChangeProductDiscount={handleChangeProductDiscount}
                selectProductDiscount={selectProductDiscount}
                selectProductType={selectProductType}
                onSubmitDiscountProductType={onSubmitDiscountProductType}
                newArrayProductType={newArrayProductType}
                setSelectProductType={setSelectProductType}
                Controller={Controller}
                setting={setting}
                control={control}
                checkPromotionProduct={checkPromotionProduct}
                handleChangesetCheckPromotionProduct={
                  handleChangesetCheckPromotionProduct
                }
                handleOpenModalAddCustomerByProductType={
                  handleOpenModalAddCustomerByProductType
                }
                checkAddCustomerByProductTypeArray={
                  checkAddCustomerByProductTypeArray
                }
              />
            </Card>
          </div>
        )}

        {selectPromotionType === 2 && (
          <div className="mb-2">
            <Card>
              <PromotionCustomer
                selectCustomerType={selectCustomerType}
                Controller={Controller}
                control={control}
                setSelectCustomerType={setSelectCustomerType}
                customerType={customerType}
                handleChangesetCheckPromotionCustomer={
                  handleChangesetCheckPromotionCustomer
                }
                checkPromotionCustomer={checkPromotionCustomer}
                handleSubmit={handleSubmit}
                onSubmitPromotionCustomerType={onSubmitPromotionCustomerType}
                fields={fields}
                NewProductType={NewProductType}
                productType={productType}
                handleDeleteItem={handleDeleteItem}
                handleAddItem={handleAddItem}
                promotionCustomerType={promotionCustomerType}
                handleOpenModalPromotion={handleOpenModalPromotion}
                setSelectDetailPromotion={setSelectDetailPromotion}
                handleDeletePromotion={handleDeletePromotion}
              />
            </Card>
          </div>
        )}

        {selectPromotionType === 3 && (
          <div className="mb-2">
            <Card>
              <PromotionWholesale
                setting={setting}
                loading={loading}
                setLoading={setLoading}
                wholesaleReset={wholesaleReset}
                wholesaleSetValue={wholesaleSetValue}
                handleOpenModalAddCustomerByWholesale={
                  handleOpenModalAddCustomerByWholesale
                }
                checkAddCustomerByWholesaleArray={
                  checkAddCustomerByWholesaleArray
                }
                handleChangesetCheckWholesaleDiscount={
                  handleChangesetCheckWholesaleDiscount
                }
                checkWholesaleDiscount={checkWholesaleDiscount}
                handleDeletePromotionWholesale={handleDeletePromotionWholesale}
                setSelectDetailPromotionWholesale={
                  setSelectDetailPromotionWholesale
                }
                handleOpenModalPromotionWholesale={
                  handleOpenModalPromotionWholesale
                }
                promotionWholesale={promotionWholesale}
                handleAddItemWholesaleList={handleAddItemWholesaleList}
                handleDeleteItemWholesaleList={handleDeleteItemWholesaleList}
                Controller={Controller}
                wholesaleControl={wholesaleControl}
                wholesaleFields={wholesaleFields}
                wholesaleHandleSubmit={wholesaleHandleSubmit}
                onSubmitPromotionWholesaleList={onSubmitPromotionWholesaleList}
                selectProductTypeWholesale={selectProductTypeWholesale}
                NewProductTypeWholesale={productType?.rows}
                setSelectProductTypeWholesale={setSelectProductTypeWholesale}
                setSelectProductTypeWholesaleArray={
                  setSelectProductTypeWholesaleArray
                }
                selectProductTypeWholesaleArray={
                  selectProductTypeWholesaleArray
                }
                setSelectEditPromotionWholesale={
                  setSelectEditPromotionWholesale
                }
                selectEditPromotionWholesale={selectEditPromotionWholesale}
                wholesaleRemove={wholesaleRemove}
              />
            </Card>
          </div>
        )}

        {selectPromotionType === 4 && (
          <div className="mb-2">
            <Card>
              <PromotionCash
                onSubmitDiscountPayment={onSubmitDiscountPayment}
                handleChangePaymentDiscount={handleChangePaymentDiscount}
                selectPaymentType={selectPaymentType}
                setSelectPaymentType={setSelectPaymentType}
                handleChangesetCheckDiscountCash={
                  handleChangesetCheckDiscountCash
                }
                discountPayment={discountPayment}
                handleDeleteDiscountPayments={handleDeleteDiscountPayments}
                checkDiscountCash={checkDiscountCash}
                selectPaymentDiscount={selectPaymentDiscount}
                discountPaymentValue={discountPaymentValue}
              />
            </Card>
          </div>
        )}

        {selectPromotionType === 5 && (
          <div className="mb-2">
            <Card>
              <PromotionBillPrice
                Controller={Controller}
                control={billPriceControl}
                handleChangesetCheckBillPrice={handleChangesetCheckBillPrice}
                checkBillPrice={checkBillPrice}
                handleSubmit={billPriceHandleSubmit}
                onSubmitPromotionCustomerType={onSubmitPromotionCustomerType}
                fields={billPriceFields}
                handleDeleteItem={handleDeleteItemBillPriceList}
                handleAddItem={handleAddItemBillPriceList}
              />
            </Card>
          </div>
        )}
      </div>
    );
  }

  return <Error />;
};

PromotionSetting.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PromotionSetting.defaultProps = {
  title: '',
  subtitle: '',
};

export default PromotionSetting;
