import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Fade,
  Card,
  Backdrop,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  TextField,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 4,
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: theme.palette.mode === 'light' ? '#ff7d1a' : '#308fe8',
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export function SalesGraph({
  product,
  productTransaction,
  loading,
  periodText,
}) {
  const textInput = useRef(null);
  const [dataStack, setDataStack] = useState([]);
  const [labelStack, setLabelStack] = useState([]);
  // const [orderStack, setOderStack] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [checked, setChecked] = useState([]);

  const NewProduct = _.map(product, (_product, index) => ({
    index: index + 1,
    ..._product,
  }));

  const [nameLeft, setNameLeft] = useState('');
  const [searchTermLeft, setSearchTermLeft] = useState('');
  const [nameRight, setNameRight] = useState('');
  const [searchTermRight, setSearchTermRight] = useState('');

  // console.log('nameLeft', nameLeft);
  // console.log('nameRight', nameRight);
  // const NewProductOut = _.filter(
  //   product,
  //   (each) => each?.total_price_offline_out !== 0,
  // );
  // const NewProductIn = _.filter(
  //   product,
  //   (each) => each?.total_price_offline_in !== 0,
  // );

  const TopTenProductOut = _.take(
    _.sortBy(NewProduct, ['total_price_offline_out']).reverse(),
    10,
  );
  const TopTenProductIn = _.take(
    _.sortBy(NewProduct, ['total_price_offline_in']).reverse(),
    10,
  );

  const OtherProductOut = _.slice(
    _.sortBy(NewProduct, ['total_price_offline_out']).reverse(),
    10,
    product.length,
  );

  const OtherProductIn = _.slice(
    _.sortBy(NewProduct, ['total_price_offline_in']).reverse(),
    10,
    product.length,
  );

  const [left, setLeft] = useState(
    periodText === 'ออก' ? OtherProductOut : OtherProductIn,
  );

  const [right, setRight] = useState(
    periodText === 'ออก' ? TopTenProductOut : TopTenProductIn,
  );

  // const textTest = 'NBdosfijopjOL50 หกนดร่สาืทพะฑฑฑ11';

  // console.log('TopTenProductOut', TopTenProductOut);
  // console.log('TopTenProductIn', TopTenProductIn);
  // console.log('left', left);
  // console.log('right', right);

  useEffect(() => {
    if (periodText === 'ออก') {
      setRight(TopTenProductOut);
    }
    if (periodText === 'เข้า') {
      setRight(TopTenProductIn);
    }
    return () => {};
  }, [product, periodText]);

  useEffect(() => {
    // setLeft(product);
    const delayDebounceFn = setTimeout(() => {
      setNameLeft(searchTermLeft.toUpperCase());
    }, 50);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTermLeft, product]);

  useEffect(() => {
    // setLeft(product);
    const delayDebounceFn = setTimeout(() => {
      setNameRight(searchTermRight.toUpperCase());
    }, 50);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTermRight, product]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setNameLeft('');
    setNameRight('');
    setOpenModal(false);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setSearchTermRight('');
    textInput.current.value = '';
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setSearchTermRight('');
    textInput.current.value = '';
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setSearchTermLeft('');
    textInput.current.value = '';
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setSearchTermLeft('');
    textInput.current.value = '';
    setLeft(left.concat(right));
    setRight([]);
  };

  useEffect(() => {
    // find labelStack
    // find sum of amount
    if (periodText === 'ออก') {
      const SalseOfProduct = _.map(
        right,
        (eachProduct) => eachProduct.total_price_offline_out,
      );

      const ArrayName = _.map(right, (eachProduct) => eachProduct.name);

      setLabelStack(ArrayName);
      setDataStack(SalseOfProduct);
    }
    if (periodText === 'เข้า') {
      const SalseOfProduct = _.map(
        right,
        (eachProduct) => eachProduct.total_price_offline_in,
      );

      const ArrayName = _.map(right, (eachProduct) => eachProduct.name);

      setLabelStack(ArrayName);
      setDataStack(SalseOfProduct);
    }

    return () => {};
  }, [_.size(left), _.size(right), product, productTransaction.rows.length]);

  const renderSearchLeft = () => (
    <Card>
      <div className="p-4 w-full">
        <div className="w-full">
          <TextField
            label="ค้นหา"
            fullWidth
            inputRef={textInput}
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTermLeft(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderSearchRight = () => (
    <Card>
      <div className="p-4 w-full">
        <div className="w-full">
          <TextField
            label="ค้นหา"
            fullWidth
            inputRef={textInput}
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTermRight(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const customListLeft = (items) => (
    <div className="h-96 overflow-y-auto w-full">
      <List dense component="div" role="list">
        {items &&
          items
            .filter((namess) => namess.name.toUpperCase().includes(nameLeft))
            .map((value) => (
              <ListItem
                key={value.id + value.index}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon key={value.id + value.index}>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText id={value.id} primary={value.name} />
              </ListItem>
            ))}
        <ListItem />
      </List>
    </div>
  );

  const customListRight = (items) => (
    <div className="h-96 overflow-y-auto w-full">
      <List dense component="div" role="list">
        {items &&
          items
            .filter((namess) => namess.name.toUpperCase().includes(nameRight))
            .map((value) => (
              <ListItem
                key={value.id + value.index}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon key={value.id + value.index}>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText id={value.id} primary={value.name} />
              </ListItem>
            ))}
        <ListItem />
      </List>
    </div>
  );

  const chartSeries = [
    {
      name: 'ยอดขาย',
      data: dataStack,
    },
  ];

  const chartOptions = {
    chart: {
      type: 'bar',
      width: '100%',
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0, 1, 2],
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
      },
      background: {
        enabled: true,
        foreColor: '#1f3a59',
        borderRadius: 2,
        padding: 4,
        opacity: 0.9,
        borderWidth: 1,
        borderColor: '#1f3a59',
      },
    },
    stroke: {
      width: [0, 4],
    },
    xaxis: {
      type: 'category',
      labels: {
        rotate: -45,
      },
      categories: labelStack,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      rotateAlways: true,
      hideOverlappingLabels: true,
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
    },
    yaxis: {
      title: {
        text: 'ยอดขาย',
      },
    },
    tooltip: {
      enabled: true,
    },
    legend: {
      position: 'bottom',
    },
    grid: {
      show: true,
    },
  };

  const chartOptionsPie = {
    options: {
      theme: {
        monochrome: {
          enabled: false,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: '100%',
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
    dataLabels: {
      style: {
        fontSize: '15px',
        fontWeight: 'bold',
      },
    },
    labels: labelStack,
  };

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Card sx={style} className="w-full">
          <div className="w-full text-center text-xl">
            เลือกสินค้าที่จะเเสดงบนกราฟ
          </div>
          <div className="pt-2 w-full">
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item className="w-full lg:w-1/3 h-full">
                <div className="border-2 p-2 text-center rounded-md text-white bg-yellow-600">
                  สินค้าที่ไม่เเสดง
                </div>
                {renderSearchLeft()}
                <div className="border-2 rounded-md">
                  {customListLeft(left)}
                </div>
              </Grid>
              <Grid item className="w-full lg:w-1/6 h-full">
                <Grid container direction="column" alignItems="center">
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllRight}
                    disabled={left?.length === 0}
                    aria-label="move all right"
                  >
                    ≫
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked?.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked?.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllLeft}
                    disabled={right?.length === 0}
                    aria-label="move all left"
                  >
                    ≪
                  </Button>
                </Grid>
              </Grid>
              <Grid item className="w-full lg:w-1/3 h-full">
                <div className="border-2 p-2 text-center rounded-md text-white bg-yellow-600">
                  สินค้าที่เเสดง
                </div>
                {renderSearchRight()}
                <div className="border-2 rounded-md">
                  {customListRight(right)}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="flex justify-end pt-2">
            <Button variant="contained" onClick={handleCloseModal}>
              ตกลง
            </Button>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  if (!loading) {
    return (
      <>
        <div>{renderModal()}</div>
        <div className="text-center pt-2 mt-4">
          <Button variant="contained" onClick={handleOpenModal}>
            จัดการสินค้าที่เเสดง
          </Button>
        </div>
        <div className="xl:grid xl:justify-items-stretch">
          {/* graphic area */}
          {!_.isEmpty(right) && (
            <>
              <div className="w-full xl:w-1/2 xl:justify-self-center">
                <Chart options={chartOptions} series={chartSeries} type="bar" />
              </div>
              <div className="w-full xl:w-1/2 xl:justify-self-center mt-5">
                <Chart
                  options={chartOptionsPie}
                  series={dataStack}
                  type="pie"
                />
              </div>
            </>
          )}
        </div>
      </>
    );
  }
  return <BorderLinearProgress />;
}

SalesGraph.propTypes = {
  product: PropTypes.array,
  productTransaction: PropTypes.object,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  periodText: PropTypes.string,
};

SalesGraph.defaultProps = {
  product: null,
  productTransaction: null,
};

export default SalesGraph;
