import React, { useEffect, useState } from 'react';
// import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Card, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { CouponForm } from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function EditCoupon({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const coupon = useSelector((state) => state.coupon);

  const [selectType, setSelectType] = useState();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  console.log('endDate', endDate);
  console.log('startDate', startDate);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      name: coupon.name,
      description: coupon.description,
      code: coupon.code,
      type: coupon.type,
      min: coupon.min,
      max_value: coupon.max_value,
      value: coupon.value,
      type_value: coupon.type_value,
      inventory: coupon.inventory,
    },
  });

  useEffect(() => {
    dispatch(actions.couponGet(id));
    return () => {};
  }, []);

  useEffect(() => {
    setStartDate(new Date(coupon?.start_date));
    setEndDate(new Date(coupon?.expire_date));
    setSelectType(coupon.type_value);
    return () => {};
  }, [coupon]);

  const handleChangeType = (event) => {
    setSelectType(event.target.value);
  };

  const handleChangeStartDate = (newValue) => {
    setStartDate(newValue);
  };

  const handleChangeEndDate = (newValue) => {
    setEndDate(newValue);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    console.log('data', data);
    try {
      const payload = {
        ...data,
        type_value: selectType,
        start_date: startDate,
        expire_date: endDate,
      };
      console.log('payload', payload);
      await dispatch(actions.couponPut(id, payload));
      await dispatch(actions.couponGet(id));
      alert('สำเร็จ');
      history.push('/discount/coupon');
    } catch (error) {
      console.log(error);
    }
  };
  if (coupon.isLoading || coupon.rows) {
    return <Loading />;
  }
  if (!coupon.isLoading && coupon.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">แก้ไขคูปอง</div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <CouponForm
                control={control}
                Controller={Controller}
                errors={errors}
                coupon={coupon}
                selectType={selectType}
                handleChangeType={handleChangeType}
                handleChangeStartDate={handleChangeStartDate}
                handleChangeEndDate={handleChangeEndDate}
                startDate={startDate}
                endDate={endDate}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditCoupon.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditCoupon.defaultProps = {
  title: '',
  subtitle: '',
};
