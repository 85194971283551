import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
// eslint-disable-next-line object-curly-newline
import { useForm, Controller } from 'react-hook-form';
import { Button, Card, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import SaveIcon from '@mui/icons-material/Save';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

const ContactSite = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.setting);

  const [uploadedImage1, setUploadedImage1] = useState(setting?.banner);

  useEffect(() => {
    dispatch(actions.getInformation());
    return () => {};
  }, []);

  useEffect(() => {
    setUploadedImage1(setting?.banner);
    return () => {};
  }, [setting]);

  console.log('setting', setting);

  // Setting React hook form
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const onSubmit = async (data, event) => {
    try {
      const preprocessedData = {
        ...setting,
        promptpay: {
          accountName: setting?.promptpay?.accountName,
          accountNumber: setting?.promptpay?.accountNumber,
          available: setting?.promptpay?.available,
          QrCode: setting?.promptpay?.QrCode?.id,
        },
        images: _.map(uploadedImage1, (image) => ({
          // eslint-disable-next-line no-underscore-dangle
          id: image.image_id,
          image: image.data_url || image.image.url,
        })),
        social_media: {
          location: data.location,
          tel: data.tel,
          email: data.email,
          facebook: data.facebook,
          instagram: data.instagram,
          line: data.line,
          youtube: data.youtube,
        },
      };
      console.log('preprocessedData', preprocessedData);
      event.preventDefault();
      await dispatch(
        // eslint-disable-next-line no-underscore-dangle
        actions.settingPut(setting?.id, preprocessedData),
      );
      alert('สำเร็จ');
      await dispatch(actions.settingAll());
    } catch (error) {
      console.error(error);
    }
  };

  if (setting.isLoading) {
    return <Loading />;
  }

  if (!setting.isLoading && setting.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          {/* <div className="mt-6">{renderAddButton()}</div> */}
        </div>
        <Card className="p-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <MainSiteForm /> */}
            <div className="flex flex-row flex-wrap">
              <div className="w-full px-1 py-2 md:w-1/2">
                <div className="py-2">โซเซียลมีเดีย</div>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'facebook'}
                    control={control}
                    defaultValue={setting?.social_media?.facebook || ''}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="facebook link"
                        fullWidth
                        size={'small'}
                        helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'instagram'}
                    control={control}
                    defaultValue={setting?.social_media?.instagram || ''}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="instagram link"
                        fullWidth
                        size={'small'}
                        helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'youtube'}
                    control={control}
                    defaultValue={setting?.social_media?.youtube || ''}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="youtube link"
                        fullWidth
                        size={'small'}
                        helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'email'}
                    control={control}
                    defaultValue={setting?.social_media?.email || ''}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="email"
                        fullWidth
                        size={'small'}
                        helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'line'}
                    control={control}
                    defaultValue={setting?.social_media?.line || ''}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="line id"
                        fullWidth
                        size={'small'}
                        helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'tel'}
                    control={control}
                    defaultValue={setting?.social_media?.tel || ''}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="เบอร์โทรศัพท์"
                        fullWidth
                        size={'small'}
                        helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="w-full px-1 py-2 md:w-1/2">
                <div className="py-2">ตำแหน่งที่ตั้ง</div>

                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'location'}
                    control={control}
                    defaultValue={setting?.social_media?.location || ''}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="คำค้นหาในแผนที่"
                        multiline={true}
                        fullWidth
                        size={'small'}
                        helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-end gap-1 py-4">
              <Button
                variant="contained"
                type="submit"
                startIcon={<SaveIcon />}
              >
                บันทึก
              </Button>
            </div>
          </form>
        </Card>
      </div>
    );
  }
  return <Error message={setting?.message} />;
};

ContactSite.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ContactSite.defaultProps = {
  title: '',
  subtitle: '',
};

export default ContactSite;
