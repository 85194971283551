import {
  MATERIAL_TRANSACTION_TYPES_ALL,
  MATERIAL_TRANSACTION_TYPES_GET,
  MATERIAL_TRANSACTION_TYPES_DEL,
  MATERIAL_TRANSACTION_TYPES_PUT,
  MATERIAL_TRANSACTION_TYPES_POST,
  MATERIAL_TRANSACTION_TYPES_LOADING,
  MATERIAL_TRANSACTION_TYPES_ERROR,
} from '../../actions/types';

const initialState = {
  materialTransactionType: null,
  isLoading: true,
  isCompleted: true,
};
// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case MATERIAL_TRANSACTION_TYPES_LOADING:
      return { isLoading: true, isCompleted: true };
    case MATERIAL_TRANSACTION_TYPES_ERROR:
      return { isLoading: false, isCompleted: false, ...action.payload };
    case MATERIAL_TRANSACTION_TYPES_ALL:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MATERIAL_TRANSACTION_TYPES_GET:
      return {
        ...action.payload,
        isLoading: false,
        isCompleted: true,
      };
    case MATERIAL_TRANSACTION_TYPES_POST:
      return { isLoading: false, isCompleted: true };
    case MATERIAL_TRANSACTION_TYPES_PUT:
      return { isLoading: false, isCompleted: true };
    case MATERIAL_TRANSACTION_TYPES_DEL:
      return { isLoading: false, isCompleted: true };
    default:
      return state;
  }
}
