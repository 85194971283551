/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

// import { useHistory, Link } from 'react-router-dom';
// eslint-disable-next-line object-curly-newline
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Card,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import 'dayjs/locale/th';

dayjs.locale('th');
dayjs.extend(buddhistEra);

const PortfolioSite = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const information = useSelector((state) => state.information);
  const portfolio = useSelector((state) => state.portfolio);

  const [searchTerm, setSearchTerm] = useState('');

  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(undefined);

  // const history = useHistory();

  useEffect(() => {
    dispatch(actions.getInformation());
    dispatch(actions.portfolioAll({ name, page, size }));

    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(portfolio?.total);
    return () => {};
  }, [portfolio]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleDeletePortfolio = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.portfolioDelete(id));
        await dispatch(actions.portfolioAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  // console.log('information', information);
  // console.log('portfolio', portfolio);
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      portfolio_header: information?.composite?.portfolio_header,
      portfolio_description: information?.composite?.portfolio_description,
    },
  });

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      const preprocessedData = {
        composite: {
          service_header: information?.composite?.service_header,
          service_description: information?.composite?.service_description,
          product_header: information?.composite?.product_header,
          product_description: information?.composite?.product_description,
          portfolio_header: data.portfolio_header,
          portfolio_description: data.portfolio_description,
        },
      };
      console.log('preprocessedData', preprocessedData);
      event.preventDefault();
      await dispatch(
        actions.editOneInformation(information?._id, preprocessedData),
      );
      alert('สำเร็จ');
      await dispatch(actions.getInformation());
    } catch (error) {
      console.error(error);
    }
  };

  const handlePushToDetailPortfolio = (id) => {
    history.push(`/cms/portfolio/${id}`);
  };

  const handlePushToCreatePortfolio = () => {
    history.push('/cms/portfolio/create');
  };

  const handlePushToEditPortfolio = (id) => {
    history.push(`/cms/portfolio/edit/${id}`);
  };

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <div>
        <Button variant="contained" onClick={handlePushToCreatePortfolio}>
          เพิ่มผลงาน
        </Button>
      </div>
    </div>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ชื่อหัวข้อ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันที่สร้าง</div>
                </TableCell>

                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(portfolio?.rows) ? (
                portfolio?.rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <p>{row?.header}</p>
                    </TableCell>
                    <TableCell>
                      {`${dayjs(row?.date).format('D MMM BBBB')}`}
                      {' , '}
                      {`${dayjs(row?.date).format('HH:mm')}`}
                    </TableCell>

                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'info'}
                          size={'small'}
                          onClick={() => handlePushToDetailPortfolio(row?.id)}
                        >
                          รายละเอียด
                        </Button>
                        <Button
                          variant="contained"
                          color={'yellow'}
                          size={'small'}
                          onClick={() => {
                            handlePushToEditPortfolio(row?.id);
                          }}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeletePortfolio(row?.id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (portfolio.isLoading || information.isLoading) {
    return <Loading />;
  }
  if (
    !portfolio.isLoading &&
    portfolio.isCompleted &&
    !information.isLoading &&
    information.isCompleted
  ) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButton()}</div>
        </div>
        <Card className="p-6">
          <div className="py-2">ข้อมูลหน้าแสดงผลงาน</div>

          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <PortfolioSiteForm /> */}
            <div className="flex flex-row flex-wrap">
              <div className="w-full  px-1 py-2">
                <Controller
                  name={'portfolio_header'}
                  control={control}
                  defaultValue={
                    information ? information?.composite?.portfolio_header : ''
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="ชื่อหัวข้อ"
                      fullWidth
                      size={'small'}
                      helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                    />
                  )}
                />
              </div>
              <div className="w-full  px-1 py-2">
                <Controller
                  name={'portfolio_description'}
                  control={control}
                  defaultValue={
                    information
                      ? information?.composite?.portfolio_description
                      : ''
                  }
                  rules={{ required: false }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="คำอธิบายหน้าแสดงผลงาน"
                      rows={3}
                      multiline={true}
                      fullWidth
                      size={'small'}
                      helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex flex-row justify-end gap-1 py-4">
              <Button
                variant="contained"
                type="submit"
                startIcon={<SaveIcon />}
              >
                บันทึก
              </Button>
            </div>
          </form>
        </Card>

        {renderTable()}
      </div>
    );
  }
  return <Error message={information?.message} />;
};

PortfolioSite.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PortfolioSite.defaultProps = {
  title: '',
  subtitle: '',
};

export default PortfolioSite;
