/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import { Card, Checkbox, Rating, IconButton, TextField } from '@mui/material';

import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import SellIcon from '@mui/icons-material/Sell';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import { red } from '@mui/material/colors';

function ProductCard({
  imageCheck,
  nameCheck,
  typeCheck,
  brandCheck,
  typeCodeCheck,
  tagCheck,
  detailCheck,
  priceCheck,
  scoreCheck,
  statusCheck,
  inventoryCheck,
  addCardCheck,
  selectDiscountLayout,
}) {
  return (
    <Card className="bg-white duration-150 ease-in-out transform hover:-translate-y-1  hover:shadow-2xl shadow-xl  border h-full hover:cursor-pointer w-72">
      <div className="p-2">
        {imageCheck && (
          <div className="relative h-40 w-40 mx-auto">
            <img
              src="https://storage.googleapis.com/mydev_eonlineshop/other/thumbnails/1645339249181_800x800.png"
              alt="Example Product"
              className="rounded-md"
            />
          </div>
        )}

        <div className="p-2 py-2">
          {nameCheck && (
            <p className="pt-1 w-full line-clamp-2 hover:text-clip ">
              สินค้าตัวอย่าง
            </p>
          )}

          {typeCodeCheck && (
            <div className="flex gap-1">
              <p className="text-xs pt-1 text-gray-400">รหัส</p>
              <a className="text-xs pt-1 hover:underline cursor-pointer">
                iarc123456
              </a>
            </div>
          )}

          {typeCheck && (
            <div className="flex gap-1">
              <p className="text-xs pt-1 text-gray-400">หมวดหมู่</p>
              <a className="text-xs pt-1 hover:underline cursor-pointer">
                หมวดหมู่01
              </a>
            </div>
          )}

          {brandCheck && (
            <div className="flex gap-1">
              <p className="text-xs pt-1 text-gray-400">แบรนด์</p>
              <a className="text-xs pt-1 hover:underline cursor-pointer">
                แบรนด์01
              </a>
            </div>
          )}

          {tagCheck && (
            <div className="flex gap-1 pt-1">
              <SellIcon color="disabled" fontSize="small" />
              <a className="text-xs hover:underline cursor-pointer">tag01</a>

              <a className="text-xs hover:underline cursor-pointer">tag02</a>

              <a className="text-xs hover:underline cursor-pointer">tag03</a>
            </div>
          )}

          {detailCheck && (
            <p className="text-sm pt-1 w-full line-clamp-2">
              รายละเอียดของสินค้าตัวอย่างซึ่งสินค้านี้คือสินค้าตัวอย่างที่ใช้แสดงผลการปรับเปลี่ยนการแสดงผลของสินค้าที่หน้าเว็บไซต์
            </p>
          )}

          {priceCheck && (
            <div className="flex">
              <div className="text-sm md:text-sm lg:text-base xl:text-lg pt-2 w-2/3 my-auto">
                <div className="text-red-500 flex gap-2">
                  {selectDiscountLayout === 'type01' && (
                    <div className="text-gray-400 line-through">{'฿'} 1000</div>
                  )}

                  <div>{'฿'} 900</div>
                </div>
              </div>
            </div>
          )}

          {(scoreCheck || inventoryCheck) && (
            <div className="md:flex md:flex-wrap md:justify-between pt-2 ">
              <div className="my-auto">
                {scoreCheck && (
                  <div className="flex">
                    <Rating
                      name="half-rating-read"
                      defaultValue={4.75}
                      precision={0.01}
                      size="small"
                      readOnly
                    />
                    <small className="text-gray-400 ml-1">{3.75}</small>
                  </div>
                )}
              </div>
              {inventoryCheck && (
                <small className="text-gray-500 my-auto">
                  ( จำนวนคงเหลือ {20} )
                </small>
              )}
            </div>
          )}
        </div>
        {addCardCheck && (
          <>
            <div className="flex w-full justify-center">
              <div className="w-3/10 flex justify-end">
                <IconButton color="primary" component="span">
                  <RemoveIcon />
                </IconButton>
              </div>
              <div className="w-4/10">
                <TextField
                  InputProps={{
                    sx: {
                      '& input': {
                        textAlign: 'center',
                      },
                    },
                  }}
                  variant="outlined"
                  value={1}
                  size="small"
                />
              </div>
              <div className="w-3/10 flex justify-start">
                <IconButton color="primary" component="span">
                  <AddIcon />
                </IconButton>
              </div>
            </div>
            <div className="w-full text-center pt-2 ">
              <button className="text-white bg-gray-700 py-2 w-full hover:bg-gray-400 rounded-md">
                เพิ่มลงตะกร้า
              </button>
            </div>
          </>
        )}
      </div>
      <div className="absolute top-2 right-2 bg-white hover:text-red-600 hover:opacity-90 opacity-60 rounded-full">
        <Checkbox
          icon={<FavoriteBorder />}
          checkedIcon={<Favorite />}
          sx={{
            color: red[500],
            '&.Mui-checked': {
              color: red[600],
            },
          }}
        />
      </div>

      {selectDiscountLayout === 'type01' ? (
        <div className="absolute top-2 left-0 bg-red-600 text-white px-3 rounded-sm text-sm">
          -10%
        </div>
      ) : (
        <div className="absolute top-2 left-0 bg-red-600 text-white px-3 rounded-sm text-sm">
          ราคาพิเศษ
        </div>
      )}

      {statusCheck && (
        <div className="absolute top-2 left-0 bg-red-600 text-white px-3 rounded-sm">
          new
        </div>
      )}
    </Card>
  );
}

ProductCard.propTypes = {
  imageCheck: PropTypes.bool,
  nameCheck: PropTypes.bool,
  typeCodeCheck: PropTypes.bool,
  tagCheck: PropTypes.bool,
  typeCheck: PropTypes.bool,
  brandCheck: PropTypes.bool,
  detailCheck: PropTypes.bool,
  priceCheck: PropTypes.bool,
  scoreCheck: PropTypes.bool,
  statusCheck: PropTypes.bool,
  inventoryCheck: PropTypes.bool,
  addCardCheck: PropTypes.bool,
  selectDiscountLayout: PropTypes.string,
};

ProductCard.defaultProps = {
  imageCheck: false,
  nameCheck: false,
  typeCodeCheck: false,
  tagCheck: false,
  typeCheck: false,
  brandCheck: false,
  detailCheck: false,
  priceCheck: false,
  scoreCheck: false,
  statusCheck: false,
  inventoryCheck: false,
  addCardCheck: false,
  selectDiscountLayout: '',
};

export default ProductCard;
