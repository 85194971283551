import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function MaterialForm({
  errors,
  material,
  Controller,
  control,
  materialType,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'type_code'}
          control={control}
          defaultValue={material ? material.type_code : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสสินค้า"
              fullWidth
              size={'small'}
              helperText={errors.type_code && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={material ? material.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อวัตถุดิบ"
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'unit'}
          control={control}
          defaultValue={material ? material.unit : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หน่วยวัตถุดิบ"
              fullWidth
              size={'small'}
              helperText={errors.unit && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'material_type'}
          control={control}
          defaultValue={material ? material?.material_type?.id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ประเภทวัตถุดิบ
              </InputLabel>
              <Select
                {...field}
                label="ประเภทวัตถุดิบ"
                size={'small'}
                fullWidth
              >
                {_.size(materialType?.rows) ? (
                  _.map(materialType.rows, (row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'net'}
          control={control}
          defaultValue={material ? material.net : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ปริมาตรสุทธิ"
              fullWidth
              type="number"
              size={'small'}
              helperText={errors.net && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'unit_net'}
          control={control}
          defaultValue={material ? material.unit_net : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หน่วยของปริมาตรสุทธิ"
              fullWidth
              size={'small'}
              helperText={errors.unit_net && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'minimum_amount'}
          control={control}
          defaultValue={material ? material.minimum_amount : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนขั้นต่ำของวัตถุดิบ"
              fullWidth
              type="number"
              size={'small'}
              helperText={errors.minimum_amount && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'detail'}
          control={control}
          defaultValue={material ? material.detail : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              fullWidth
              rows={2}
              multiline={true}
              size={'small'}
              helperText={errors.detail && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
}

MaterialForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    unit: PropTypes.object,
    net: PropTypes.object,
    unit_net: PropTypes.object,
    detail: PropTypes.object,
    type_code: PropTypes.object,
    minimum_amount: PropTypes.object,
  }),
  material: PropTypes.object,
  materialType: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

MaterialForm.defaultProps = {
  material: null,
  materialType: null,
};

export default MaterialForm;
