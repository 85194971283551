/* eslint-disable consistent-return */
import React from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  MATERIAL_TRANSACTION_TYPE,
  MATERIAL_STOCK_STATUS,
} from '../../../utils/constants';

export function MaterialStockForm({
  errors,
  Controller,
  control,
  material,
  materialType,
  place,
  materialTransactionType,
  materialStockLot,
  customer,
  department,
  // me,
  type,
  setType,
  selectMaterial,
  setSelectMaterial,
  filterMaterial,
  setFilterMaterial,
  filterPlace,
  setFilterPlace,
}) {
  // console.log('me', me);
  console.log('type', type);
  console.log('selectMaterial', selectMaterial);
  console.log('filterMaterial', filterMaterial);
  console.log('filterPlace', filterPlace);

  const checkType = (data) => {
    const findType = _.find(materialTransactionType, { id: data });
    setType(findType);
  };

  const checkMaterial = (data) => {
    const findMaterial = _.find(material, { id: data });
    setSelectMaterial(findMaterial);
  };

  const checkMaterialType = (data) => {
    const findMaterialType = _.find(materialType, { id: data });
    setFilterMaterial(findMaterialType);
  };

  const checkPlace = (data) => {
    const findPlace = _.find(place, { id: data });
    setFilterPlace(findPlace);
  };

  const materialOptions = () => {
    if (type?.direction === MATERIAL_TRANSACTION_TYPE[0].direction) {
      return (
        <div className="w-full flex flex-row flex-wrap">
          <div className="w-full px-1 py-2">
            <Controller
              name={'billNumber'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="เลขที่บิล"
                  fullWidth
                  size={'small'}
                  helperText={errors.billNumber && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'supplier'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    คู่ค้า
                  </InputLabel>
                  <Select {...field} label="คู่ค้า" size={'small'} fullWidth>
                    {_.size(customer) ? (
                      _.map(customer, (_customer) => (
                        <MenuItem key={_customer.id} value={_customer.id}>
                          {_customer.firstname} {_customer.lastname}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'source'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="แหล่งวัตถุดิบ"
                  fullWidth
                  size={'small'}
                  helperText={errors.source && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'material_type'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    ประเภทวัตถุดิบ
                  </InputLabel>
                  <Select
                    {...field}
                    label="ประเภทวัตถุดิบ"
                    size={'small'}
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      checkMaterialType(e.target.value);
                    }}
                  >
                    {_.size(materialType) ? (
                      _.map(materialType, (_type) => (
                        <MenuItem key={_type.id} value={_type.id}>
                          {_type.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'material'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    วัตถุดิบ
                  </InputLabel>
                  <Select
                    {...field}
                    label="วัตถุดิบ"
                    size={'small'}
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      checkMaterial(e.target.value);
                    }}
                  >
                    {_.size(material) ? (
                      _.map(material, (_material) => {
                        if (
                          _material?.material_type?.id === filterMaterial?.id
                        ) {
                          return (
                            <MenuItem key={_material.id} value={_material.id}>
                              {_material.name}
                            </MenuItem>
                          );
                        }
                      })
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'price'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ราคา"
                  fullWidth
                  size={'small'}
                  helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'quantity'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="จำนวน"
                  fullWidth
                  size={'small'}
                  helperText={errors.quantity && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <label>วันที่ผลิต</label>
            <Controller
              name={'productionDate'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  fullWidth
                  size={'small'}
                  helperText={errors.productionDate && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <label>วันหมดอายุ</label>
            <Controller
              name={'expirationDate'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  fullWidth
                  size={'small'}
                  helperText={errors.expirationDate && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <label>วันที่ซื้อ</label>
            <Controller
              name={'purchaseDate'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  fullWidth
                  size={'small'}
                  helperText={errors.purchaseDate && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <label>วันรับวัตถุดิบ</label>
            <Controller
              name={'receiptDate'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  fullWidth
                  size={'small'}
                  helperText={errors.receiptDate && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'orderDepartment'}
              control={control}
              // defaultValue={
              //   me
              //     ? me?.rows?.department?.id
              //     : ''
              // }
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    แผนกผู้สั่งซื้อ
                  </InputLabel>
                  <Select {...field} label="คู่ค้า" size={'small'} fullWidth>
                    {_.size(department) ? (
                      _.map(department, (_department) => (
                        <MenuItem key={_department.id} value={_department.id}>
                          {_department.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'recipientName'}
              control={control}
              rules={{ required: true }}
              // defaultValue={
              //   me
              //     ? me?.rows?.name
              //     : ''
              // }
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อผู้รับวัตถุดิบ"
                  fullWidth
                  size={'small'}
                  helperText={errors.recipientName && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-full px-1 py-2">
            <Controller
              name={'remark'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="หมายเหตุ"
                  fullWidth
                  size={'small'}
                  multiline={true}
                  rows={3}
                  helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
        </div>
      );
      // eslint-disable-next-line no-else-return
    } else if (type?.direction === MATERIAL_TRANSACTION_TYPE[1].direction) {
      return (
        <div className="w-full flex flex-row flex-wrap">
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'material_type'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    ประเภทวัตถุดิบ
                  </InputLabel>
                  <Select
                    {...field}
                    label="ประเภทวัตถุดิบ"
                    size={'small'}
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      checkMaterialType(e.target.value);
                    }}
                  >
                    {_.size(materialType) ? (
                      _.map(materialType, (_type) => (
                        <MenuItem key={_type.id} value={_type.id}>
                          {_type.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'material'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    วัตถุดิบ
                  </InputLabel>
                  <Select
                    {...field}
                    label="วัตถุดิบ"
                    size={'small'}
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      checkMaterial(e.target.value);
                    }}
                  >
                    {_.size(material) ? (
                      _.map(material, (stock) => (
                        <MenuItem key={stock.id} value={stock.id}>
                          {stock.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'material_stock_lot'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    ล็อตวัตถุดิบ
                  </InputLabel>
                  <Select
                    {...field}
                    label="ล็อตวัตถุดิบ"
                    size={'small'}
                    fullWidth
                  >
                    {_.size(materialStockLot) ? (
                      _.map(materialStockLot, (_materialStockLot) => {
                        console.log(_materialStockLot);
                        if (
                          _materialStockLot.status !==
                            MATERIAL_STOCK_STATUS.OUT_OF_STOCK &&
                          _materialStockLot?.material_type?.id === material?.id
                        ) {
                          return (
                            <MenuItem
                              key={_materialStockLot.id}
                              value={_materialStockLot.id}
                            >
                              วันที่{' '}
                              {new Date(
                                _materialStockLot?.recording_date,
                              ).toLocaleDateString('th', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              })}{' '}
                              คงเหลือ {_materialStockLot?.amount}{' '}
                              {_materialStockLot?.material_type?.unit}
                            </MenuItem>
                          );
                        }
                      })
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'quantity'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="จำนวน"
                  fullWidth
                  size={'small'}
                  helperText={errors.quantity && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-2">
            <label>วันที่เบิก</label>
            <Controller
              name={'issueDate'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="date"
                  fullWidth
                  size={'small'}
                  helperText={errors.receiptDate && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'requestDepartment'}
              control={control}
              // defaultValue={
              //   me
              //     ? me?.rows?.department?.id
              //     : ''
              // }
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <InputLabel id="type" size={'small'}>
                    แผนกที่ขอเบิก
                  </InputLabel>
                  <Select {...field} label="คู่ค้า" size={'small'} fullWidth>
                    {_.size(department) ? (
                      _.map(department, (_department) => (
                        <MenuItem key={_department.id} value={_department.id}>
                          {_department.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'requestName'}
              control={control}
              rules={{ required: true }}
              // defaultValue={
              //   me
              //     ? me?.rows?.name
              //     : ''
              // }
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อเบิก"
                  fullWidth
                  size={'small'}
                  helperText={errors.recipientName && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full lg:w-full px-1 py-2">
            <Controller
              name={'remark'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="หมายเหตุ"
                  fullWidth
                  size={'small'}
                  multiline={true}
                  rows={3}
                  helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'material_transaction_type'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ประเภทการดำเนินการ
              </InputLabel>
              <Select
                {...field}
                label="ประเภทการดำเนินการ"
                size={'small'}
                fullWidth
                onChange={(e) => {
                  field.onChange(e.target.value);
                  checkType(e.target.value);
                }}
              >
                {_.size(materialTransactionType) ? (
                  _.map(materialTransactionType, (_materialTransactionType) => (
                    <MenuItem
                      key={_materialTransactionType.id}
                      value={_materialTransactionType.id}
                    >
                      {_materialTransactionType.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'place'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                คลัง
              </InputLabel>
              <Select
                {...field}
                label="คลัง"
                size={'small'}
                fullWidth
                onChange={(e) => {
                  field.onChange(e.target.value);
                  checkPlace(e.target.value);
                }}
              >
                {_.size(place) ? (
                  _.map(place, (_place) => (
                    <MenuItem key={_place.id} value={_place.id}>
                      {_place.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      {materialOptions()}
    </div>
  );
}

MaterialStockForm.propTypes = {
  errors: PropTypes.shape({
    material_transaction_type: PropTypes.object,
    billNumber: PropTypes.billNumber,
    supplier: PropTypes.object,
    source: PropTypes.object,
    quantity: PropTypes.object,
    price: PropTypes.object,
    material_stock_Lots: PropTypes.object,
    place: PropTypes.object,
    material_type: PropTypes.object,
    productionDate: PropTypes.object,
    expirationDate: PropTypes.object,
    purchaseDate: PropTypes.object,
    receiptDate: PropTypes.object,
    remark: PropTypes.object,
    recipientName: PropTypes.object,
    orderDepartment: PropTypes.object,
    requestDepartment: PropTypes.object,
    requestName: PropTypes.object,
  }),
  material: PropTypes.object,
  materialType: PropTypes.object,
  materialTransactionType: PropTypes.object,
  materialStockLot: PropTypes.object,
  place: PropTypes.object,
  customer: PropTypes.object,
  department: PropTypes.object,
  me: PropTypes.object,
  type: PropTypes.object,
  setType: PropTypes.func,
  selectMaterial: PropTypes.object,
  setSelectMaterial: PropTypes.func,
  filterMaterial: PropTypes.object,
  setFilterMaterial: PropTypes.func,
  filterPlace: PropTypes.object,
  setFilterPlace: PropTypes.func,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

MaterialStockForm.defaultProps = {
  material: null,
  materialType: null,
  materialTransactionType: null,
  materialStockLot: null,
  place: null,
  customer: null,
  department: null,
  me: null,
  type: null,
  selectMaterial: null,
  filterMaterial: null,
  filterPlace: null,
};

export default MaterialStockForm;
