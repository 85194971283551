/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line object-curly-newline
import { ButtonGroup, TextField, Button } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import SaleStockCardImport from '../../components/NormalCard/SaleStock/SaleStockCardImport';
import SaleStockCardExport from '../../components/NormalCard/SaleStock/SaleStockCardExport';

import FirstComponentCardImport from '../../components/NormalCard/FirstComponent/FirstComponentCardImport';
import FirstComponentCardExport from '../../components/NormalCard/FirstComponent/FirstComponentCardExport';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import DoughnutComponentImport from '../../components/NormalCard/Doughnut/DoughnutComponentImport';
import DoughnutComponentExport from '../../components/NormalCard/Doughnut/DoughnutComponentExport';

const DashboardOverview = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const paymentTransaction = useSelector((state) => state.paymentTransaction);

  const productTransaction = useSelector((state) => state.productTransaction);
  const product = useSelector((state) => state.product);
  const customer = useSelector((state) => state.customer);
  const [State, setState] = useState();

  const name = '';

  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').toISOString(),
  );
  const [endDate, setEndDate] = useState(dayjs().endOf('month').toISOString());
  const [periodText, setPeriodText] = useState('ออก');
  const transactionType = periodText;
  const DashBoardPage = true;

  const [importButtonPressed, setImportButtonPressed] = useState(false);

  const handleImport = () => {
    setImportButtonPressed((prevState) => !prevState);
  };

  console.log('productTransaction', productTransaction);
  console.log('startDate', startDate);
  console.log('endDate', endDate);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        await dispatch(actions.customerAll({ name }));
      } catch (error) {
        console.error(error);
      }
    };
    const fetchMe = async () => {
      try {
        await dispatch(actions.meGet(''));
      } catch (error) {
        console.error(error);
      }
    };
    fetchCustomer();
    fetchMe();

    return () => {};
  }, []);

  useEffect(async () => {
    setLoading(true);
    await dispatch(
      actions.productTransactionAll({
        transactionType,
        startDate,
        endDate,
      }),
    );

    await dispatch(actions.paymentTransactionAll({ startDate, endDate }));
    await dispatch(actions.productAll({ startDate, endDate, DashBoardPage }));

    setLoading(false);
    console.log('ทำ2');
    return () => {};
  }, [startDate, endDate, transactionType]);

  const renderPeriod = () => (
    <>
      <div className="grid md:grid-cols-2 pb-2 gap-x-2 gap-y-2">
        <div className="bg-white">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="วันเริ่มต้น"
              value={startDate}
              minDate={new Date('2020-01-01')}
              onChange={(newValue) => {
                setStartDate(dayjs(newValue).toISOString());
                setLoading(true);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="bg-white">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="วันสิ้นสุด"
              value={endDate}
              minDate={new Date('2020-01-01')}
              onChange={(newValue) => {
                setEndDate(dayjs(newValue).toISOString());
                setLoading(true);
              }}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>
    </>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (
    me.isLoading ||
    product.isLoading ||
    customer.isLoading ||
    productTransaction.isLoading
  ) {
    return <Loading />;
  }
  if (
    !me.isLoading &&
    me.isCompleted &&
    !product.isLoading &&
    product.isCompleted &&
    !customer.isLoading &&
    customer.isCompleted &&
    !productTransaction.isLoading &&
    productTransaction.isCompleted
  ) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        {renderPeriod()}
        <div>กำลังพัฒนา</div>
        {/* <div>
          <div className="pl-4 mt-3">
            <ButtonGroup
              variant="contained"
              aria-label="outlined button group "
              color="secondary"
            >
              <Button onClick={handleImport}>
                {importButtonPressed ? 'Export' : 'Import'}
              </Button>
            </ButtonGroup>
          </div>

          <div className="flex justify-center m-4 bg-white">
            {importButtonPressed ? (
              <FirstComponentCardImport />
            ) : (
              <FirstComponentCardExport />
            )}
          </div>

          <div className="flex flex-row  px-4 py-1  space-x-3 ">
            {importButtonPressed ? (
              <DoughnutComponentImport />
            ) : (
              <DoughnutComponentExport />
            )}

            {importButtonPressed ? (
              <SaleStockCardImport />
            ) : (
              <SaleStockCardExport />
            )}
          </div>
        </div> */}
      </div>
    );
  }
  return <Error message={me?.message} />;
};

DashboardOverview.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DashboardOverview.defaultProps = {
  title: '',
  subtitle: '',
};

export default DashboardOverview;
