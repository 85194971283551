export const FRONT_TEMPLATE = {
  DEFAULT: {
    status_code: 'DEFAULT',
    description: 'รูปแบบพื้นฐาน',
  },
  LOWER_MENU: {
    status_code: 'LOWER_MENU',
    description: 'เมนูด้านล่าง',
  },
  LEFT_MENU: {
    status_code: 'LEFT_MENU',
    description: 'เมนูด้านซ้าย',
  },
  RIGHT_MENU: {
    status_code: 'RIGHT_MENU',
    description: 'เมนูด้านขวา',
  },
  DIPROM_1: {
    status_code: 'DIPROM_1',
    description: 'Diprom 1',
  },
  DIPROM_2: {
    status_code: 'DIPROM_2',
    description: 'Diprom 2',
  },
  DIPROM_3: {
    status_code: 'DIPROM_3',
    description: 'Diprom 3',
  },
  DIPROM_4: {
    status_code: 'DIPROM_4',
    description: 'Diprom 4',
  },
};

export default FRONT_TEMPLATE;
