import api from '../../../utils/functions/api';

import {
  SERIAL_ALL,
  SERIAL_CREATE,
  SERIAL_GET,
  SERIAL_UPDATE,
  SERIAL_DELETE,
  SERIAL_ERROR,
} from '../types';

// faker.locale = "th"

export const getAllSerial = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/serialNumber?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: SERIAL_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SERIAL_ERROR });
    throw new Error(error);
  }
};

export const getSerialOfProduct = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1, id = '' } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/serialNumber?productId=${id}&name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: SERIAL_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SERIAL_ERROR });
    throw new Error(error);
  }
};

export const getSerialAllOfProduct = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/serialNumber?productId=${id}`,
    );
    if (status === 200) {
      dispatch({ type: SERIAL_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SERIAL_ERROR });
    throw new Error(error);
  }
};

export const getOneSerial = (id) => async (dispatch) => {
  await api
    .get(`${process.env.REACT_APP_API_URL}/serialNumber/${id}`)
    .then((res) => {
      console.log('Request Server to Get One serialNumber');
      if (res.data) {
        dispatch({ type: SERIAL_GET, payload: res.data });
      } else {
        dispatch({ type: SERIAL_GET, payload: null });
      }
    });
};
export const createOneSerial = (payload) => async (dispatch) => {
  await api
    .post(`${process.env.REACT_APP_API_URL}/serialNumber/`, payload)
    .then(() => {
      console.log('Request Server to Create New serialNumber');
      dispatch({ type: SERIAL_CREATE });
    });
};

export const editOneSerial = (id, payload) => async (dispatch) => {
  await api
    .put(`${process.env.REACT_APP_API_URL}/serialNumber/${id}`, payload)
    .then(() => {
      console.log('Request Server to edit serialNumber');
      dispatch({ type: SERIAL_UPDATE });
    });
};

export const deleteOneSerial = (id) => async (dispatch) => {
  await api
    .delete(`${process.env.REACT_APP_API_URL}/serialNumber/${id}`)
    .then(() => {
      console.log('Request Server to Delete One serialNumber');
      dispatch({ type: SERIAL_DELETE, payload: null });
    });
};
