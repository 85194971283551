import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Fade,
  Card,
  Backdrop,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  TextField,
  InputAdornment,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 4,
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: theme.palette.mode === 'light' ? '#ff7d1a' : '#308fe8',
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export function ProductGraphProfit({ product, productTransaction, loading }) {
  // const [dataStack, setDataStack] = useState([]);
  const textInput = useRef(null);
  const [costStack, setCostStack] = useState([]);
  const [profitDataStack, setProfitDataStack] = useState([]);
  const [labelStack, setLabelStack] = useState([]);
  const [orderStack, setOderStack] = useState([]);
  const [percentProfit, setPercentProfit] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [checked, setChecked] = useState([]);

  const [nameLeft, setNameLeft] = useState('');
  const [searchTermLeft, setSearchTermLeft] = useState('');
  const [nameRight, setNameRight] = useState('');
  const [searchTermRight, setSearchTermRight] = useState('');

  const NewProduct = _.map(product, (_product, index) => ({
    index: index + 1,
    ..._product,
  }));

  const TopTenProduct = _.take(NewProduct, 10);

  const [left, setLeft] = useState(_.slice(NewProduct, 10, product.length));
  const [right, setRight] = useState(TopTenProduct);

  console.log('left', left);
  console.log('right', right);
  // console.log('profitDataStack', profitDataStack);
  // console.log('percentProfit', percentProfit);

  useEffect(() => {
    setRight(TopTenProduct);
    return () => {};
  }, [product]);

  useEffect(() => {
    // setLeft(product);
    const delayDebounceFn = setTimeout(() => {
      setNameLeft(searchTermLeft.toUpperCase());
    }, 100);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTermLeft, product]);

  useEffect(() => {
    // setLeft(product);
    const delayDebounceFn = setTimeout(() => {
      setNameRight(searchTermRight.toUpperCase());
    }, 100);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTermRight, product]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  // console.log('left', left);
  // console.log('right', right);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setSearchTermRight('');
    textInput.current.value = '';
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setSearchTermRight('');
    textInput.current.value = '';
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setSearchTermLeft('');
    textInput.current.value = '';
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setSearchTermLeft('');
    textInput.current.value = '';
    setLeft(left.concat(right));
    setRight([]);
  };

  useEffect(() => {
    // find labelStack
    const tempLabelStack = _.map(right, (eachProduct) => eachProduct.name);
    setLabelStack(tempLabelStack);

    // find order stack
    const stackData = orderStack;
    _.map(productTransaction.rows, (log) => {
      _.map(log.order, (order) => {
        const Data = {
          order,
          date: log.date,
        };
        stackData.push(Data);
      });
    });
    setOderStack(stackData);

    // find group of product
    // const orderByProduct = _.groupBy(
    //   stackData,
    //   // eslint-disable-next-line no-underscore-dangle
    //   (order) => order.order.product._id,
    // );
    // console.log('right', right);

    const priceOfProduct = _.map(
      right,
      (eachProduct) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        eachProduct?.cost_price,
      // eslint-disable-next-line function-paren-newline
    );

    // console.log('priceOfProduct', priceOfProduct);
    setCostStack(priceOfProduct);

    // find avg profit
    // eslint-disable-next-line consistent-return
    const profitStack = _.map(
      right,
      (eachProduct) =>
        // eslint-disable-next-line implicit-arrow-linebreak
        eachProduct?.price,
      // eslint-disable-next-line function-paren-newline
    );

    setProfitDataStack(profitStack);

    const DataOfProfit = _.map(profitStack, (profit, index) => {
      // console.log('ขาย', profit);
      // console.log('ทุน', priceOfProduct[index]);
      if (priceOfProduct[index] !== 0) {
        return (
          ((profit - priceOfProduct[index]) / priceOfProduct[index]) *
          100
        ).toFixed(1);
      }
      return 100 * profit;
    });
    setPercentProfit(DataOfProfit);
    _.remove(stackData);

    return () => {};
  }, [_.size(left), _.size(right), product, productTransaction]);

  const renderSearchLeft = () => (
    <Card>
      <div className="p-4 w-full">
        <div className="w-full">
          <TextField
            label="ค้นหา"
            fullWidth
            inputRef={textInput}
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTermLeft(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderSearchRight = () => (
    <Card>
      <div className="p-4 w-full">
        <div className="w-full">
          <TextField
            label="ค้นหา"
            fullWidth
            inputRef={textInput}
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTermRight(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const customListLeft = (items) => (
    <div className="h-96 overflow-y-auto w-full">
      <List dense component="div" role="list">
        {items &&
          items
            .filter((namess) => namess.name.toUpperCase().includes(nameLeft))
            .map((value) => {
              console.log();
              // const labelId = `transfer-list-item-${value}-label`;

              return (
                <ListItem
                  key={value.id}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText id={value.id} primary={value.name} />
                </ListItem>
              );
            })}
        <ListItem />
      </List>
    </div>
  );

  const customListRight = (items) => (
    <div className="h-96 overflow-y-auto w-full">
      <List dense component="div" role="list">
        {items &&
          items
            .filter((namess) => namess.name.toUpperCase().includes(nameRight))
            .map((value) => {
              console.log();
              // const labelId = `transfer-list-item-${value}-label`;

              return (
                <ListItem
                  key={value.id}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText id={value.id} primary={value.name} />
                </ListItem>
              );
            })}
        <ListItem />
      </List>
    </div>
  );

  const options = {
    chart: {
      type: 'bar',
      width: '100%',
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    stroke: {
      width: [0, 4],
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0, 1, 2],
    },
    xaxis: {
      type: 'category',
      labels: {
        rotate: -45,
      },
      categories: labelStack,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      rotateAlways: true,
      hideOverlappingLabels: true,
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
    },
    yaxis: [
      {
        seriesName: 'ต้นทุน',
        title: {
          text: 'ราคา (บาท)',
        },
        labels: {
          formatter: (value) => `${value} บาท`,
        },
      },
      {
        seriesName: 'ต้นทุน', // スケール合わせるためにわざと総数にしている
        show: false,
      },
      {
        seriesName: 'กำไร',
        opposite: true,
        title: {
          text: 'กำไร ( % )',
        },
        labels: {
          formatter: (value) => `${value}%`,
        },
      },
    ],
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val} `;
        },
      },
      grid: {
        show: true,
      },
    },
  };
  const series = [
    {
      name: 'ต้นทุน',
      type: 'column',
      data: costStack,
    },
    {
      name: 'ราคาขาย',
      type: 'column',
      data: profitDataStack,
    },
    {
      name: 'กำไร',
      type: 'line',
      data: percentProfit,
    },
  ];

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Card sx={style} className="w-10/12">
          <div className="w-full text-center text-xl">
            เลือกสินค้าที่จะเเสดงบนกราฟ
          </div>
          <div className="pt-2 w-full">
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item className="w-full lg:w-1/3 h-full">
                <div className="border-2 p-2 text-center rounded-md text-white bg-yellow-600">
                  สินค้าที่ไม่เเสดง
                </div>
                {renderSearchLeft()}
                <div className="border-2 rounded-md">
                  {customListLeft(left)}
                </div>
              </Grid>
              <Grid item className="w-full lg:w-1/6 h-full">
                <Grid container direction="column" alignItems="center">
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllRight}
                    disabled={left?.length === 0}
                    aria-label="move all right"
                  >
                    ≫
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked?.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked?.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                  <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleAllLeft}
                    disabled={right?.length === 0}
                    aria-label="move all left"
                  >
                    ≪
                  </Button>
                </Grid>
              </Grid>
              <Grid item className="w-full lg:w-1/3 h-full">
                <div className="border-2 p-2 text-center rounded-md text-white bg-yellow-600">
                  สินค้าที่เเสดง
                </div>
                {renderSearchRight()}
                <div className="border-2 rounded-md">
                  {customListRight(right)}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="flex justify-end pt-2">
            <Button variant="contained" onClick={handleCloseModal}>
              ตกลง
            </Button>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  if (!loading) {
    return (
      <>
        <div>{renderModal()}</div>
        <div className="text-center pt-2">
          <Button variant="contained" onClick={handleOpenModal}>
            จัดการข้อมูลที่เเสดง
          </Button>
        </div>
        <div className="xl:grid xl:justify-items-stretch">
          {/* graphic area */}
          <div className="w-full xl:w-2/3 xl:justify-self-center">
            <Chart options={options} series={series} type="line" width="100%" />
          </div>
        </div>
      </>
    );
  }
  return <BorderLinearProgress />;
}

ProductGraphProfit.propTypes = {
  product: PropTypes.array,
  productTransaction: PropTypes.object,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

ProductGraphProfit.defaultProps = {
  product: null,
  productTransaction: null,
};

export default ProductGraphProfit;
