import axios from 'axios';

// eslint-disable-next-line no-undef

axios.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem('token');
    // eslint-disable-next-line no-param-reassign
    config.headers.common.Authorization = `Bearer ${authToken}`;
    return config;
  },
  (error) => Promise.reject(error),
);

export default axios;
