import {
  AVAILABLE_MODULE_ALL,
  AVAILABLE_MODULE_ERROR,
  AVAILABLE_MODULE_LOADING,
  AVAILABLE_MODULE_PUT,
} from '../types';

import api from '../../../utils/functions/api';

export const availableModuleAll = () => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/available-module`,
    );
    if (status === 200) {
      dispatch({ type: AVAILABLE_MODULE_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: AVAILABLE_MODULE_ERROR });
    throw new Error(error);
  }
};

export const availableModulePut = (id, payload) => async (dispatch) => {
  try {
    console.log('COME IN');
    dispatch({ type: AVAILABLE_MODULE_LOADING });
    console.log('after loading');
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/available-module/${id}`,
      payload,
    );
    dispatch({ type: AVAILABLE_MODULE_PUT, payload: data });
  } catch (error) {
    console.error('error', error);
    dispatch({ type: AVAILABLE_MODULE_ERROR });
    throw new Error(error);
  }
};
