import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Card, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { SponsorForm } from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function EditSponsor({ title, subtitle }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const sponsor = useSelector((state) => state.sponsor);
  const [addImage, setAddImage] = useState([]);

  console.log('addImage', addImage);

  const [showFront, setShowFront] = useState(sponsor?.show_front);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      name: sponsor.name,
      description: sponsor.description,
      show_front: sponsor.show_front,
    },
  });

  useEffect(() => {
    dispatch(actions.sponsorGet(id));
    setShowFront(sponsor?.show_front);
    return () => {};
  }, []);

  useEffect(() => {
    if (sponsor.image) {
      setAddImage([sponsor.image]);
    }
    return () => {};
  }, [sponsor]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    console.log('data', data);
    try {
      if (_.isEmpty(addImage)) {
        const payload = {
          name: data.name,
          show_front: data.show_front,
          description: data.description,
        };
        console.log(payload);
        await dispatch(actions.sponsorPut(id, payload));
        await dispatch(actions.sponsorGet(id));
        setAddImage([]);
        alert('สำเร็จ');
        history.push('/cms/sponsor');
      }
      if (addImage[0].id) {
        const payload = {
          ...data,
          image: addImage[0].id,
        };
        console.log(payload);
        await dispatch(actions.sponsorPut(id, payload));
        await dispatch(actions.sponsorGet(id));
        setAddImage([]);
        alert('สำเร็จ');
        history.push('/cms/sponsor');
      }
      if (addImage[0].data_url) {
        const payload = {
          ...data,
          image: {
            image: addImage[0]?.data_url,
            imageType: 'sponsor',
            alt: '',
          },
        };
        console.log(payload);
        await dispatch(actions.sponsorPut(id, payload));
        await dispatch(actions.sponsorGet(id));
        setAddImage([]);
        alert('สำเร็จ');
        history.push('/cms/sponsor');
      }
    } catch (error) {
      console.log(error);
    }
  };
  if (sponsor.isLoading || sponsor.rows) {
    return <Loading />;
  }
  if (!sponsor.isLoading && sponsor.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">
            แก้ไขข้อมูลประเภทสินค้า
          </div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <SponsorForm
                errors={errors}
                sponsor={sponsor}
                control={control}
                Controller={Controller}
                addImage={addImage}
                setAddImage={setAddImage}
                showFront={showFront}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<SaveIcon />}
                >
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditSponsor.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditSponsor.defaultProps = {
  title: '',
  subtitle: '',
};
