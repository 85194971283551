import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../redux/actions';
import PlaceholderLogo from '../../assets/images/diprom.png';

export function HomeNavbar({ onMobileNavOpen }) {
  const dispatch = useDispatch();
  const setting = useSelector((state) => state.setting);
  // const history = useHistory();
  useEffect(() => {
    dispatch(actions.settingAll());
    return () => {};
  }, []);
  return (
    <>
      <nav className="top-0 fixed z-10 w-full flex flex-wrap items-center justify-between px-2 py-1 navbar-expand-lg bg-white shadow-xl">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start font-sans">
            <div className="flex gap-2">
              <Link
                to="/"
                className="text-gray-600 text-base font-bold leading-relaxed mr-4 py-2 whitespace-no-wrap flex"
              >
                <img
                  src={setting?.logo?.url || PlaceholderLogo}
                  className="h-10 rounded-md"
                />
                <div className="my-auto pl-4">
                  {setting?.name || 'ระบบ E-OnlineShop'}
                </div>
              </Link>
            </div>

            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => onMobileNavOpen()}
            >
              <i className="fas fa-bars text-gray-600"></i>
            </button>
          </div>
        </div>
      </nav>
    </>
  );
}
HomeNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
export default HomeNavbar;
