/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';

function ProfilePersonalize({ title, subtitle }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(actions.meGet());
    return () => {};
  }, []);

  const selectSeaTheme = () => {
    localStorage.setItem(
      'ERP_MAIN_BACKGROUD',
      `${process.env.PUBLIC_URL}/backgroud/beach.jpg`,
    );
    localStorage.setItem('ERP_USE_CUSTOM_COLOR', 'true');
    localStorage.setItem('ERP_MAIN_COLOR', '#1DBCCC');
    localStorage.setItem('ERP_MAIN_SECONDARY', '#841DCC');
    window.location.reload();
  };

  const selectGreenPark = () => {
    localStorage.setItem(
      'ERP_MAIN_BACKGROUD',
      `${process.env.PUBLIC_URL}/backgroud/greenpark.jpg`,
    );
    localStorage.setItem('ERP_USE_CUSTOM_COLOR', 'true');
    localStorage.setItem('ERP_MAIN_COLOR', '#3CB371');
    localStorage.setItem('ERP_MAIN_SECONDARY', '#3C42B3');
    window.location.reload();
  };

  const selectFlower = () => {
    localStorage.setItem(
      'ERP_MAIN_BACKGROUD',
      `${process.env.PUBLIC_URL}/backgroud/flower.jpg`,
    );
    localStorage.setItem('ERP_USE_CUSTOM_COLOR', 'true');
    localStorage.setItem('ERP_MAIN_COLOR', '#FFB6C1');
    localStorage.setItem('ERP_MAIN_SECONDARY', '#E6FFB6');
    window.location.reload();
  };

  const selectDefaultTheme = () => {
    localStorage.removeItem('ERP_MAIN_BACKGROUD');
    localStorage.removeItem('ERP_MAIN_COLOR');
    localStorage.removeItem('ERP_MAIN_SECONDARY');
    localStorage.removeItem('ERP_USE_CUSTOM_COLOR');
    window.location.reload();
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderEditButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <Link to="/profile/edit">
        <Button variant="contained" color="warning">
          แก้ไข
        </Button>
      </Link>
    </div>
  );

  const renderSetting = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="font-semibold font-display  ">ธีมการใช้งานระบบ</div>
          <div className="flex flex-wrap w-full my-2">
            {' '}
            <div className="w-1/2 py-4 px-2 self-center">ธีมเริ่มต้น</div>
            <div className="w-1/2 py-4 px-2  flex justify-end">
              <Button variant="contained" onClick={() => selectDefaultTheme()}>
                เลือก
              </Button>
            </div>
            <hr className="w-full" />
            <div className="w-1/2 py-4 px-2 self-center">ธีมชายหาด / ทะเล</div>
            <div className="w-1/2 py-4 px-2  flex justify-end">
              <Button variant="contained" onClick={() => selectSeaTheme()}>
                เลือก
              </Button>
            </div>
            <hr className="w-full" />
            <div className="w-1/2 py-4 px-2 self-center">
              ธีมสวน / พื้นที่สีเขียว
            </div>
            <div className="w-1/2 py-4 px-2  flex justify-end">
              <Button variant="contained" onClick={() => selectGreenPark()}>
                เลือก
              </Button>
            </div>
            <hr className="w-full" />
            <div className="w-1/2 py-4 px-2 self-center">ธีมดอกซากุระ</div>
            <div className="w-1/2 py-4 px-2  flex justify-end">
              <Button variant="contained" onClick={() => selectFlower()}>
                เลือก
              </Button>
            </div>
            <hr className="w-full" />
          </div>
        </CardContent>
      </Card>
    </div>
  );

  if (me.isLoading) {
    return <Loading />;
  }
  if (!me.isLoading && me.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="mt-6">{renderEditButton()}</div>
        </div>

        {renderSetting()}
      </div>
    );
  }
  return <Error />;
}
ProfilePersonalize.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProfilePersonalize.defaultProps = {
  title: '',
  subtitle: '',
};

export default ProfilePersonalize;
