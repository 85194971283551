import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
// eslint-disable-next-line object-curly-newline
import { Button, Card, TextField, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ProcessTemplateTable } from '../../components/Tables';
import { ViewTitle } from '../../components/ViewTitle';

export default function ProcessTemplate({ title, subtitle }) {
  const dispatch = useDispatch();
  const processTemplate = useSelector((state) => state.processTemplate);
  const history = useHistory();

  // Setting React hook form

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(undefined);

  useEffect(() => {
    const fetchProcessTemplate = async () => {
      try {
        await dispatch(actions.processTemplateAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    };
    const fetchDepartment = async () => {
      try {
        await dispatch(actions.departmentAll(''));
      } catch (error) {
        console.error('Department get all error', error);
      }
    };

    fetchDepartment();
    fetchProcessTemplate();

    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(processTemplate?.total);
    return () => {};
  }, [processTemplate]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleDelete = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.processTemplateDelete(id));
        await dispatch(actions.processTemplateAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToEditPage = async (id) => {
    history.push(`process-templates/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleToInfoPage = async (id) => {
    history.push(`process-templates/view/${id}`);
  };

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4">
      <Link to="/mms/process-templates/create">
        <Button variant="contained">เพิ่ม</Button>
      </Link>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <ProcessTemplateTable
      page={page}
      size={size}
      total={total}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleDelete={handleDelete}
      handlePushToEditPage={handlePushToEditPage}
      handleToInfoPage={handleToInfoPage}
      processTemplate={processTemplate}
    />
  );

  if (processTemplate.isLoading) {
    return <Loading />;
  }
  if (!processTemplate.isLoading && processTemplate.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButtom()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={processTemplate?.message} />;
}

ProcessTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProcessTemplate.defaultProps = {
  title: '',
  subtitle: '',
};
