import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import SaveIcon from '@mui/icons-material/Save';
import * as actions from '../../redux/actions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValues = {
  type: '',
  organization_type: '',
  name: '',
  phone_number: '',
};

function Customers({ isOpen, handleOnClose }) {
  const dispatch = useDispatch();
  const customerType = useSelector((state) => state.customerType);
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm();

  const name = '';

  useEffect(() => {
    dispatch(actions.customerAll({ name }));
    dispatch(actions.customerTypeAll(''));
    return () => {};
  }, []);

  const onSubmit = async (data, event) => {
    try {
      console.log('data', data);
      event.preventDefault();
      await dispatch(actions.customerCreate(data));
      reset(defaultValues);
      alert('สำเร็จ');
      handleOnClose();
      await dispatch(actions.customerAll({ name }));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={handleOnClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Card sx={style} className="max-w-4xl">
            <div className="py-2">เพิ่มข้อมูลลูกค้า</div>
            <div className="py-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-row flex-wrap">
                  <div className="w-full lg:w-1/2 px-1 py-2">
                    <Controller
                      name={'type'}
                      control={control}
                      defaultValue={''}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                          <InputLabel id="type" size={'small'}>
                            หมวดหมู่*
                          </InputLabel>
                          <Select
                            {...field}
                            label="หมวดหมู่"
                            size={'small'}
                            fullWidth
                            required
                          >
                            {_.size(customerType?.rows) ? (
                              _.map(customerType.rows, (row) => (
                                <MenuItem key={row.id} value={row.id}>
                                  {row.name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem>
                                <MenuItem disabled value="">
                                  <em>ไม่มีข้อมูล</em>
                                </MenuItem>
                              </MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </div>
                  <div className="w-full lg:w-1/2 px-1 py-2">
                    <Controller
                      name={'organization_type'}
                      control={control}
                      defaultValue={''}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                          <InputLabel id="type" size={'small'}>
                            ประเภทองค์กร
                          </InputLabel>
                          <Select
                            {...field}
                            label="ประเภทองค์กร"
                            size={'small'}
                            fullWidth
                            required
                          >
                            <MenuItem value="บริษัท">บุคคลทั่วไป</MenuItem>
                            <MenuItem value="นิติบุคคล">นิติบุคคล</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </div>
                  <div className="w-full  px-1 py-2">
                    <Controller
                      name={'code_name'}
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="รหัสลูกค้า (ถ้ามี)"
                          fullWidth
                          size={'small'}
                          helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full  px-1 py-2">
                    <Controller
                      name={'name'}
                      control={control}
                      defaultValue={''}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="ชื่อ"
                          fullWidth
                          size={'small'}
                          helperText={errors.name && 'กรุณาใส่ข้อมูล'}
                          required
                        />
                      )}
                    />
                  </div>
                  <div className="w-full px-1 py-1">
                    <Controller
                      name={'address'}
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="ที่อยู่"
                          rows={3}
                          multiline={true}
                          fullWidth
                          size={'small'}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full lg:w-1/2 px-1 py-2">
                    <Controller
                      name={'taxes'}
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="เลขที่ผู้เสียภาษี"
                          fullWidth
                          size={'small'}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full lg:w-1/2  px-1 py-2">
                    <Controller
                      name={'phone_number'}
                      control={control}
                      defaultValue={''}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="เบอร์โทรศัพท์"
                          fullWidth
                          size={'small'}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full lg:w-1/2 px-1 py-2">
                    <Controller
                      name={'email'}
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="อีเมล"
                          fullWidth
                          size={'small'}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full lg:w-1/2 px-1 py-2">
                    <Controller
                      name={'fax'}
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="แฟกซ์"
                          fullWidth
                          size={'small'}
                        />
                      )}
                    />
                  </div>
                  <div className="w-full lg:w-1/2 px-1 py-2">
                    <Controller
                      name={'payment'}
                      control={control}
                      rules={{ required: false }}
                      render={({ field }) => (
                        <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                          <InputLabel id="type" size={'small'}>
                            ประเภทการชำระเงิน
                          </InputLabel>
                          <Select
                            {...field}
                            label="ประเภทการชำระเงิน"
                            size={'small'}
                            fullWidth
                          >
                            <MenuItem value="เงินสด">เงินสด</MenuItem>
                            <MenuItem value="เครดิต">เครดิต</MenuItem>
                            <MenuItem value="30day_credit">
                              เครดิต 30 วัน
                            </MenuItem>
                            <MenuItem value="60day_credit">
                              เครดิต 60 วัน
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-end gap-1 py-4">
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<SaveIcon />}
                  >
                    บันทึก
                  </Button>
                  <Button variant="outlined" onClick={handleOnClose}>
                    ยกเลิก
                  </Button>
                </div>
              </form>
            </div>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
}

Customers.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isOpen: PropTypes.bool,
  handleOnClose: PropTypes.func,
};

Customers.defaultProps = {
  title: '',
  subtitle: '',
};

export default Customers;
