import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Card } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { ProductStockForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';

const defaultValues = {
  product_transaction_type: '',
  warehouse: '',
  customer: '',
  quantity: '',
  price: '',
  product_stock_lot: '',
  product: '',
};

const AddProductStockLot = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const productType = useSelector((state) => state.productType);
  const warehouse = useSelector((state) => state.warehouse);
  const productTransactionType = useSelector(
    (state) => state.productTransactionType,
  );
  const productStockLot = useSelector((state) => state.productStockLot);
  const customer = useSelector((state) => state.customer);
  const department = useSelector((state) => state.department);
  const me = useSelector((state) => state.user);
  // console.log(customer.rows);
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState(5);
  const [type, setType] = useState(null);
  const [selectProduct, setSelectProduct] = useState(null);
  const [filterProduct, setFilterProduct] = useState(null);
  const [filterWarehouse, setFilterWarehouse] = useState(null);

  useEffect(() => {
    dispatch(actions.productStockLotAll(''));
    dispatch(actions.productAll(''));
    dispatch(actions.productTypeAll(''));
    dispatch(actions.productTransactionTypeAll(''));
    dispatch(actions.warehouseAll(''));
    dispatch(actions.customerAll(''));
    dispatch(actions.departmentAll(''));
    dispatch(actions.userGet(''));
    return () => {};
  }, [name, page, size]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      console.log(data);
      event.preventDefault();
      // await dispatch(actions.productStockLotCreate(data));
      reset(defaultValues);
      alert('สำเร็จ');

      // await dispatch(actions.productStockLotAll({ name, page, size }));
      history.goBack();
    } catch (error) {
      console.error(error);
    }
  };

  const renderForm = () => (
    <Card className="p-6">
      <div className="py-2">เพิ่มข้อมูล</div>
      <div className="py-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ProductStockForm
            Controller={Controller}
            control={control}
            errors={errors}
            product={product.rows}
            productType={productType.rows}
            warehouse={warehouse.rows}
            productTransactionType={productTransactionType.rows}
            productStockLot={productStockLot.rows}
            customer={customer.rows}
            department={department.rows}
            me={me.rows}
            type={type}
            setType={setType}
            selectProduct={selectProduct}
            setSelectProduct={setSelectProduct}
            filterProduct={filterProduct}
            setFilterProduct={setFilterProduct}
            filterWarehouse={filterWarehouse}
            setFilterWarehouse={setFilterWarehouse}
          />
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    </Card>
  );

  if (product.isLoading) {
    return <Loading />;
  }
  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">เพิ่ม ลดสต๊อกสินค้า</div>
        </div>
        {renderForm()}
      </div>
    );
  }
  return <Error message={product?.message} />;
};

AddProductStockLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

AddProductStockLot.defaultProps = {
  title: '',
  subtitle: '',
};

export default AddProductStockLot;
