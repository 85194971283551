import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  InputAdornment,
  Paper,
  TablePagination,
} from '@mui/material';

import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';

import { WMS } from '../../utils/Proptypes';

const IncreaseSerialNumberDialog = ({
  open,
  handleClose,
  selectSerial,
  setSelectSerial,
  productId,
}) => {
  const textInput = useRef(null);
  const dispatch = useDispatch();
  const serialNumber = useSelector((state) => state.serialNumber);
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [reload, setReload] = useState(false);
  useEffect(() => () => {}, [reload]);

  useEffect(() => {
    const id = productId;
    dispatch(actions.getSerialOfProduct({ id, name, page, size }));
    return () => {};
  }, [productId, name, page, size]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 100);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    setTotal(serialNumber.total);
    return () => {};
  }, [serialNumber]);

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  console.log('serialNumber in modal', selectSerial);

  const handleAddSerial = (data) => {
    setSearchTerm('');
    textInput.current.value = '';
    console.log('data', data);
    const temp = selectSerial;
    const check = _.findIndex(temp, { id: data.id });
    console.log('check', check);
    if (check === -1) {
      const dataSerial = {
        id: data.id,
        name: data.serialNumber,
      };
      temp.push(dataSerial);
      setSelectSerial(temp);
    } else {
      temp.splice(check, 1);
      setSelectSerial(temp);
    }

    setReload(!reload);
  };

  //   const handleRemoveOption = (index) => {
  //     selectSerial.splice(index, 1);
  //     setSelectSerial(selectSerial);
  //     setReload(!reload);
  //   };

  // Show Product list to search and add
  const renderSearch = () => (
    <Card>
      <div className="p-4 w-full">
        <div className="w-full">
          <TextField
            label="ค้นหา"
            fullWidth
            inputRef={textInput}
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead className="bg-yellow-100">
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> Serial Number</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(serialNumber.rows) ? (
                serialNumber.rows.map((serial, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>{serial?.serialNumber}</TableCell>
                    <TableCell>
                      {_.findIndex(selectSerial, { id: serial.id }) > -1 ? (
                        <Button
                          color={'primary'}
                          variant="outlined"
                          size={'small'}
                          onClick={() => handleAddSerial(serial)}
                        >
                          <i className="fas fa-check pr-1"></i>เลือกแล้ว
                        </Button>
                      ) : (
                        <Button
                          color={'primary'}
                          variant="contained"
                          size={'small'}
                          onClick={() => handleAddSerial(serial)}
                        >
                          เลือก
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow
                  key={''}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  className="bg-gray-100"
                >
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มี SN ที่ต้องการ</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );
  // Show Product that already added

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">
        <div className="flex justify-between">
          <div>เพิ่ม Serial Number</div>
          <Button onClick={handleClose}>ปิด</Button>
        </div>
      </DialogTitle>

      <DialogContent>
        {renderSearch()}
        {renderTable()}
      </DialogContent>
    </Dialog>
  );
};

export default IncreaseSerialNumberDialog;

IncreaseSerialNumberDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  selectedProduct: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.oneOfType([WMS.Product, PropTypes.string]),
      amount: PropTypes.number,
    }),
  ),
  handleAddProduct: PropTypes.func,
  handleRemoveProduct: PropTypes.func,
  selectSerial: PropTypes.array,
  setSelectSerial: PropTypes.func,
  productId: PropTypes.number,
};

IncreaseSerialNumberDialog.defaultProps = {
  open: false,
};
