import {
  SPONSOR_ALL,
  SPONSOR_GET,
  SPONSOR_DEL,
  SPONSOR_PUT,
  SPONSOR_POST,
  SPONSOR_LOADING,
  SPONSOR_ERROR,
} from '../types';

import api from '../../../utils/functions/api';

export const sponsorCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SPONSOR_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/sponsor`,
      {
        ...payload,
      },
    );
    dispatch({ type: SPONSOR_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SPONSOR_ERROR });
    throw new Error(error);
  }
};

export const sponsorAll = (params) => async (dispatch) => {
  try {
    const { name = '', size = '', page = 1 } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/sponsor?name=${name}&size=${size}&page=${page}`,
    );
    if (status === 200) {
      dispatch({ type: SPONSOR_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SPONSOR_ERROR });
    throw new Error(error);
  }
};

export const sponsorGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/sponsor/${id}`,
    );
    if (status === 200) {
      dispatch({ type: SPONSOR_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: SPONSOR_ERROR });
    throw new Error(error);
  }
};

export const sponsorPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: SPONSOR_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/sponsor/${id}`,
      payload,
    );
    dispatch({ type: SPONSOR_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SPONSOR_ERROR });
    throw new Error(error);
  }
};
export const sponsorDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: SPONSOR_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/sponsor/${id}`,
    );
    dispatch({ type: SPONSOR_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: SPONSOR_ERROR });
    throw new Error(error);
  }
};
