/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  Button,
  Card,
  TextField,
  InputAdornment,
  TablePagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Backdrop,
  Fade,
  Modal,
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import AddBoxIcon from '@mui/icons-material/AddBox';

import { useForm, Controller, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Trash2 as Delete } from 'react-feather';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import Error from '../../components/Error/Error';
import { AddProviderForm } from '../../components/Forms';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValues = {
  name: '',
  name_en: '',
  price_more: '',
  price_more_on_delivery: '',
};

const DeliverySetting = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const deliveryPrice = useSelector((state) => state.deliveryPrice);
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [openAdd, setOpenAdd] = useState(false);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [selectProvider, setSelectProvider] = useState();
  const [selectProviderPrice, setSelectProviderPrice] = useState();
  const [checkMorePrice, setCheckMorePrice] = useState(false);
  const [cashOnDelivery, setCashOnDelivery] = useState(false);

  const { register, control, handleSubmit, setValue, reset } = useForm({
    mode: 'onBlur',
  });

  const [searchTerm, setSearchTerm] = useState('');

  console.log('deliveryPrice', deliveryPrice);
  // console.log('selectProvider', selectProvider);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'itemList',
  });

  console.log('fields', fields);

  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const handleOpenModal = (data) => {
    setOpenModalDetail(true);
    setSelectProvider(data);
  };
  const handleCloseModal = () => setOpenModalDetail(false);

  const handleChangeProviderPrice = (event) => {
    setSelectProviderPrice(event.target.value);
  };

  const handleChangeCheckMorePrice = () => setCheckMorePrice(!checkMorePrice);

  const handleChangeCashOnDelivery = () => setCashOnDelivery(!cashOnDelivery);

  useEffect(() => {
    dispatch(actions.getAllDeliveryPrice({ name, page, size }));
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(deliveryPrice?.total);
    return () => {};
  }, [deliveryPrice]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    if (!deliveryPrice.isLoading && selectProvider) {
      setValue('itemList', selectProvider?.prices);
    }
    return () => {};
  }, [deliveryPrice, selectProvider]);

  const handleAddItem = () => {
    append({
      price: 100,
      weight: 100,
    });
  };

  const handleDeleteItem = (index) => {
    remove(index);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleDelete = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.deleteOneDeliveryPrice(id));
        await dispatch(actions.getAllDeliveryPrice({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onSubmit = async (data, event) => {
    try {
      console.log('DATA', data);
      const prices = _.map(data.itemList, (eachList) => {
        const dataList = {
          weight: parseInt(eachList.weight, 10),
          price: parseInt(eachList.price, 10),
        };
        return dataList;
      });
      event.preventDefault();
      console.log('prices new', prices);
      await dispatch(actions.editOneDeliveryPrice(selectProvider.id, prices));
      handleCloseModal();
      alert('สำเร็จ');
      await dispatch(actions.getAllDeliveryPrice({}));
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitAdd = async (data, event) => {
    try {
      const payload = {
        name: data.name_th,
        name_en: data.name_en,
        free_delivery: selectProviderPrice === 'self_pickup',
        self_pickup: selectProviderPrice === 'self_pickup',
        more_price: {
          available: checkMorePrice,
          name: data.name_more || undefined,
          price: parseFloat(data.price_more) || undefined,
        },
        more_price_on_delivery: {
          available: cashOnDelivery,
          price: parseFloat(data.price_more_on_delivery) || undefined,
        },
      };
      console.log('DATA add', payload);

      event.preventDefault();
      await dispatch(actions.createOneDeliveryPrice(payload));
      reset(defaultValues);
      setCashOnDelivery(false);
      setCheckMorePrice(false);
      handleCloseAdd();
      alert('สำเร็จ');
      await dispatch(actions.getAllDeliveryPrice({}));
    } catch (error) {
      console.error(error);
    }
  };

  const renderModalDetail = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModalDetail}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModalDetail}>
        <Card sx={style} className="max-w-4xl">
          <div className="max-h-screen overflow-y-auto">
            <div className="py-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="py-2 text-xl">ตั้งค่าการจัดส่ง</div>
                <div className="py-2 text-xl">ตารางราคาค่าจัดส่งตามน้ำหนัก</div>
                <div className="w-full">
                  <Paper>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} size={'small'}>
                        <colgroup>
                          <col width="10%" />
                          <col width="45%" />
                          <col width="30%" />
                          <col width="15%" />
                        </colgroup>
                        <TableHead>
                          <TableRow className="bg-green-100">
                            <TableCell>
                              <div className="font-bold py-1">ลำดับที่</div>
                            </TableCell>
                            <TableCell>
                              <div className="font-bold py-1">
                                น้ำหนัก ( กรัม )
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="font-bold py-1">ราคา ( บาท )</div>
                            </TableCell>
                            <TableCell>
                              <div className="font-bold py-1">ดำเนินการ</div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!_.isEmpty(fields) ? (
                            _.map(fields, (item, index) => (
                              <TableRow
                                key={item?.id}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <div className="py-1">{`${index + 1}`}</div>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <div className="py-1">
                                    <Controller
                                      name={`itemList.${index}.weight`}
                                      control={control}
                                      defaultValue={item?.weight}
                                      render={({ field }) => (
                                        <TextField
                                          size="small"
                                          fullWidth
                                          required
                                          type="number"
                                          label="น้ำหนัก (กรัม)"
                                          {...field}
                                        />
                                      )}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <div className="py-1">
                                    <Controller
                                      name={`itemList.${index}.price`}
                                      control={control}
                                      defaultValue={item?.price}
                                      render={({ field }) => (
                                        <TextField
                                          size="small"
                                          fullWidth
                                          required
                                          type="number"
                                          label="ราคา"
                                          {...field}
                                        />
                                      )}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <Button
                                    size="sm"
                                    variant="outlined"
                                    color="error"
                                    onClick={() => {
                                      handleDeleteItem(index);
                                    }}
                                  >
                                    <Delete size={16} color="red" />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={6}>
                                <div className="text-center">
                                  {selectProvider?.free_delivery
                                    ? 'ไม่คิดค่าการจัดส่ง'
                                    : 'ไม่มีข้อมูล'}
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </div>
                {!selectProvider?.free_delivery && (
                  <div className="mt-2">
                    <Button
                      size="small"
                      variant="outlined"
                      color="success"
                      onClick={() => {
                        handleAddItem();
                      }}
                    >
                      เพิ่ม
                    </Button>
                  </div>
                )}

                <div className="w-full flex justify-end mt-4 gap-1">
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<SaveIcon />}
                  >
                    บันทึก
                  </Button>
                  <Button variant="outlined" onClick={handleCloseModal}>
                    ยกเลิก
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderModalAdd = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openAdd}
      onClose={handleCloseAdd}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openAdd}>
        <Card sx={style} className="max-w-4xl">
          <div className="max-h-screen overflow-y-auto my-10">
            <div className="py-2 text-xl">เพิ่มช่องทางการจัดส่ง</div>
            <div className="py-2">
              <form onSubmit={handleSubmit(onSubmitAdd)}>
                <AddProviderForm
                  Controller={Controller}
                  control={control}
                  handleChangeProviderPrice={handleChangeProviderPrice}
                  selectProviderPrice={selectProviderPrice}
                  handleChangeCheckMorePrice={handleChangeCheckMorePrice}
                  checkMorePrice={checkMorePrice}
                  handleChangeCashOnDelivery={handleChangeCashOnDelivery}
                  cashOnDelivery={cashOnDelivery}
                />
                <div className="flex flex-row justify-end gap-1 py-4">
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<SaveIcon />}
                  >
                    บันทึก
                  </Button>
                  <Button variant="outlined" onClick={handleCloseAdd}>
                    ยกเลิก
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <div>
        <Button
          variant="contained"
          onClick={handleOpenAdd}
          startIcon={<AddBoxIcon />}
        >
          เพิ่ม
        </Button>
      </div>
    </div>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ชื่อผู้ให้บริการ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ค่าจัดส่ง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ค่าบริการเพิ่มเติม</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">เก็บเงินปลายทาง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(deliveryPrice.rows) ? (
                deliveryPrice.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <div>{`${row?.provider?.name}`}</div>
                      <div>{row?.provider?.name_en || ''}</div>
                    </TableCell>
                    <TableCell>
                      {row?.provider?.free_delivery
                        ? 'ไม่มีค่าจัดส่ง'
                        : 'คิดตามน้ำหนัก'}
                    </TableCell>
                    <TableCell>
                      {row?.provider?.more_price?.available ? (
                        <div>
                          {row?.provider?.more_price?.name}{' '}
                          {row?.provider?.more_price?.price} บาท
                        </div>
                      ) : (
                        <div>ไม่มี</div>
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.provider?.more_price_on_delivery?.available ? (
                        <div>
                          + {row?.provider?.more_price_on_delivery?.price} บาท
                        </div>
                      ) : (
                        <div>ไม่มี</div>
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'info'}
                          size={'small'}
                          onClick={() => handleOpenModal(row)}
                          startIcon={<DescriptionIcon />}
                          disabled={row?.provider?.free_delivery}
                        >
                          รายละเอียด
                        </Button>
                        <Button
                          variant="contained"
                          startIcon={<EditIcon />}
                          color={'warning'}
                          size={'small'}
                          onClick={() => {
                            history.push(`/setting/delivery/edit/${row?.id}`);
                          }}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDelete(row?.id);
                          }}
                          startIcon={<DeleteIcon />}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (deliveryPrice.isLoading) {
    return <Loading />;
  }
  if (!deliveryPrice.isLoading && deliveryPrice.isCompleted) {
    return (
      <div>
        {renderModalDetail()}
        {renderModalAdd()}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButton()}</div>
        </div>
        {renderSearch()}
        <div></div>
        {renderTable()}
      </div>
    );
  }

  return <Error />;
};

DeliverySetting.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DeliverySetting.defaultProps = {
  title: '',
  subtitle: '',
};

export default DeliverySetting;
